import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageHeader from "../../view/pageheader";
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import _ from 'lodash';
import moment from 'moment'
import { Button, Form, Modal } from "react-bootstrap";
import { diceGamesSelector, getDiceBets } from "../../store/reducer/dice";
import CachedIcon from '@mui/icons-material/Cached';
function DiceGamesList() {
    const dispatch = useDispatch();
    const {
        bets,
        betsCount,
    } = useSelector(diceGamesSelector);
    console.log(bets, 'bets')
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getDiceGamesList({
            page: newPage,
            limit: rowsPerPage
        });
    };
    const handleChangeRowsPerPage = (event) => {
        let limit = parseInt(event.target.value, 10)
        setRowsPerPage(limit);
        setPage(0);
        getDiceGamesList({
            page,
            limit
        });
    };

    const getDiceGamesList = ({
        page,
        limit
    }) => {
        dispatch(
            getDiceBets({
                payload: {
                    page: page + 1,
                    limit,
                }
            })
        )
    }

    useEffect(() => {
        getDiceGamesList({
            page,
            limit: rowsPerPage
        })
    }, [])

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Dice"
                    Breadcrumb={[{ name: "Dice" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Dice</h2>
                                    </div>
                                    <div className="col-md-6 ">
                                        <h2> <Button className="pull-right" variant="light"
                                            onClick={() => {
                                                setPage(0);
                                                getDiceGamesList({
                                                    page,
                                                    limit: rowsPerPage
                                                })
                                            }}
                                        >
                                            <CachedIcon></CachedIcon>
                                        </Button></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">Date</StyledTableCell>
                                            <StyledTableCell align="">Username</StyledTableCell>
                                            <StyledTableCell align="">Trade On</StyledTableCell>
                                            <StyledTableCell align="">Result</StyledTableCell>
                                            <StyledTableCell align="">Status</StyledTableCell>
                                            <StyledTableCell align="">Amount / Winning Amount</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            bets && bets.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {moment(row.placedOn).format('DD-MM-YYYY HH:mm:ss a')}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.username}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.odds.on == "under" ? <Chip variant="filled" size="small" color="success" label="Under x2.3"></Chip> : ''}
                                                        {row.odds.on == "seven" ? <Chip variant="filled" size="small" color="success" label="Seven x5.8"></Chip> : ''}
                                                        {row.odds.on == "over" ? <Chip variant="filled" size="small" color="success" label="Over x2.3"></Chip> : ''}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                    <Chip variant="filled" size="small" color="secondary" sx={{fontWeight:'bold'}} label={row.numberVal.one + row.numberVal.two}></Chip>
                                                       
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {row.status == 'placed' ? <Chip size="small" color="success" label="Placed" /> : ''}
                                                        {row.status == 'succedded' ? <Chip size="small" color="success" label="Succuss" /> : ''}
                                                        {row.status == 'failed' ? <Chip size="small" color="error" label="Lost" /> : ''}
                                                        {row.status == 'in-review' ? <Chip size="small" color="warning" label="In Review" /> : ''}
                                                        {row.status == 'credited' ? <Chip size="small" color="success" label="Credited to wallet" /> : ''}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {row.status == 'credited' ? `₹ ${Number(row.amount).toFixed(2)} / ₹ ${Number(row.winningAmount).toFixed(2)}` : `₹ ${Number(row.amount).toFixed(2)}`}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={betsCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DiceGamesList;
