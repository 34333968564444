import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Tooltip } from 'antd';
import {
    categoryData,
    AddCategory,
    UpdateCategory,
    DeleteCategory,
    categorySelector,
    clearLoadingDatas,
    clearCatgAddLoading,
    clearCatgUpdateLoading,
    clearCatgDeleteLoading,
    clearData,
    clearErrormsg
} from "../store/reducer/category";
import { API_STATUS } from "../utils/constants";
import {
    categoryFields
} from "./fields/categoryform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL } from "../services/config";
import { Button, Modal } from "react-bootstrap";


function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext('2d')
    const TO_RADIANS = Math.PI / 180
    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}
function Category() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { categoryDatas, categoryCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(categorySelector);
    const resetForm = categoryFields;
    const [formFields, setFormFields] = useState(categoryFields);
    const [showAdd, setshowAdd] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filesizeerror, setfilesizeerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    let [scale, setScale] = useState(1);
    //const formDatas = _.map()

    let [thumbsimage, setThumbsImage] = useState([]);
    const [completedCrop, setCompletedCrop] = useState()
    let [imgSRC, setImgSRC] = useState([]);
    let [imgSRCwidth, setImgSRCwidth] = useState("");
    let [imgSRCheight, setImgSRCheight] = useState("");


    const maxProductWidth = 300;
    const maxProductHeight = 500;

    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 0,
        y: 0,
        locked: true,
        width: maxProductWidth,
        height: maxProductHeight
    })

    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = {
            unit: 'px', // Can be 'px' or '%'
            x: 0,
            y: 0,
            locked: true,
            width: maxProductWidth,
            height: maxProductHeight
        }

        setCrop(crop)
        setCompletedCrop(crop)
    }

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        categoryDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        categoryDispatch();
    };

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value)
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {

            console.log(file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result

                const img = new Image();
                img.onload = () => {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;
                    setImgSRCwidth(width);
                    setImgSRCheight(height);

                    if (width < maxProductWidth || height < maxProductHeight) {
                        const errorMessage = {
                            file,
                            errors: [
                                {
                                    code: "file-invalid-dimensions",
                                    message: 'The image dimensions must be at least ' + maxProductWidth + ' x ' + maxProductHeight + ' pixels',
                                },
                            ],
                        };

                        rejectedFiles.push(errorMessage);
                        setThumbsImage([]);
                        setfileUpload('')

                    } else {

                        setShowPreview(true);
                        console.log(`Image dimensions: ${width}x${height}`);
                        //setfileUpload(binaryStr);
                        setfilePath(file.path);
                        setfileerror(false);
                    }
                };
                img.src = binaryStr;
            }
            reader.readAsDataURL(file)
        })

    }, [])


    useEffect(() => {
        if (files.length > 0) {
            console.log(files[0].preview, "preview");
            setImgSRC(files[0].preview)
            let thumbs = files.map(file => (
                <div className="dropzone_thum_img" key={file.name}>
                    <div className="dropzone_thumb">
                        <img
                            src={file.preview}
                            className="dropzone_img"
                            // Revoke data uri after image is loaded
                            onLoad={() => { URL.revokeObjectURL(file.preview) }}
                        />
                    </div>
                </div>
            ));
            setThumbsImage(thumbs);
        }
    }, [files])

    useEffect(() => {
        if (completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current) {
            const t = setTimeout(() => {

                // We use canvasPreview as it's much faster than imgPreview.

                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1,
                    0,
                )
            }, 100);

            return () => {
                clearTimeout(t)
            }
        }

    }, [completedCrop])

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: false,
        maxSize: 1024000,
    })


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{(e.message == 'File is larger than 1024000 bytes') ? 'File size should be less than 1 MB' : e.message}</span>

            ))}
        </div>
    ));

    const cropImg = () => {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        //   console.log(previewCanvasRef.current)
        previewCanvasRef.current.toBlob((blob) => {
            console.log(blob, "blob");
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            const reader = new FileReader();

            reader.onload = () => {
                console.log(reader.result, "blob");
                setfileUpload(reader.result);
                setShowPreview(false)
            };
            reader.readAsDataURL(blob);
        })
    };
    const submitCategory = (e) => {
        console.log('submit');
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
                return false;
            }
            if (filesizeerror == true) {
                return false;
            }
            //const postData = new FormData();
            const datas = formFields.map((data) => {
                if (data?.name === "status") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val[0].id;
                } else {
                    return arr[data?.name] = data.value;
                }
            });
            arr.image = fileUpload;
            arr.imgpath = filePath;

            const postData = arr;
            console.log(postData, "postData");
            //return false;
            //const postData = arr;
            if (updateID == "")
                dispatch(AddCategory({ postData }));
            else {
                postData.id = updateID;
                dispatch(UpdateCategory({ postData, updateID }));
            }
            setfileUpload('');
            setThumbsImage([]);
            //setshowAdd(false);
        } else {
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
            }
            setShowvalidator(true);
            console.log("test");
        }
    };

    const editCategory = (row) => {
        window.scrollTo(0, 0)
        setshowAdd(true);
        setUpdateID(row.id);
        console.log(row.name)
        setfileUpload('');
        setThumbsImage([]);

        let data = formFields.map((data, index) => {
            //console.log(_.filter(statusDropdown, { id: row.status }));
            if (data.name === "name")
                data.value = row.name;
            if (data.name === "description")
                data.value = row.description;
            if (data.name === "status") {
                let set_status = _.filter(statusDropdown, { id: row.status });
                console.log(set_status[0]);
                data.value = set_status;
            }
            if (data.name === "image") {
                data.value = assestURL + row.image;
            }

            return data;
        });
        console.log(data, "formfields");
        setFormFields(data)
        setfileerror(false);
    };

    const deleteCategory = (row) => {
        setshowAdd(false);
        //setUpdateID(row.id);
        const deleteID = row.id;
        MySwal.fire({
            title: 'Are You Sure to Delete Category?',
            html: 'Deleting Category will delete its subcategory and products',
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            cancelButtonText:
                'Cancel',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(DeleteCategory({ deleteID }));
            }
        })

    };

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        setShowvalidator(false);
        setfileerror(false);
        setfileUpload('');
        formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
        })
    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        categoryDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        categoryDispatch();
    };

    const zoomIn = () => {
        setScale(scale * 2 );
     }
     
     const zoomOut = () => {
        setScale(scale / 2 );
     }

    const categoryDispatch = () => {
        dispatch(
            categoryData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };

    useEffect(() => {
        dispatch(clearData())
        categoryDispatch()
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        console.log(completedCrop, "completedCrop")
    }, [completedCrop])

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearCatgDeleteLoading())
            categoryDispatch()
        }
        if (addloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearCatgAddLoading())
            categoryDispatch()
        }
        if (updateloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearCatgUpdateLoading())
            resetFormFields();
            categoryDispatch()
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            setshowAdd(true);
            dispatch(clearErrormsg())

        }
    }, [deleteloading, addloading, updateloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Category"
                    Breadcrumb={[{ name: "Category" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Category</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-8">
                                            {(!showAdd) ?
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder="Search Category" />
                                                : ''}
                                        </div>
                                        <div className="col-md-4"><button type="button" className="btn btn-primary mr-1  float-right" onClick={(e) => {
                                            setshowAdd(!showAdd);
                                            console.log(resetForm, 'categoryFields')
                                            resetFormFields()
                                        }}>
                                            {(showAdd) ? "Cancel" : "Add Category"}
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={(showAdd) ? "body" : "body hide"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.type}
                                                                    required=""
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={statusDropdown}
                                                                            values={fields?.value}
                                                                            disabled={false}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        />
                                                                        : (fields?.input == "file") ?
                                                                            <>&nbsp;
                                                                                <Tooltip title={fields?.placeHolder}>
                                                                                    <span className="icon-question"></span>
                                                                                </Tooltip><br />
                                                                                <section className="row container">
                                                                                    <div className="col-md-8">
                                                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                                                            <input {...getInputProps()}
                                                                                                name={fields?.name} />
                                                                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                        </div></div>
                                                                                    <div className="col-md-4">


                                                                                        <div className="thumbcontainer">{(fileUpload ? <div className="dropzone_thum_img">
                                                                                            <div className="dropzone_thumb">
                                                                                                <img
                                                                                                    src={fileUpload}
                                                                                                    className="dropzone_img"
                                                                                                />
                                                                                            </div>
                                                                                        </div> : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                            <div className="dropzone_thumb">
                                                                                                <img
                                                                                                    src={fields?.value}
                                                                                                    className="dropzone_img"
                                                                                                />
                                                                                            </div>
                                                                                        </div> : '')}</div>
                                                                                    </div>
                                                                                    {fileRejectionItems}
                                                                                    <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                                </section>
                                                                            </>
                                                                            : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitCategory(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("name");
                                                    }}
                                                >
                                                    Category
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("status");
                                                    }}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                                Sorting No.
                                            </StyledTableCell> */}
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            categoryDatas && categoryDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status === 1
                                                            ? "Active"
                                                            : "In-active"}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="center">

                                                    </StyledTableCell> */}
                                                    <StyledTableCell align="">
                                                        <a href="javascript:void(0);" onClick={(e) => { editCategory(row) }} title="Edit"><i className="icon-pencil text-info p-2"></i></a>
                                                        <a href="javascript:void(0);" onClick={(e) => { deleteCategory(row) }} title="Delete"><i className="icon-trash text-danger p-2" ></i></a>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={categoryCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={showPreview} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div> 
                        {/* <button onClick={zoomIn}>Zoom in</button>
                        <button onClick={zoomOut}>Zoom out</button> */}
                        {imgSRC && (
                        <ReactCrop
                            crop={crop}
                            //onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onChange={c => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={false}
                            maxHeight={maxProductHeight}
                            maxWidth={maxProductWidth}
                            minWidth={maxProductWidth}
                            minHeight={maxProductHeight}
                            locked={true}
                            style={{ overflow: "scroll", height: "400px" , width: imgSRCwidth}}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSRC}
                                style={{ transform:`scale(${scale})`, width: imgSRCwidth, height: imgSRCheight, maxWidth: "unset" }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}

                        {!!completedCrop && (
                            <>
                                <div style={{ display: "none" }}>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </div>
                            </>)}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={cropImg}>
                        Crop & Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Category;
