import React, { useEffect, useRef, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Navigate, useNavigate } from "react-router-dom";
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import _ from 'lodash';
import { gamesList, createGameApi, updateGameApi } from "../../services/api";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import { Button as MuiButton, Typography, styled } from '@mui/material'
import { imageToBase64 } from "../../services/config";
import Toastr from "../../utils/toastr";
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function GamesList() {
  const [tableData, setTableData] = useState({
    result: [],
    count: 0
  })

  const simpleValidator = useRef(new SimpleReactValidator())

  const navigate = useNavigate()
  const [showvalidator, setShowvalidator] = useState(false);
  const [formPayload, setFormPayload] = useState({
    gameType: '',
    gameName: '',
    website_url: '',
    admin_url: '',
    image: '',
    status: '',
    _id: ''
  })

  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false)
  let [searchQuery, setSearchQuery] = useState("");

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    GamesListDispatch();
  };

  const formDataChange = (key, value) => {
    setFormPayload({
      ...formPayload,
      [key]: value
    })
  }

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    GamesListDispatch();
  };

  const resetForm = () => {
    setFormPayload({
      ...formPayload,
      gameType: '',
      gameName: '',
      website_url: '',
      admin_url: '',
      image: '',
      status: 1,
      _id: ''
    })
    setShowvalidator(false)
  }

  const submitGameDetails = async () => {
    setShowvalidator(true)
    try {
      if (simpleValidator.current.allValid()) {
        let func = formPayload._id ? updateGameApi : createGameApi
        await func({
          payload: formPayload
        })
        Toastr.success("Game details saved successfully..")
        await GamesListDispatch()
        setOpenModal(false)
        resetForm()
      }
    } catch (error) {
      Toastr.error("Something went wrong.. Pleas try again..")
      console.log(error)
    }
  }




  const searchData = (e) => {
    searchQuery = e.target.value;
    setSearchQuery(e.target.value)
    setPage(0);
    GamesListDispatch()
  }

  const GamesListDispatch = async () => {
    const apiResult = await gamesList({
      payload: {
        page: page + 1,
        limit: rowsPerPage,
        searchKey: searchQuery
      }
    })
    setTableData({
      ...tableData,
      result: apiResult.data.data.result,
      count: apiResult.data.data.count,
    })
  }

  useEffect(() => {
    GamesListDispatch()
    simpleValidator.current.showMessages()
  }, [])


  return (
    <div>
      <div className="container-fluid">
        <PageHeader
          HeaderText="Games"
          Breadcrumb={[{ name: "Games" }]}
        />
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <div className="row">
                  <div className="col-md-6">
                    <h2>Games List</h2>
                  </div>
                  <div className="col-md-6 row">
                    <div className="col-md-8">
                      <input type="text" name="search" className="form-control" value={searchQuery}
                        onChange={(e) => { searchData(e) }} placeholder="Search" />
                    </div>
                    <div className="col-md-4">
                      <Button className="btn btn-primary mr-1  float-right" onClick={() => {
                        setOpenModal(true)
                      }}>Create</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-2">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="">
                        Name
                      </StyledTableCell>
                      <StyledTableCell align="">
                        Type
                      </StyledTableCell>
                      <StyledTableCell align="">
                        Image
                      </StyledTableCell>
                      <StyledTableCell align="">
                        Status
                      </StyledTableCell>
                      <StyledTableCell align="">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      tableData.result && tableData.result.map((row) => (
                        <StyledTableRow>
                          <StyledTableCell align="">
                            {row.gameName}
                          </StyledTableCell>

                          <StyledTableCell align="">
                            {String(row.gameType).toUpperCase()}
                          </StyledTableCell>
                          <StyledTableCell align="">
                            {/* {row.icon} */}
                            <img
                              src={row.image} alt="sports_icon" width={"50"} height={"50"} />
                          </StyledTableCell>
                          <StyledTableCell align="">
                            {row.status == 1
                              ? "Active"
                              : row.status == 2 ? "Coming Soon" : "In-active"}
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <Button variant="default" size="sm" title="Edit" onClick={() => {
                              setOpenModal(true)
                              setFormPayload({
                                ...formPayload,
                                ...row
                              })
                            }}> <i className="icon-pencil text-info p-2"></i></Button>
                            <Button variant="default" size="sm" title="Admin" onClick={() => {
                              navigate(`/${row.admin_url}`)
                            }}> <i className="icon-user text-info p-2"></i></Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        colSpan={8}
                        count={tableData.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={openModal} onExit={() => {
        resetForm()
      }} backdrop="static" scrollable centered >
        <Modal.Header>
          <Modal.Title>{formPayload._id ? "Edit Game" : "Create Game"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control size="sm" value={formPayload.gameName} onChange={(e) => {
                formDataChange('gameName', e.target.value)
              }} type="text" placeholder="Name" />
              {simpleValidator.current.message(
                'Name',
                formPayload.gameName,
                "required",
                {
                  className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`,
                  messages: {
                    required: 'Required field'
                  }
                },

              )}
            </Col>
            <Col>
              <Form.Label>Type</Form.Label>
              <Form.Select size="sm" value={formPayload.gameType} onChange={(e) => {
                formDataChange('gameType', e.target.value)
              }} >
                <option value="">Select</option>
                <option value="sports">Sports</option>
                <option value="casino">Casino</option>
                <option value="racing">Racing</option>
                <option value="lottery">Lottery</option>
              </Form.Select>
              {simpleValidator.current.message(
                'Game Type',
                formPayload.gameType,
                "required",
                {
                  className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`,
                  messages: {
                    required: 'Required field'
                  }
                },

              )}

            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <Form.Label>Status</Form.Label>
              <Form.Select size="sm" value={formPayload.status} onChange={(e) => {
                formDataChange('status', e.target.value)
              }} >
                <option value="">Select</option>
                <option value="1">Active</option>
                <option value="0">In-active</option>
                <option value="2">Coming Soon</option>
              </Form.Select>
              {simpleValidator.current.message(
                'Status',
                formPayload.status,
                "required",
                {
                  className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`,
                  messages: {
                    required: 'Required field'
                  }
                },

              )}
            </Col>
            <Col>
              <Form.Label>Image</Form.Label>
              <br></br>
              <MuiButton component="label" variant="contained" color="success">
                Pick Image
                <VisuallyHiddenInput type="file" accept="image/png, image/jpeg, image/jpg" onChange={async (e) => {
                  if (e.target.files.length) {
                    let base64 = await imageToBase64(e.target.files[0])
                    console.log(base64)
                    formDataChange('image', base64)
                    // changeSettings(item.key, base64)
                  }
                }} />
              </MuiButton>
              {simpleValidator.current.message(
                'Image',
                formPayload.image,
                "required",
                {
                  className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`,
                  messages: {
                    required: 'Required field'
                  }
                },

              )}
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              {formPayload.image ? <><Form.Label>Preview</Form.Label><br></br>
                <img src={formPayload.image} style={{ width: '100px', height: '100px' }}></img></> : ''}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Admin URL</Form.Label>
              <Form.Control size="sm" value={formPayload.admin_url} onChange={(e) => {
                formDataChange('admin_url', e.target.value)
              }} type="text" placeholder="URL" />
            </Col>
            <Col>
              <Form.Label>Website URL</Form.Label>
              <Form.Control size="sm" value={formPayload.website_url} onChange={(e) => {
                formDataChange('website_url', e.target.value)
              }} type="text" placeholder="URL" />
            </Col>
          </Row>
          <br></br>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => {
            submitGameDetails()
          }}>
            Submit
          </Button>
          <Button variant="primary" onClick={() => {
            setOpenModal(false)
            resetForm()
          }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default GamesList;
