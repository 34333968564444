import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
    kitchensectionData,
    AddKitchensection,
    UpdateKitchensection,
    DeleteKitchensection,
    kitchensectionSelector,
    clearKSLoadingDatas,
    clearKSAddLoading,
    clearKSUpdateLoading,
    clearKSDeleteLoading,
    clearData,
    clearErrormsg
} from "../store/reducer/kitchensection";
import {
    custommenuitemData,
    custommenuitemSelector
} from "../store/reducer/custommenuitem";
import { API_STATUS } from "../utils/constants";
import {
    kitchensectionFields
} from "./fields/kitchensectionform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL } from "../services/config";

function Kitchensection() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { kitchensectionDatas, kitchensectionCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(kitchensectionSelector);
    const { custommenuitemDatas, custommenuitemCount } = useSelector(custommenuitemSelector);
    const resetForm = kitchensectionFields;
    const [formFields, setFormFields] = useState(kitchensectionFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [custommenuitemOptions, setCustommenuitemOptions] = useState([]);
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    //const formDatas = _.map()

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        kitchensectionDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        kitchensectionDispatch();
    };

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value)
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
            console.log(file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setfileUpload(binaryStr);
                setfilePath(file.path);
                setfileerror(false);
                //console.log(binaryStr)
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const thumbs = files.map(file => (
        <div className="dropzone_thum_img" key={file.name}>
            <div className="dropzone_thumb">
                <img
                    src={file.preview}
                    className="dropzone_img"
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: false
    })


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{e.message}</span>
            ))}
        </div>
    ));
    const submitKitchensection = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            //const postData = new FormData();
            const datas = formFields.map((data) => {
                if (data?.name === "status") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val[0].id;
                }else{
                    return arr[data?.name] = data.value;
                }
            });
            arr.custom_items = _.map(arr.custom_items, 'id');
            const postData = arr;
            console.log(postData, "postData");
            //return false;
            //const postData = arr;
            if (updateID == "")
                dispatch(AddKitchensection({ postData }));
            else {
                postData.id = updateID;
                dispatch(UpdateKitchensection({ postData, updateID }));
            }
           // setshowAdd(false);
        } else {
            setShowvalidator(true);
            console.log("test");
        }
    };

    const customItemname = (ids) => {
        let custom_ids = _.split(ids, ',', ids.length);
        let chosen = "";
        custom_ids.map((item) => {
            let set_status = _.filter(custommenuitemOptions, { id: parseInt(item) });
            //data.value = row.code;
            console.log(set_status, "chosen")
            if (set_status.length > 0) {
                chosen += set_status[0].name +", ";
            }
        })
        if(chosen !="")
            return chosen.slice(0,"-2");
        else    
            return "";
    };

    const editKitchensection = (row) => {
        window.scrollTo(0, 0)
        setshowAdd(true);
        setUpdateID(row.id);
        console.log(_.split(row.custom_items, ',', row.custom_items.length));
        let data = formFields.map((data, index) => {
            //console.log(_.filter(statusDropdown, { id: row.status }));
            if (data.name === "name")
                data.value = row.name;
            if (data.name === "description")
                data.value = row.description;
            if (data.name === "custom_items") {
                let custom_ids = _.split(row.custom_items, ',', row.custom_items.length);
                let chosen = [];
                custom_ids.map((item) => {
                    let set_status = _.filter(custommenuitemOptions, { id: parseInt(item) });
                    //data.value = row.code;
                    console.log(set_status, "chosen")
                    if (set_status.length > 0) {
                        chosen.push(set_status[0]);
                    }
                })
                console.log(chosen, "chosen")
                data.value = chosen;
                console.log(data)
            }
            if (data.name === "status") {
                let set_status = _.filter(statusDropdown, { id: row.status });
                console.log(set_status[0]);
                data.value = set_status;
            }
            console.log(data)
            return data;
        });
        console.log(data, "formfields data");
        setFormFields(data)
        setfileerror(false);
    };



    const deleteKitchensection = (row) => {
        setshowAdd(false);
        //setUpdateID(row.id);
        const deleteID = row.id;
        MySwal.fire({
            title: 'Are You Sure to Delete Kitchensection?',
            html: 'Deleting Kitchensection will delete its code',
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            cancelButtonText:
                'Cancel',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(DeleteKitchensection({ deleteID }));
            }
        })

    };

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else if (data.name === "custom_items")
                data.value = [];
            else
                data.value = "";
        })
    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        kitchensectionDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        kitchensectionDispatch();
    };

    const kitchensectionDispatch = () => {
        dispatch(
            kitchensectionData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };

    useEffect(() => {
        dispatch(clearData())
        dispatch(custommenuitemData({}))
        kitchensectionDispatch()
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        console.log(custommenuitemDatas, "custommenuitemDatas")
        if (custommenuitemOptions && custommenuitemOptions.length == 0) {
            if (custommenuitemDatas && custommenuitemDatas.length > 0) {
                let catdata = [];
                custommenuitemDatas.map((data) => {
                    return catdata.push({ id: data.id, name: data.name })
                });
                setCustommenuitemOptions(catdata)
            }
        }
    }, [custommenuitemDatas])

    useEffect(() => {
        console.log(custommenuitemOptions, "custommenuitemOptions")
        console.log(formFields, "formFields")
    }, [custommenuitemOptions, formFields])

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearKSDeleteLoading())
            kitchensectionDispatch()
        }
        if (addloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearKSAddLoading())
            kitchensectionDispatch()
        }
        if (updateloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearKSUpdateLoading())
            resetFormFields();
            kitchensectionDispatch()
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            setshowAdd(true);
            dispatch(clearErrormsg())

        }
    }, [deleteloading, addloading, updateloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Kitchen Code Section"
                    Breadcrumb={[{ name: "Kitchen Code Section" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Kitchen Code Section</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-6">
                                            {(!showAdd) ?
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder="Search Kitchen Code Section" />
                                                : ''}
                                        </div>
                                        <div className="col-md-6"><button type="button" className="btn btn-primary mr-1  float-right" onClick={(e) => {
                                            setshowAdd(!showAdd);
                                            console.log(resetForm, 'kitchensectionFields')
                                            resetFormFields()
                                        }}>
                                            {(showAdd) ? "Cancel" : "Add Kitchen Code Section"}
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={(showAdd) ? "body" : "body hide"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.type}
                                                                    required=""
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={(fields?.name == "status") ? statusDropdown : (fields?.name == "custom_items") ? custommenuitemOptions : ""}
                                                                            values={fields?.value}
                                                                            disabled={false}
                                                                            multi={fields?.multiple}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        />
                                                                        : (fields?.input == "file") ?
                                                                            <section className="row container">
                                                                                <div className="col-md-8">
                                                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                                                        <input {...getInputProps()}
                                                                                            name={fields?.name} />
                                                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                    </div></div>
                                                                                <div className="col-md-4">


                                                                                    <div className="thumbcontainer">{(thumbs && thumbs.length > 0 ? thumbs : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                        <div className="dropzone_thumb">
                                                                                            <img
                                                                                                src={fields?.value}
                                                                                                className="dropzone_img"
                                                                                            />
                                                                                        </div>
                                                                                    </div> : '')}</div>
                                                                                </div>
                                                                                {fileRejectionItems}
                                                                                <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                            </section>
                                                                            : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitKitchensection(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("name");
                                                    }}
                                                >
                                                    Section Name
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                    Custom Items
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("status");
                                                    }}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                                Sorting No.
                                            </StyledTableCell> */}
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            kitchensectionDatas && kitchensectionDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {customItemname(row.custom_items)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status === 1
                                                            ? "Active"
                                                            : "In-active"}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="center">

                                                    </StyledTableCell> */}
                                                    <StyledTableCell align="">
                                                        <a href="javascript:void(0);" onClick={(e) => { editKitchensection(row) }} title="Edit"><i className="icon-pencil text-info p-2"></i></a>
                                                        <a href="javascript:void(0);" onClick={(e) => { deleteKitchensection(row) }} title="Delete"><i className="icon-trash text-danger p-2" ></i></a>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={kitchensectionCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kitchensection;
