import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
// import { Button, Modal } from "react-bootstrap";
import ModalPopup from "../../utils/modals";
import { Tree, Tabs, Tooltip } from 'antd';
import {
    categoryData,
    categorySelector,
} from "../../store/reducer/category";
import {
    subcategoryFilterData,
    subCategorySelector,
    clearSubCatgDatas
} from "../../store/reducer/subcategory";
import {
    itemsizeData,
    itemsizeSelector,
} from "../../store/reducer/itemsize";
import {
    productData,
    AddProduct,
    UpdateProduct,
    DeleteProduct,
    productsSelector,
    clearProdAddLoading,
    clearErrormsg
} from "../../store/reducer/products";
import {
    custommenuData,
    custommenuDatabyID,
    custommenuSelector,
} from "../../store/reducer/custommenu";

import { API_STATUS } from "../../utils/constants";
import {
    productFields
} from "../fields/productform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL } from "../../services/config";
import { Button, Modal } from "react-bootstrap";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext('2d')
    const TO_RADIANS = Math.PI / 180
    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}

function AddProducts() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { productDatas, productCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(productsSelector);
    const { categoryDatas, categoryCount } = useSelector(categorySelector);
    const { custommenuDatas, custommenuDatasbyID } = useSelector(custommenuSelector);
    const { itemsizeDatas, itemsizeCount } = useSelector(itemsizeSelector);
    const { subcategoryDatas, subcategoryCount } = useSelector(subCategorySelector);
    const resetForm = productFields;
    console.log(productFields, "productFields1111")
    const [formFields, setFormFields] = useState(productFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    const [page, setPage] = useState(0);
    const [custom_menu, setCustom_menu] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [itemSizeOptions, setItemSizeOptions] = useState([]);
    const [customMenuOptions, setCustomMenuOptions] = useState([]);
    const [defaultSizeOptions, setDefaultSizeOptions] = useState([]);
    const [chosenItems, setChosenItems] = useState([]);
    const [fileerror, setfileerror] = useState(false);
    const [filesizeerror, setfilesizeerror] = useState(false);
    const [showCustommenuCount, setshowCustommenuCount] = useState(false);
    const [customenuCountDatas, setcustomenuCountDatas] = useState([]);
    const [showCustommenuItem, setshowCustommenuItem] = useState(false);
    const [selectedDatas, setselectedDatas] = useState([]);
    const [rowcheckbox, setrowcheckbox] = useState([]);
    const [defaultSelect, setDefaultSelect] = useState([]);
    const [customerror, setcustomerror] = useState(false);
    const [priceerror, setpriceerror] = useState(false);
    const [selectallitem, setselectallitem] = useState([]);
    const [minmaxerror, setminmaxerror] = useState(false);
    const [formerrormsg, setFormErrorMsg] = useState("");
    const [rewardhide, setRewardhide] = useState(false);
    const [custommenuserror, setcustommenuserror] = useState(false);
  
    let [thumbsimage, setThumbsImage] = useState([]);
    const [completedCrop, setCompletedCrop] = useState()
    let [imgSRC, setImgSRC] = useState([]);
    let [imgSRCwidth, setImgSRCwidth] = useState("");
    let [imgSRCheight, setImgSRCheight] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const previewCanvasRef = useRef(null);
    const [filename, setfilename] = useState('');
    const imgRef = useRef(null);
    let [scale, setScale] = useState(1);

    const maxProductWidth = 500;
    const maxProductHeight = 600;

    //const formDatas = _.map()

    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 0,
        y: 0,
        locked: true,
        width: maxProductWidth,
        height: maxProductHeight
    })

    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = {
            unit: 'px', // Can be 'px' or '%'
            x: 0,
            y: 0,
            locked: true,
            width: maxProductWidth,
            height: maxProductHeight
        }

        setCrop(crop)
        setCompletedCrop(crop)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onCloseCustommenuCount = () => {
        setshowCustommenuCount(false);
    }

    const onCloseCustommenuItem = () => {
        setshowCustommenuItem(false);
        setselectedDatas([]);
        setrowcheckbox([]);
    }

    const onSaveCustommenuItem = () => {
        setshowCustommenuItem(false);
    }

    const onSaveCustommenuCount = () => {
        let valid_minmax = false;
        let couterror = false;
        let columnname = '';
        customenuCountDatas.map((item) => {
            console.log("final datas", customenuCountDatas)
            console.log("min-max details", parseInt(item.min), item.min, item.max, "3")
            if ((item.min == '' && item.min != '0') || (item.max == '' && item.max != '0')) {
                console.log(item.min, item.max, "1")
                valid_minmax = true;
            } else if ((parseInt(item.min) >= parseInt(item.max))) {
                console.log(item.min, item.max, "2")
                valid_minmax = true;
            } else if ((parseInt(item.max) > parseInt(item.match_count))) {
                couterror = true;
            }

            if (valid_minmax) {
                columnname += "Menu name : " + item.name + ' Min field should be less than Max Field and it is required. <br> '
                valid_minmax = '';

            } else if (couterror) {
                columnname += "Menu Name : " + item.name + ' Maxium value is ' + item.match_count + '<br> '
                couterror = '';

            }

        })

        //if (valid_minmax || couterror) {
        if (columnname != '') {
            setFormErrorMsg(columnname);
            setminmaxerror(true);
            return false;
        } else {

            setminmaxerror(false);
        }
        setshowCustommenuCount(false);
    }

    const FieldChange = (e, index) => {
       
        if (formFields[index].name == "weight") {
            const re = /^\d*\.?\d*$/
          
            if (!e.target.value.match(re) && e.target.value != "") {
              
                return false;
            }
        }
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const CheckOrRadioChange = (value, index) => {
     
        console.log(value, "value")
        if (formFields[index].name == "custom_menus")
            setCustom_menu(value);
        if (formFields[index].name == "uom" && value == "1") {
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "hide";
                formFields[get_index].validation = "";
            }
        } else if (formFields[index].name == "uom" && value == "0") {
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "";
                formFields[get_index].validation = "required";
            }
        }
        if (formFields[index].name == "reward") {
            if (value == 1) {
                setRewardhide(true);
                let data = chosenItems;
                data.map((item) => {
                    return item.price = 0;
                })
                setChosenItems([...data])
            } else {
                setRewardhide(false);
                let data = chosenItems;
                data.map((item) => {
                    item.price = '';
                    item.reward = '';
                    return item;
                })
                setChosenItems([...data])
            }
        }

        formFields[index].value = value;
        setFormFields([...formFields]);
    }

    const DropdownCustom = (value) => {
       
        console.log(value);
        let data = customenuCountDatas;
        console.log('custome-menu-datas', customenuCountDatas, custommenuDatasbyID);
        value.map((items) => {
            let index_chosen = _.findIndex(customenuCountDatas, { name: items.name });

            let index_subcat = _.findIndex(custommenuDatasbyID, { name: items.name });

            if (index_chosen < 0) {
                data.push({ name: items.name, id: items.id, min: 0, max: 1 });
            }
        })
        // let idata = _.omit(data, _.isUndefined)
        data = data.filter(function (element) {
            return element !== undefined;
        });
        let diff = _.differenceBy(data, value, 'name');
        console.log(diff, 'diff');
        let delete_index = -1;
        if (diff.length > 0)
            delete_index = _.findIndex(data, { name: diff[0]?.name });
        if (delete_index >= 0)
            _.unset(data, delete_index)


        data = data.filter(function (element) {
            return element !== undefined;
        });
        console.log(data, "selected chosen")
        setcustomenuCountDatas([...data]);
        let menuIds = data.map(value => value.id);
        let postData = { ids: menuIds }
        dispatch(custommenuDatabyID({ postData }));

        if (selectedDatas.length > 0) {
         
            let sdata = selectedDatas.map((vals) => {
                let sdelete_index = _.findIndex(data, { "id": vals.custom_menu_id });
                if (sdelete_index < 0) {
                    _.unset(vals)
                    rowcheckbox[vals.custom_menu_id] = [];
                    setrowcheckbox([...rowcheckbox]);
                } else {
                    return vals;
                }
            });
            sdata = sdata.filter(function (element) {
                return element !== undefined;
            });
            console.log(sdata);
            setselectedDatas([...sdata]);
        }
    };

    const DropdownChange = (value, index, type) => {
        console.log(value, "value", type)
       
        //if (value.length > 0) {
        if (type === "category") {
            setSubcategoryOptions([]);
            let datas = formFields.map((data, index) => {
                console.log(data, "data");
                if (data.name === "subcategory_id")
                    data.value = [];
                return data;
            })
            setFormFields(datas)
            if (value.length > 0) {
                let postData = { category_id: value[0].id };
                console.log(postData, postData)
                dispatch(subcategoryFilterData({ postData }))
            }
        }
        if (type === "itemsize") {
            console.log(value, "value");
            let data = chosenItems;
            console.log(data, "data");
            let new_id = "";
            value.map((items) => {
                let index_chosen = _.findIndex(chosenItems, { name: items.name });
                if (index_chosen < 0) {
                    data.push({ name: items.name, itemsize_id: items.id, price: '', points: '' });
                    new_id = items.id;
                }
            })
            // let idata = _.omit(data, _.isUndefined)
            data = data.filter(function (element) {
                return element !== undefined;
            });
            let diff = _.differenceBy(data, value, 'name');
            console.log(diff, 'diff');
            let delete_index = -1;
            if (diff.length > 0)
                delete_index = _.findIndex(data, { name: diff[0]?.name });
            if (delete_index >= 0) {
                let default_index = _.findIndex(formFields, { name: "default_size" });
                if (default_index >= 0) {
                    let dval = formFields[default_index].value;
                    if (dval.length > 0 && diff[0]?.name == dval[0]?.name) {
                        formFields[default_index].value = [];
                    }
                    console.log(dval, "value123");
                }
                _.unset(data, delete_index)
            }


            console.log(data, "chosen")
            data = data.filter(function (element) {
                return element !== undefined;
            });
            setChosenItems([...data]);

            if (selectedDatas.length > 0) {
                let sdata = selectedDatas.map((vals) => {
                    let iids = vals.itemsize_id;
                    let iprice = vals.price;
                    console.log(iids, iprice)
                    if (delete_index >= 0) {
                        _.unset(vals.itemsize_id, delete_index)
                        _.unset(vals.price, delete_index)
                    }
                    else if (new_id != "") {
                        vals.itemsize_id.push(new_id);
                        vals.price.push(vals.item_price);
                    }
                    vals.itemsize_id = vals.itemsize_id.filter(function (element) {
                        return element !== undefined;
                    });
                    vals.price = vals.price.filter(function (element) {
                        return element !== undefined;
                    });
                    return vals;
                });
                console.log(sdata);
                setselectedDatas([...sdata]);
            }
        }
        formFields[index].value = value;
        console.log(formFields, index + "formFields")
        setFormFields([...formFields]);
        //}
    };

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
            console.log(file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result

                const img = new Image();
                img.onload = () => {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;
                    setImgSRCwidth(width);
                    setImgSRCheight(height);

                    if (width < maxProductWidth || height < maxProductHeight) {
                        const errorMessage = {
                            file,
                            errors: [
                                {
                                    code: "file-invalid-dimensions",
                                    message: 'The image dimensions must be at least ' + maxProductWidth + ' x ' + maxProductHeight + ' pixels',
                                },
                            ],
                        };

                        rejectedFiles.push(errorMessage);
                        setThumbsImage([]);
                        setfileUpload('')
                    } else {

                        setShowPreview(true);
                        console.log(`Image dimensions: ${width}x${height}`);
                        // setfileUpload(binaryStr);
                        setfilePath(file.path);
                        setfileerror(false);
                    }
                };
                img.src = binaryStr;
            }
            reader.readAsDataURL(file)
        })

    }, [])

    useEffect(() => {
        if (files.length > 0) {
            console.log(files[0].preview, "preview");
            setImgSRC(files[0].preview)
            let thumbs = files.map(file => (
                <div className="dropzone_thum_img" key={file.name}>
                    <div className="dropzone_thumb">
                        <img
                            src={file.preview}
                            className="dropzone_img"
                            // Revoke data uri after image is loaded
                            onLoad={() => { URL.revokeObjectURL(file.preview) }}
                        />
                    </div>
                </div>
            ));
            setThumbsImage(thumbs);
        }
    }, [files])

    useEffect(() => {
        if (completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current) {
            const t = setTimeout(() => {

                // We use canvasPreview as it's much faster than imgPreview.

                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1,
                    0,
                )
            }, 100);

            return () => {
                clearTimeout(t)
            }
        }

    }, [completedCrop])



    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: {
            'image/*': []
        }, multiple: false,
        maxSize: 1024000,

    })
    console.log('filereje', fileRejections);
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{(e.message == 'File is larger than 1024000 bytes') ? 'File size should be less than 1 MB' : e.message}</span>

            ))}
        </div>
    ));

    const cropImg = () => {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        //   console.log(previewCanvasRef.current)
        previewCanvasRef.current.toBlob((blob) => {
            console.log(blob, "blob");
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            const reader = new FileReader();

            reader.onload = () => {
                console.log(reader.result, "blob");
                setfileUpload(reader.result);
                setShowPreview(false)
            };
            reader.readAsDataURL(blob);
        })
    };


    const submitCategory = (e) => {
        let itemsize_id = _.map(chosenItems, 'itemsize_id');
        let price = _.map(chosenItems, 'price');
        console.log(itemsize_id);
        //return false;
        if (simpleValidator.current.allValid()) {
            setShowvalidator(false);
            //alert('You submitted the form and stuff!');
            let arr = {};
            if (fileUpload == '') {
                setfileerror(true);
                return false;
            }
            if (filesizeerror == true) {
                return false;
            }
            //const postData = new FormData();
            const datas = formFields.map((data) => {
                console.log(data, 'datadata')
                let val = '';
                if (data?.name === "status") {
                    val = data.value;
                    console.log(val)
                    val = val[0].id;
                } else if (data?.name === "category_id") {
                    val = data.value;
                    console.log(val)
                    val = val[0].id;
                } else if (data?.name === "subcategory_id") {
                    val = data.value;
                    console.log(val)
                    val = val[0].id;
                } else if (data?.name === "default_size") {
                    val = data.value;
                    console.log(val)
                    val = val[0].id;
                } else if (data?.name === "reward") {
                    if (data.value == "")
                        val = 0;
                    else
                        val = data.value
                } else {
                    val = data.value
                }
                return arr[data?.name] = val;


            });
            if(arr.custom_menus == 1 && customenuCountDatas.length == 0 ){
                setcustommenuserror(true);
                return false;
            }else{
                setcustommenuserror(false);
            }
            console.log(selectedDatas.length, "selectedDatas.length")
            if (arr.custom_menus == 1 && (customenuCountDatas.length == 0 || selectedDatas.length == 0)) {
                setcustomerror(true);
                return false;
            }
            let price_valid = true;
            if (!rewardhide) {
                chosenItems.map((item) => {
                    if (item.price == "") {
                        price_valid = false;
                    }
                });
            } else {
                chosenItems.map((item) => {
                    if (item.points == "") {
                        price_valid = false;
                    }
                });
            }
            if (price_valid == false) {
                setpriceerror(true);
                return false;
            }

            
            arr.itemsize_id = _.map(chosenItems, 'itemsize_id');
            arr.price = _.map(chosenItems, 'price');
            arr.points = _.map(chosenItems, 'points');
            arr.custom_products = selectedDatas;
            arr.custom_menudata = customenuCountDatas;
            arr.image = fileUpload;
            arr.imgpath = filePath;
            delete arr['itemsize_ids'];
            const postData = arr;
            console.log(arr.custom_menudata,'custommenus');
            console.log(postData, "postData1");

            if (updateID == "")
                dispatch(AddProduct({ postData }));
            else {
                postData.id = updateID;
                dispatch(UpdateProduct({ postData, updateID }));
            }
        } else {
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
            }
            // setcustommenuserror(false);
            setShowvalidator(true);
            console.log("test");
        }
    };

    const zoomIn = () => {
        setScale(scale * 2 );
     }
     
     const zoomOut = () => {
        setScale(scale / 2 );
     }

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        dispatch(clearSubCatgDatas());
        setSubcategoryOptions([])
        setfileUpload('');
        console.log("sub resetFormFields")
        let datas = formFields.map((data, index) => {
            console.log(data, "data");
            if (data.name === "category_id")
                data.value = [];
            else if (data.name === "subcategory_id")
                data.value = [];
            else if (data.name === "itemsize_ids")
                data.value = [];
            else if (data.name === "default_size")
                data.value = [];
            else if (data.name === "uom")
                data.value = 0;
            else if (data.name === "weight") {
                data.class = "";
                data.validation = "required";
                data.value = "";
            }
            else if (data.name === "custom_menus")
                data.value = 0;
            else if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
            return data
            //return datas.push(data);
        })
        //console.log(datas, "datas")
        setFormFields(datas)
    };

    const changePrice = (e, index) => {
       
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) && e.target.value >= 1 || e.target.value == ""   ) {
            console.log(e.target.value, 'test')
            chosenItems[index].price = e.target.value
            console.log(chosenItems);
            setChosenItems([...chosenItems]);
            setpriceerror(false);
        }
    }
    const changeReward = (e, index) => {
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) && e.target.value >= 1|| e.target.value == "") {
            chosenItems[index].points = e.target.value
            console.log(chosenItems);
            setChosenItems([...chosenItems]);
            setpriceerror(false);
        }
    }

    const changeMinMax = (e, index, type) => {
        setminmaxerror(false);
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            if (type == "min")
                customenuCountDatas[index].min = e.target.value
            else if (type == "max")
                customenuCountDatas[index].max = e.target.value
            else
                customenuCountDatas[index].max = ''

            setcustomenuCountDatas([...customenuCountDatas]);
        }
    }

    const TabName = (row) => {
        const get_arr = _.filter(customenuCountDatas, { name: row?.name });
        if (get_arr.length > 0)
            return row?.name + " (" + get_arr[0].min + ", " + get_arr[0].max + ")";
        else
            return row?.name;
    };



    const ItemPrice = (price, row_data, index, item) => {
        //console.log(row_data, price)
        if (row_data)
            return price;
        else {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            if (index_selected >= 0) {
                return data[index_selected].price[index];
            }
        }

    };

    const ChangeItemPrice = (e, index, item) => {
     
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            console.log(index_selected, "data")
            if (index_selected >= 0) {
                data[index_selected].price[index] = e.target.value;
            }
            setselectedDatas([...data]);
            console.log(data, "data")
        }
    };
    const ItemPoints = (price, row_data, index, item) => {
       
        //console.log(row_data, price)
        if (row_data)
            return price;
        else {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            if (index_selected >= 0) {
                return data[index_selected].points[index];
            }
        }

    };

    const ChangeItemPoints = (e, index, item) => {
        
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            console.log(index_selected, "data")
            if (index_selected >= 0) {
                data[index_selected].points[index] = e.target.value;
            }
            setselectedDatas([...data]);
            console.log(data, "data")
        }
    };

    const selectAll = (row, tindex) => {
      
        if (typeof (selectallitem[tindex]) === "undefined") {
            selectallitem[tindex] = true
        } else {
            selectallitem[tindex] = !selectallitem[tindex];
        }
        setselectallitem(selectallitem);
        if (row.custom_menu_items && row.custom_menu_items.length > 0) {
            if (selectallitem[tindex]) {
                row.custom_menu_items.map((item, index) => {
                    if (typeof (rowcheckbox[tindex]) === "undefined") {
                        rowcheckbox.push(tindex);
                        console.log(rowcheckbox, "rowcheckbox")
                        rowcheckbox[tindex] = [index];
                    } else {
                        let indexdata = _.indexOf(rowcheckbox[tindex], index)
                        console.log(indexdata, "indexdata")
                        if (indexdata < 0)
                            rowcheckbox[tindex].push(index);

                        rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                            return element !== undefined;
                        });
                    }
                    setrowcheckbox([...rowcheckbox]);
                    let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
                    let item_price = _.fill(Array(chosenItems.length), item.price);
                    let item_points = _.fill(Array(chosenItems.length), 0);
                    let data = selectedDatas;
                    let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
                    if (index_selected < 0) {
                        data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, points: item_points, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
                    } else {
                        //_.unset(data, index_selected);
                    }
                    data = data.filter(function (element) {
                        return element !== undefined;
                    });
                    //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
                    setselectedDatas([...data])
                })
            } else {
                row.custom_menu_items.map((item, index) => {
                    if (typeof (rowcheckbox[tindex]) === "undefined") {
                        // rowcheckbox.push(tindex);
                        // console.log(rowcheckbox, "rowcheckbox")
                        // rowcheckbox[tindex] = [index];
                    } else {
                        let indexdata = _.indexOf(rowcheckbox[tindex], index)
                        console.log(indexdata, "indexdata")
                        if (indexdata >= 0)
                            _.unset(rowcheckbox[tindex], indexdata);
                        else
                            rowcheckbox[tindex].push(index);

                        rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                            return element !== undefined;
                        });
                    }
                    setrowcheckbox([...rowcheckbox]);
                    let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
                    let item_price = _.fill(Array(chosenItems.length), item.price);
                    let data = selectedDatas;
                    let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
                    if (index_selected < 0) {
                        //data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
                    } else {
                        _.unset(data, index_selected);
                    }
                    data = data.filter(function (element) {
                        return element !== undefined;
                    });
                    //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
                    setselectedDatas([...data])
                    let defaultdata = defaultSelect;
                    let dindex_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
                    if (dindex_selected >= 0) {
                        _.unset(defaultdata, dindex_selected);

                        defaultdata = defaultdata.filter(function (element) {
                            return element !== undefined;
                        });
                        setDefaultSelect(defaultdata);
                    }
                })
            }
        }
        /* if (row.custom_menu_items && row.custom_menu_items.length > 0) {
            row.custom_menu_items.map((item, index) => {
                if (typeof (rowcheckbox[tindex]) === "undefined") {
                    rowcheckbox.push(tindex);
                    console.log(rowcheckbox, "rowcheckbox")
                    rowcheckbox[tindex] = [index];
                }
                else {
                    let indexdata = _.indexOf(rowcheckbox[tindex], index)
                    console.log(indexdata, "indexdata")
                    if (indexdata >= 0)
                        _.unset(rowcheckbox[tindex], indexdata);
                    else
                        rowcheckbox[tindex].push(index);

                    rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                        return element !== undefined;
                    });
                }
                console.log(rowcheckbox, "rowcheckbox")
                //rowcheckbox[index] = (rowcheckbox[index]) ? true : false;
                setrowcheckbox([...rowcheckbox]);
                let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
                let item_price = _.fill(Array(chosenItems.length), item.price);
                let data = selectedDatas;
                let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
                if (index_selected < 0) {
                    data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
                } else {
                    _.unset(data, index_selected);
                }
                data = data.filter(function (element) {
                    return element !== undefined;
                });
                //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
                setselectedDatas([...data])
            })

        } */
    };

    const selectItems = (row, tindex, index, item) => {
        console.log(rowcheckbox, "rowcheckbox")
        setcustomerror(false);
        if (typeof (rowcheckbox[tindex]) === "undefined") {
            rowcheckbox.push(tindex);
            console.log(rowcheckbox, "rowcheckbox")
            rowcheckbox[tindex] = [index];
        }
        else {

            let indexdata = _.indexOf(rowcheckbox[tindex], index)
            console.log(indexdata, "indexdata")
            if (indexdata >= 0)
                _.unset(rowcheckbox[tindex], indexdata);
            else
                rowcheckbox[tindex].push(index);

            rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                return element !== undefined;
            });
        }
        console.log(rowcheckbox, "rowcheckbox")
        //rowcheckbox[index] = (rowcheckbox[index]) ? true : false;
        setrowcheckbox([...rowcheckbox]);
        let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
        let item_price = _.fill(Array(chosenItems.length), item.price);
        let item_points = _.fill(Array(chosenItems.length), 0);
        let data = selectedDatas;
        let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
        if (index_selected < 0) {
            data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, points: item_points, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
        } else {
            _.unset(data, index_selected);
        }
        data = data.filter(function (element) {
            return element !== undefined;
        });
        //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
        setselectedDatas([...data])
        let defaultdata = defaultSelect;
        let dindex_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
        if (dindex_selected >= 0) {
            _.unset(defaultdata, dindex_selected);

            defaultdata = defaultdata.filter(function (element) {
                return element !== undefined;
            });
            setDefaultSelect(defaultdata);
        }

        if (typeof (selectallitem[tindex]) === "undefined") {
            selectallitem[tindex] = false
        } else {
            let count = row.custom_menu_items.length;
            let datas = _.filter(selectedDatas, { "custom_menu_id": row.id })
            console.log(datas.length, count);
            if (count == datas.length)
                selectallitem[tindex] = true;
            else
                selectallitem[tindex] = false;
        }
        setselectallitem(selectallitem)
    };

    useEffect(() => {
        console.log(selectedDatas, "selectedDatas")
        console.log(chosenItems, "chosenItems")
        console.log(rowcheckbox, "selected rowcheckbox")
    }, [selectedDatas, chosenItems, rowcheckbox])

    useEffect(() => {
        let tempData = customenuCountDatas
        for (let i in tempData) {
            let findEle = _.find(custommenuDatasbyID, { id: tempData[i].id })
            tempData[i].match_count = findEle && findEle.custom_menu_items && findEle.custom_menu_items.length
        }
        setcustomenuCountDatas([...tempData])

    }, [custommenuDatasbyID])

    const checkedBox = (row, type) => {
        let foundindex = _.findIndex(selectedDatas, { item_id: row?.id })
        if (foundindex > 0 && type == "checked") {
            return true;
        }
        else if (type == "checked") {
            return false;
        }
        if (foundindex > 0 && type == "disabled") {
            return false;
        }
        else if (type == "disabled") {
            return true;
        }

        console.log(selectedDatas, "selectItems")
    };

    const selectDefault = (row, tindex, rindex, item) => {
        console.log(row);
        console.log(item);
        let data = defaultSelect;
        let countminmax = _.filter(customenuCountDatas, { id: row.id });
        let get_min = countminmax[0]['min'];
        let get_max = countminmax[0]['max'];
        console.log(data, "data");
        let datadefault = 0;
        let index_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
        if (index_selected < 0) {
            let getcustomdatas = _.filter(data, { custom_menu_id: row.id });
            let getcustomdatasindex = _.findIndex(data, { custom_menu_id: row.id });
            console.log(getcustomdatas);
            console.log(get_min, get_max, getcustomdatas.length);
            if (getcustomdatas.length >= parseInt(get_max)) {
                //var lastItem = data.pop();
                var lastItem = getcustomdatas.pop();
                _.unset(data, getcustomdatasindex);
                console.log(lastItem);
                selectedDatas.map((datas) => {
                    if (datas.item_id == lastItem.item_id && datas.custom_menu_id == lastItem.custom_menu_id) {
                        datas.default = 0;
                        return datas;
                    }
                });
                setselectedDatas([...selectedDatas])
                //data.splice(-1);
                //return false;
            }
            data.push({ custom_menu_id: row.id, item_id: item.id });
            datadefault = 1;
        } else {
            _.unset(data, index_selected);
        }
        data = data.filter(function (element) {
            return element !== undefined;
        });
        //let seledata = _.filter(selectedDatas, {item_id: item.id, custom_menu_id: row.id});
        selectedDatas.map((datas) => {
            if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
                datas.default = datadefault;
                return datas;
            }
        });
        setselectedDatas([...selectedDatas])
        setDefaultSelect(data);
    };

    useEffect(() => {
        //clearSubCatgDatas();
        //setSubcategoryOptions([]);
        resetFormFields();
        console.log(productFields, "productFields")
        dispatch(categoryData({}));
        dispatch(custommenuData({ status: 1 }));
        dispatch(itemsizeData({ status: 1 }));
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        console.log(subcategoryDatas, "subcategoryDatas")
        console.log(subcategoryOptions, "subcategoryOptions")
    }, [subcategoryDatas, subcategoryOptions])
    useEffect(() => {
        if (categoryOptions && categoryOptions.length == 0) {
            if (categoryDatas && categoryDatas.length > 0) {
                let catdata = [];
                categoryDatas.map((data) => {
                    return catdata.push({ id: data.id, name: data.name })
                });
                setCategoryOptions(catdata)
            }
        }
        //if (itemSizeOptions && itemSizeOptions.length == 0) {
        if (itemsizeDatas && itemsizeDatas.length > 0) {
            let itemsizeoptions = [];
            itemsizeDatas.map((data) => {
                return itemsizeoptions.push({ id: data.id, name: data.code })
            });
            setItemSizeOptions(itemsizeoptions)
        }
        //}
        if (subcategoryOptions && subcategoryOptions.length == 0) {
            if (subcategoryDatas && subcategoryDatas.length > 0) {
                let subcategoryOptions = [];
                subcategoryDatas.map((data) => {
                    return subcategoryOptions.push({ id: data.id, name: data.name })
                });
                setSubcategoryOptions(subcategoryOptions)
            }
        }
        //if (defaultSizeOptions && defaultSizeOptions.length == 0) {
        if (chosenItems && chosenItems.length >= 0) {
            let defaultSizeOptions = [];
            let chosendata = chosenItems.filter(function (element) {
                return element !== undefined;
            });
            chosendata.map((data) => {
                return defaultSizeOptions.push({ id: data.itemsize_id, name: data.name })
            });
            setDefaultSizeOptions([...defaultSizeOptions])
        }
        //}

        console.log('custommenudata', custommenuDatas)

        //if (customMenuOptions && customMenuOptions.length == 0) {
        if (custommenuDatas && custommenuDatas.length > 0) {
            let customMenuOptions = [];
            custommenuDatas.map((data) => {
                return customMenuOptions.push({ id: data.id, name: data.name })
            });
            setCustomMenuOptions(customMenuOptions)
        }
        //}

    }, [itemsizeDatas, categoryDatas, subcategoryDatas, chosenItems, custommenuDatas])

    useEffect(() => {
        console.log(loading, "loading")
        if (addloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearProdAddLoading())
            navigate('/products');
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }

            dispatch(clearErrormsg())

        }
    }, [addloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Add Products"
                    Breadcrumb={[{ name: "Add Products" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2>Add Products</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <form className="ng-untouched ng-dirty ng-invalid">
                                <div className="row p-10">
                                    {
                                        formFields && formFields.map((fields, index) => {
                                            return (
                                                <div className={(fields.class == "hide") ? "col-md-6 hide" : (fields.name == "reward") ? "col-md-12" : "col-md-6"}>
                                                    <div className="form-group">
                                                        <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}
                                                        </label>
                                                        {(fields?.input == "text") ?
                                                            <input
                                                                className={`form-control ` + fields?.class}
                                                                value={fields?.value}
                                                                name={fields?.type}
                                                                required=""
                                                                onChange={(e) => { FieldChange(e, index) }}
                                                            />
                                                            : (fields?.input == "textarea") ?
                                                                <textarea
                                                                    className="form-control"
                                                                    value={fields?.value}
                                                                    cols="30"
                                                                    name="textarea"
                                                                    required=""
                                                                    rows="2"
                                                                    onChange={(e) => {
                                                                        FieldChange(e, index)
                                                                    }}
                                                                ></textarea>
                                                                : (fields?.input == "dropdown") ?
                                                                    <>
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={(fields?.options == "status") ? statusDropdown : (fields?.options == "subcategory" && subcategoryOptions.length > 0) ? subcategoryOptions : (fields?.options == "category" && categoryOptions.length > 0) ? categoryOptions : (fields?.options == "itemsize" && itemSizeOptions.length > 0) ? itemSizeOptions : (fields?.options == "defaultsize" && defaultSizeOptions.length > 0) ? defaultSizeOptions : []}
                                                                            values={(fields.value) ? fields?.value : []}
                                                                            disabled={false}
                                                                            multi={(fields.multiple) ? fields.multiple : false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index, fields?.options); }}
                                                                        />
                                                                        {(fields?.dynamic_data && fields?.dynamic_data == true) ?
                                                                            <>
                                                                                <TableContainer component={Paper}>
                                                                                    <Table sx={{ minWidth: 400 }} stickyHeader>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <StyledTableCell className="table-header-column-name">
                                                                                                    Item Size
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell className={(rewardhide) ? "table-header-column-name hide" : "table-header-column-name"}>
                                                                                                    Item Price
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell className={(rewardhide) ? "table-header-column-name" : "table-header-column-name hide"}>
                                                                                                    Reward Points
                                                                                                </StyledTableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {chosenItems && chosenItems.map((items, index) => {
                                                                                                return (items && items.name) ?
                                                                                                    <StyledTableRow>
                                                                                                        <StyledTableCell align="center">
                                                                                                            {items?.name}
                                                                                                        </StyledTableCell>
                                                                                                        <StyledTableCell align="center" className={(rewardhide) ? "hide" : ""}>
                                                                                                            <input type="text" name="itemprice" value={items.price}
                                                                                                                onChange={(e) => {
                                                                                                                    changePrice(e, index)
                                                                                                                }} class="form-control" />
                                                                                                        </StyledTableCell>
                                                                                                        <StyledTableCell align="center" className={(rewardhide) ? "" : "hide"}>
                                                                                                            <input type="text" name="points" value={items.points}
                                                                                                                onChange={(e) => {
                                                                                                                    changeReward(e, index)
                                                                                                                }} class="form-control" />
                                                                                                        </StyledTableCell>
                                                                                                    </StyledTableRow>
                                                                                                    : ''
                                                                                            })}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                                <div className={(priceerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The {(rewardhide) ? "points" : "price"} field is required.</div>
                                                                            </>
                                                                            : ''
                                                                        }
                                                                    </>
                                                                    : (fields?.input == "checkbox") ?
                                                                        <>&nbsp;
                                                                            <Tooltip title={fields?.placeHolder}>
                                                                                <span className="icon-question"></span>
                                                                            </Tooltip><br />
                                                                            <label class="fancy-checkbox">
                                                                                <input type="checkbox" className={fields?.class}
                                                                                    value={fields?.value}
                                                                                    name={fields?.type}
                                                                                    required=""
                                                                                    onChange={(e) => { CheckOrRadioChange(((fields?.value == 1) ? 0 : 1), index) }} /><span></span>
                                                                            </label>
                                                                        </>
                                                                        : (fields?.input == "radio") ?
                                                                            <><br />
                                                                                {fields?.options && fields.options.map((item) => {
                                                                                    return <label class="fancy-radio">
                                                                                        <input type="radio" className={fields?.class}
                                                                                            value={item?.value}
                                                                                            name={fields?.name}
                                                                                            checked={(item?.value == fields?.value) ? "checked" : ""}
                                                                                            onChange={(e) => { CheckOrRadioChange(item?.value, index) }} /><span><i></i>{item.option}</span>
                                                                                    </label>
                                                                                })}

                                                                            </>
                                                                            : (fields?.input == "file") ?
                                                                                <>&nbsp;
                                                                                    <Tooltip title={fields?.placeHolder}>
                                                                                    <span className="icon-question"></span>
                                                                                </Tooltip><br />
                                                                                <section className="row container">
                                                                                    <div className="col-md-8">
                                                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                                                            <input {...getInputProps()}
                                                                                                name={fields?.name} />
                                                                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                        </div></div>
                                                                                    <div className="col-md-4">


                                                                                        <div className="thumbcontainer">{(fileUpload ? <div className="dropzone_thum_img">
                                                                                            <div className="dropzone_thumb">
                                                                                                <img
                                                                                                    src={fileUpload}
                                                                                                    className="dropzone_img"
                                                                                                />
                                                                                            </div>
                                                                                        </div> : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                            <div className="dropzone_thumb">
                                                                                                <img
                                                                                                    src={fields?.value}
                                                                                                    className="dropzone_img"
                                                                                                />
                                                                                            </div>
                                                                                        </div> : '')}</div>
                                                                                    </div>
                                                                                    {fileRejectionItems}
                                                                                    <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                                </section>
                                                                                </>
                                                                                : ''

                                                        }
                                                        {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        (custom_menu == 1) ?
                                            <>
                                                <div className="row col-md-12">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Custom Menus <span style={{color:"red" }}>*</span></label>
                                                            <Select
                                                                className="js-states"
                                                                placeholder=""
                                                                options={customMenuOptions}
                                                                values={[]}
                                                                disabled={false}
                                                                multi={true}
                                                                dropdownHandle={true}
                                                                searchable={false}
                                                                labelField={"name"}
                                                                valueField={"id"}
                                                                onChange={(value) => { DropdownCustom(value); }}
                                                              
                                                               
                                                            />
                                                           <div className={(custommenuserror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>Please select custom menus.</div>

                                                        </div>

                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group"><label className="col-md-12">&nbsp;</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { e.preventDefault(); setshowCustommenuCount(true) }}>Set Custom Menu's Count</button>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group"><label className="col-md-12">Custom Items</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { e.preventDefault(); setshowCustommenuItem(true) }}>Select Custom Items</button>
                                                        </div>
                                                    </div>
                                                    <div className={(customerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>Please select custom items.</div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            {selectedDatas.length > 0 && selectedDatas.map((item) => (
                                                                <span id="submit_btn" className="badge badge-info" onClick={(e) => { e.preventDefault(); }}>{item.item_name}</span>
                                                            ))}
                                                        </div>
                                                    </div>

                                                </div>

                                                <Modal size="lg" show={showCustommenuCount} backdrop="static">
                                                    <Modal.Header>
                                                        <Modal.Title>Set Custom Menus Count</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className={(minmaxerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>{formerrormsg}</div>
                                                        {
                                                            <TableContainer component={Paper}>
                                                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="">
                                                                                Menu
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="">
                                                                                Min
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="">
                                                                                Max
                                                                            </StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>

                                                                        {
                                                                            customenuCountDatas && customenuCountDatas.map((row, index) => (
                                                                                <StyledTableRow>
                                                                                    <StyledTableCell align="">
                                                                                        {row?.name}
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            value={row?.min}
                                                                                            name="min"
                                                                                            type="number"
                                                                                            max={row?.match_count}
                                                                                            required=""
                                                                                            onChange={(e) => { changeMinMax(e, index, "min") }}
                                                                                        />
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            value={row?.max}
                                                                                            name="max"
                                                                                            type="number"
                                                                                            max={row?.match_count}
                                                                                            required=""
                                                                                            onChange={(e) => { changeMinMax(e, index, "max") }}
                                                                                        />
                                                                                    </StyledTableCell>
                                                                                </StyledTableRow>
                                                                            ))
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        }
                                                    </Modal.Body>

                                                    <Modal.Footer>
                                                        {/* <Button variant="secondary" onClick={onCloseCustommenuCount}>
                                                            Close
                                                        </Button> */}
                                                        <Button variant="primary" onClick={onSaveCustommenuCount}>
                                                            Save & Close
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>

                                                <Modal size="xl" show={showCustommenuItem} backdrop="static">
                                                    <Modal.Header>
                                                        <Modal.Title>Set Custom Menus Items</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Tabs defaultActiveKey="0">
                                                            {
                                                                custommenuDatasbyID && custommenuDatasbyID.map((row, tindex) => (
                                                                    <Tabs.TabPane tab={TabName(row)} key={tindex}>
                                                                        <div className="row col-md-12 p-5">
                                                                            <label class="fancy-checkbox">
                                                                                <input type="checkbox" className=""
                                                                                    value=""
                                                                                    name=""
                                                                                    required=""
                                                                                    checked={(typeof (selectallitem[tindex]) === "undefined") ? false : selectallitem[tindex]}
                                                                                    onChange={(e) => selectAll(row, tindex)}
                                                                                /><span>Select All Items</span>
                                                                            </label>
                                                                            {row?.custom_menu_items.length > 0 && row?.custom_menu_items.map((item, rindex) => (
                                                                                <div className="col-md-3">
                                                                                    <div className="card card-border text-center">
                                                                                        <label class="fancy-checkbox float-right" style={{ position: "absolute", left: "85%", top: "2%" }}>
                                                                                            <input type="checkbox" className=""
                                                                                                value=""
                                                                                                name={rindex}
                                                                                                checked={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? false : true}
                                                                                                //checked={(_.indexOf(rowcheckbox[tindex], rindex) < 0) ? false : true}
                                                                                                required=""
                                                                                                onClick={(e) => selectItems(row, tindex, rindex, item)}
                                                                                            // (rowcheckbox[tindex][rindex] == true) ? true :
                                                                                            /><span></span>
                                                                                        </label>
                                                                                        <label for={rindex}>
                                                                                            <img src={assestURL + item?.image}
                                                                                                onClick={(e) => selectItems(row, tindex, rindex, item)} style={{ width: "auto", height: "125px", marginTop: "10px" }} class="card-img-top" alt="..." />
                                                                                        </label>
                                                                                        <div className="card-body">
                                                                                            <h5 class="card-title">{item?.name}</h5>
                                                                                            <p className="card-text">{item?.price}</p>
                                                                                            <div>
                                                                                                <table>
                                                                                                    {chosenItems && chosenItems.map((items, ciindex) => (
                                                                                                        <tr>
                                                                                                            <td style={{ width: "20%" }}>
                                                                                                                <label>{items?.name}</label>
                                                                                                            </td>
                                                                                                            <td style={{ width: "80%" }} className={(rewardhide) ? "hide" : ""}>
                                                                                                                <input type="text"
                                                                                                                    className="form-control"
                                                                                                                    value={ItemPrice(item?.price, (_.indexOf(rowcheckbox[tindex], rindex) < 0), ciindex, item)}
                                                                                                                    name="priceItem"
                                                                                                                    onChange={(e) => ChangeItemPrice(e, ciindex, item)}
                                                                                                                    disabled={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? true : false}
                                                                                                                //disabled={(_.indexOf(rowcheckbox[tindex], rindex) < 0) ? true : false} 
                                                                                                                />
                                                                                                                {/* //(rowcheckbox[tindex][rindex] == true) ? false : */}
                                                                                                            </td>
                                                                                                            <td style={{ width: "80%" }} className={(rewardhide) ? "" : "hide"}>
                                                                                                                <input type="text"
                                                                                                                    className="form-control"
                                                                                                                    value={ItemPoints(0, (_.indexOf(rowcheckbox[tindex], rindex) < 0), ciindex, item)}
                                                                                                                    name="priceItem"
                                                                                                                    onChange={(e) => ChangeItemPoints(e, ciindex, item)}
                                                                                                                    disabled={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? true : false}
                                                                                                                //disabled={(_.indexOf(rowcheckbox[tindex], rindex) < 0) ? true : false} 
                                                                                                                />
                                                                                                                {/* //(rowcheckbox[tindex][rindex] == true) ? false : */}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </table>
                                                                                            </div><br />
                                                                                            <label class="fancy-checkbox">
                                                                                                <input type="checkbox" className=""
                                                                                                    value=""
                                                                                                    name={"default-" + rindex}
                                                                                                    required=""
                                                                                                    checked={(_.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id }) >= 0) ? true : false}
                                                                                                    onChange={(e) => selectDefault(row, tindex, rindex, item)}
                                                                                                    //disabled={(_.indexOf(rowcheckbox[tindex], rindex) < 0) ? true : false}
                                                                                                    disabled={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? true : false}
                                                                                                /><span>Set Default</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <img src={assestURL + item?.image} style={{ width: "auto", height: "125px" }} />
                                                                                    {chosenItems && chosenItems.map((items, index) => (
                                                                                        <>
                                                                                            <div> {items?.name}</div>
                                                                                            <div> {item?.price}</div>
                                                                                        </>
                                                                                    ))}
                                                                                    <div>{item?.name}</div>
                                                                                    <div>{item?.price}</div> */}

                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                ))

                                                            }
                                                        </Tabs>
                                                    </Modal.Body>

                                                    <Modal.Footer>
                                                        {/* <Button variant="secondary" onClick={onCloseCustommenuItem}>
                                                            Close
                                                        </Button> */}
                                                        <Button variant="primary" onClick={onSaveCustommenuItem}>
                                                            Save & Close
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </>
                                            : ""
                                    }
                                    <button id="submit_btn" className="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitCategory(e); }}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={showPreview} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div> 
                        {/* <button onClick={zoomIn}>Zoom in</button>
                        <button onClick={zoomOut}>Zoom out</button> */}
                        {imgSRC && (
                        <ReactCrop
                            crop={crop}
                            //onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onChange={c => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={false}
                            maxHeight={maxProductHeight}
                            maxWidth={maxProductWidth}
                            minWidth={maxProductWidth}
                            minHeight={maxProductHeight}
                            locked={true}
                            style={{ overflow: "scroll", height: "400px" , width: imgSRCwidth}}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSRC}
                                style={{ transform:`scale(${scale})`, width: imgSRCwidth, height: imgSRCheight, maxWidth: "unset" }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}

                        {!!completedCrop && (
                            <>
                                <div style={{ display: "none" }}>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </div>
                            </>)}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={cropImg}>
                        Crop & Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddProducts;
