import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BetList, EventListApi, EventOddsApi, EventOddsUpdateApi, TransactionList, updateTranscation } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import alertmessage from '../../utils/alert';
const namespace = "bet";

const initialState = {
    loading: "initial",
    updateloading: "initial",
    errorMessage: null,
    betDatas: null,
    events: [],
    eventCount:0,
    eventOdds: [],
    eventOddsCount:0,
    eventDetails:null,
    betCounts: 0,
};

export const BetDataFunc = createAsyncThunk(
    `${namespace}/BetDataFunc`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await BetList({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const EventList = createAsyncThunk(
    `${namespace}/EventList`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await EventListApi({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const EventOdds = createAsyncThunk(
    `${namespace}/EventOdds`,
    async ({ payload,eventId }, { rejectWithValue, dispatch }) => {
        try {
            const data = await EventOddsApi({ payload },eventId);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const BetSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearBetLoadingDatas: (state) => {
            state.loading = "initial";
            state.updateloading = "initial";
        },
        clearBetUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [BetDataFunc.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [BetDataFunc.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = payload
            console.log(payloaddatas, 'payloaddatas--load');
            state.betCounts = payloaddatas?.data?.data?.count;
            state.betDatas = payloaddatas?.data?.data?.result;
        },
        [EventList.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [EventList.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = payload
            state.events = payloaddatas?.data?.data?.result;
            state.eventCount = payloaddatas?.data?.data?.count;
        },
        [EventOdds.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [EventOdds.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = payload
            state.eventOdds = payloaddatas?.data?.data?.result;
            state.eventDetails = payloaddatas?.data?.data?.eventDetails;
            state.eventOddsCount = payloaddatas?.data?.data?.count;
        },
        [BetDataFunc.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },

    }
});

export const { clearData, clearBetLoadingDatas, clearBetUpdateLoading, clearErrormsg } = BetSlice.actions;

export const BetSelector = (state) => state.bets;

export default BetSlice.reducer;