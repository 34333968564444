import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { rouletteGamesList, roulettebetList} from "../../services/api";
import { toast } from "react-toastify";
const namespace = "roulettegames";

const initialState = {
    bets:[],
    games:[],
    betsCount:0,
    gamesCount:0
};

export const getrouletteGames = createAsyncThunk(
    `${namespace}/list`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await rouletteGamesList({ payload });
            return data;
        } catch (error) {
            toast.error('Something went wrong.. Try again..')
            return rejectWithValue(error.response);
        }
    }
);

export const getrouletteGameBets = createAsyncThunk(
    `${namespace}/bets`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await roulettebetList({ payload });
            return data;
        } catch (error) {
            toast.error('Something went wrong.. Try again..')
            return rejectWithValue(error.response);
        }
    }
);

const rouletteGamesSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
       
        [getrouletteGameBets.fulfilled](state, { payload }) {
            state.betsCount = payload?.data?.data?.count;
            state.bets = payload?.data?.data?.result;
        },
        [getrouletteGames.fulfilled](state, { payload }) {
            state.gamesCount = payload?.data?.data?.count;
            state.games = payload?.data?.data?.result;
        },
       
    },
});

export const { clearData} = rouletteGamesSlice.actions;

export const rouletteGamesSelector = (state) => state.roulette;

export default rouletteGamesSlice.reducer;
