import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { API_STATUS } from "../../utils/constants";
import Select from "react-dropdown-select";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { DateFormat } from "../../services/config";
import { sportsField } from "../fields/sportsform";
import { Tooltip } from 'antd';
import { useDropzone } from 'react-dropzone';
import { sportsSelector, AddSportsfunc, clearSportsAddLoading, clearSportsDeleteLoading, clearSportsLoadingDatas, clearSportsUpdateLoading, clearErrormsg } from "../../store/reducer/sports";
import { imgaeUpload } from "../../services/api";
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';

function AddSports() {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const MySwal = withReactContent(Swal)
  const simpleValidator = useRef(new SimpleReactValidator())
  const { sportsDatas, sportsCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(sportsSelector)
  const resetForm = sportsField;
  const [formFields, setFormFields] = useState(sportsField);
  const [showAdd, setshowAdd] = useState(false);
  const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]
  const RegionDropDown = [{ name: 'Mumbai', id: 1 }, { name: 'Kolkata', id: 2 }, { name: 'Chennai', id: 3 }, { name: 'Hyderabad', id: 4 }]
  const [showvalidator, setShowvalidator] = useState(false);
  const [fileUpload, setfileUpload] = useState('');
  const [filename, setfilename] = useState('');
  const [fileerror, setfileerror] = useState(false);
  const [filesizeerror, setfilesizeerror] = useState(false);
  const [filePath, setfilePath] = useState('');
  const [updateID, setUpdateID] = useState('');
  const fileReader = new FileReader();
  const [files, setFiles] = useState([]);
  const [uploadfiles, setUploadFiles] = useState([]);
  const [fileName, setfileName] = useState("")
  let [thumbsimage, setThumbsImage] = useState([]);
  const maxProductWidth = 600;
  const maxProductHeight = 600;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([])
  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    console.log(file.preview, 'file--preview');
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList)
    setfileName(newFileList && newFileList.length && newFileList[0].name)
    console.log(newFileList, 'newFileList');
    if (newFileList && newFileList.length > 0) {
      let originalUrl = await getBase64(newFileList[0].originFileObj);
      setfileUpload(originalUrl)
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const FieldChange = (e, index) => {
    console.log(e.target.value, formFields[index], 'fieldImage');
    formFields[index].value = e.target.value;
    console.log(formFields);
    setFormFields([...formFields]);
  };

  const DropdownChange = (value, index) => {
    console.log(value)
    formFields[index].value = value;
    setFormFields([...formFields]);
  };

  const onChangeTime = (value, time, index, name) => {
    formFields[index].value = value;
    setFormFields([...formFields]);
  };

  // const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
  //   console.log(acceptedFiles, 'acceptedFiles');
  //   setFiles(acceptedFiles.map(file => Object.assign(file, {
  //     preview: URL.createObjectURL(file)
  //   })));
  //   setUploadFiles(...uploadfiles, acceptedFiles);
  //   let formdata = new FormData();
  //   formdata.append("file", acceptedFiles)


  //   //setfileUpload(binaryStr);
  //   acceptedFiles.forEach((file) => {
  //     console.log(file);
  //     formdata.append("fileToUpload", file);
  //     setfilename(file.name)
  //     const reader = new FileReader()

  //     reader.onabort = () => console.log('file reading was aborted')
  //     reader.onerror = () => console.log('file reading has failed')
  //     reader.onload = () => {
  //       // Do whatever you want with the file contents
  //       const binaryStr = reader.result

  //       const img = new Image();
  //       img.onload = () => {
  //         const width = img.naturalWidth;
  //         const height = img.naturalHeight;


  //         if (width != maxProductWidth || height != maxProductHeight) {
  //           const errorMessage = {
  //             file,
  //             errors: [
  //               {
  //                 code: "file-invalid-dimensions",
  //                 message: 'The image dimensions must be at least ' + maxProductWidth + ' x ' + maxProductHeight + ' pixels',
  //               },
  //             ],
  //           };

  //           rejectedFiles.push(errorMessage);
  //           setThumbsImage([]);

  //         } else {

  //           console.log(`Image dimensions: ${width}x${height}`);
  //           setfileUpload(binaryStr);
  //           setfilePath(file.path);
  //           setfileerror(false);
  //         }
  //       };
  //       img.src = binaryStr;
  //     }
  //     reader.readAsDataURL(file)
  //   })

  //   const data = await imgaeUpload(formdata);
  //   console.log("getCount--> ", data);
  // }, [])

  // useEffect(() => {
  //   const thumbs = files.map(file => (
  //     <div className="dropzone_thum_img" key={file.name}>
  //       <div className="dropzone_thumb">
  //         <img
  //           src={file.preview}
  //           className="dropzone_img"
  //           // Revoke data uri after image is loaded
  //           onLoad={() => { URL.revokeObjectURL(file.preview) }}
  //         />
  //       </div>
  //     </div>
  //   ));
  //   setThumbsImage(thumbs);
  // }, [files])


  // const { getRootProps, getInputProps, fileRejections } = useDropzone({
  //   onDrop, accept: {
  //     'image/*': [],
  //   }, multiple: true,
  //   maxSize: 1024000,
  // })  

  // const fileRejectionItems = fileRejections.map(({ file, errors }) => (
  //   <div class="invalid-feedback show">
  //     {errors.map(e => (
  //       <span>{(e.message == 'File is larger than 1024000 bytes') ? 'File size should be less than 1 MB' : e.message}</span>
  //     ))}
  //   </div>
  // ));

  const submitSports = (e) => {
    console.log(formFields, 'submmit admin', simpleValidator.current.allValid());
    if (simpleValidator.current.allValid()) {
      //alert('You submitted the form and stuff!');
      let arr = {};
      // if (fileUpload == '' && updateID == "") {
      //     setfileerror(true);
      //     return false;
      // }
      if (filesizeerror == true) {
        return false
      }

      const datas = formFields.map((data) => {
        if (data?.name === "status") {
          let val = data.value;
          console.log(val)
          //data.value = val[0].id;
          return arr[data?.name] = val[0].id;
        } else {
          return arr[data?.name] = data.value;
        }
      });
      console.log(arr);
      // if (arr.date != "") {
      //     arr.from_date = dayjs(new Date(arr.date[0])).format('YYYY-MM-DD')
      //     arr.to_date = dayjs(new Date(arr.date[1])).format('YYYY-MM-DD')
      // } else {
      //     arr.from_date = null
      //     arr.to_date = null
      // }
      const postDatas = arr;
      console.log(postDatas, "postDatas")
      postDatas.image_name = fileName;
      postDatas.image_binary = fileUpload;
      //return false;
      // dispatch(AddBanner({ postDatas, uploadfiles }));
      dispatch(AddSportsfunc({ postDatas }))
    } else {
      // console.log(fileUpload, updateID)
      // if (fileUpload == '' && updateID == "") {
      //     setfileerror(true);
      // }
      setShowvalidator(true);
    }
  };

  const imageOnChange = (e) => {
    // e.preventDefault();
    const image = e.target.files[0];
    // if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
    console.log(image, 'Select Image');
    //   toast.error('Select Valid Image - (jpg,jpeg,png)')
    //   return false;
    // } else {
    const reader = new FileReader();
    const file = e.target.files[0];
    setfileName(file.name)

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onloadend = () => {
      const binaryStr = reader.result
      setfileUpload(binaryStr)
    }
    reader.readAsDataURL(file);
  }


  useEffect(() => {
    simpleValidator.current.showMessages()
    if (addloading === API_STATUS.FULFILLED) {
      setshowAdd(false);
      toast.dismiss();
      toast.success("Added Successfully!");
      dispatch(clearSportsAddLoading())
      navigate('/sportlist');
    }
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage.error);
      // if (errorMessage.error)
      // else if (errorMessage.errors) {
      //     let data = "";
      //     errorMessage?.errors.map((err) => {
      //         data += err.message + " ";
      //     })
      //     toast.error(data);
      // }
      setshowAdd(true);
      dispatch(clearErrormsg())
    }
  }, [addloading, errorMessage]);


  return (
    <div>
      <div className="container-fluid">
        <PageHeader
          HeaderText="Add Sports"
          Breadcrumb={[{ name: "Add Sports" }]}
        />
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <div className="row">
                  <div className="col-md-6">
                    <h2>Add Sports</h2>
                  </div>
                  <div className="col-md-6 row">
                    <div className="col-md-12">
                      <Link to='/sportlist' className="btn btn-primary mr-1  float-right"> Cancel</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"body"}>
                <form className="ng-untouched ng-dirty ng-invalid">
                  <div className="row">
                    {
                      formFields && formFields.map((fields, index) => {
                        return (
                          <div className={(fields?.input == "file") ? "col-md-12" : "col-md-6"}>
                            <div className="form-group">
                              <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                              {(fields?.input == "text") ?
                                <input
                                  className={`form-control ` + fields?.class}
                                  value={fields?.value}
                                  name={fields?.name}
                                  required=""
                                  onChange={(e) => { FieldChange(e, index) }}
                                />
                                : (fields?.input == "textarea") ?
                                  <textarea
                                    className="form-control"
                                    value={fields?.value}
                                    cols="30"
                                    name="textarea"
                                    required=""
                                    rows="2"
                                    onChange={(e) => {
                                      FieldChange(e, index)
                                    }}
                                  ></textarea>
                                  : (fields?.input == "dropdown") ?
                                    <Select
                                      className="js-states"
                                      placeholder=""
                                      options={fields?.name == "status" ? statusDropdown : RegionDropDown}
                                      values={fields?.value}
                                      disabled={false}
                                      multi={false}
                                      dropdownHandle={true}
                                      searchable={false}
                                      labelField={"name"}
                                      valueField={"id"}
                                      onChange={(value) => { DropdownChange(value, index); }}
                                    />
                                    :
                                    (fields?.input == "rangepicker") ?
                                      <RangePicker onChange={(time, timeString) => {
                                        console.log(timeString);
                                        onChangeTime(time, timeString, index, fields?.name);
                                      }}
                                        format={DateFormat}
                                        className="form-control antformrange" />
                                      :
                                      (fields?.input == "file") ?
                                        <>&nbsp;
                                          <Upload
                                            // action={""}
                                            // https://www.mocky.io/v2/5cc8019d300000980a055e76
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                            beforeUpload={() => { return false }}
                                          >
                                            {fileList.length >= 1 ? null : uploadButton}
                                          </Upload>
                                          {/* <Tooltip title={fields?.placeHolder}>
                                            <span className="icon-question"></span>
                                          </Tooltip><br />
                                          <section className="row container">
                                            <div className="col-md-6">
                                              {/* <div {...getRootProps({ className: 'dropzone' })}> 
                                              <input
                                                name={fields?.name}
                                                className="form-control"
                                                accept={"image"}
                                                multiple={"multiple"}
                                                type={fields?.type}
                                                // style={{ display: "none" }}
                                                tabIndex={-1}
                                                onChange={(e) => { imageOnChange(e) }}
                                              />
                                              {/* <p>Drag 'n' drop some files here, or click to select files</p> 
                                            </div>
                                            {/* </div> 
                                            <div className="col-md-12">
                                              <div className="thumbcontainer">{(thumbsimage && thumbsimage.length > 0 ? thumbsimage : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                <div className="dropzone_thumb">
                                                  <img
                                                    src={fields?.value}
                                                    className="dropzone_img"
                                                  />
                                                </div>
                                              </div> : '')}</div>
                                            </div>
                                            {/* {fileRejectionItems} 
                                            <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                          </section> */}
                                        </> : ''

                              }
                              {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                            </div>
                          </div>
                        );
                      })
                    }
                    <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitSports(e); }}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
}

export default AddSports;