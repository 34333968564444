import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from "react-router-dom";
import PrivateRoute from '../PrivateRoute';
import Dashboard from "./dashboard";

const RoutesLink = () => {
    return (
        <Route path="/" element={<PrivateRoute />} >
            <Route exact path="/dashboard" element={<Dashboard />} />
        </Route>
    );
}
export default connect(null)(RoutesLink);