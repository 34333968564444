export const profileFields =[
    { label: "Name *", type: "text", name: "name", placeHolder: "Name", class: "", value: "", input: "text", validation: "required" },
    { label: "Mobile *", type: "text", name: "mobile", placeHolder: "Mobile", class: "", value: "", input: "text", validation: "required|integer" },
    { label: "Email *", type: "text", name: "email", placeHolder: "Email", class: "", value: [], input: "text", validation: "required|email" },
    { label: "Password *", type: "text", name: "password", placeHolder: "Password", class: "", value: [], input: "text", validation: "required" },
    // { label: "Region *", type: "text", name: "region", placeHolder: "Region", class: "", value: "", input: "textarea", validation: "required" },
    // { label: "Address ", type: "text", name: "address", placeHolder: "Address", class: "", value: "", input: "textarea", validation: "" },
    { label: "Alter Mobile", type: "text", name: "altermobile", placeHolder: "Alter Mobile", class: "", value: "", input: "text", validation: "integer" },
    { label: "Status", type: "text", name: "status", placeHolder: "Status", class: "", value: [{ name: "Active", id: "1" }], input: "dropdown", validation: "", options: "status" }

]