import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import dayjs from 'dayjs';
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { BetDataFunc, BetSelector } from "../../store/reducer/bet";
import { Button, Chip } from "@mui/material";
import { betReleaseAmountApi } from "../../services/api";


function Betlist() {
    const dispatch = useDispatch();
    const { betDatas, betCounts } = useSelector(BetSelector);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [sortBy, setSortBy] = useState("createdOn");
    let [order, setOrder] = useState("desc");
    let [searchQuery, setSearchQuery] = useState("");

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        BetListDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        BetListDispatch();
    };



    const BetListDispatch = () => {
        let payloadOrder = order == 'asc' ? 1 : -1;

        dispatch(
            BetDataFunc({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery
                }
            })
        )
    }

    useEffect(() => {
        let payloadOrder = order == 'asc' ? 1 : -1;
        dispatch(
            BetDataFunc({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    // filter: { 'userType': 0 },
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery,
                }
            })
        )
    }, [])

    const releaseAmountFunc = async (id)=>{
        try {
            await betReleaseAmountApi({
                payload:{
                    id
                }
            })
            BetListDispatch();
        } catch (error) {
            BetListDispatch();
        }
    }   

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Bet List"
                    Breadcrumb={[{ name: "Bet List" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Bet List</h2>
                                    </div>
                                    <div className="col-md-12 row">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                User Name
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Event
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Odds
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Odds Value
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Amount
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Status
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                createdOn
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Action
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            betDatas && betDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.user?.name}<br></br>
                                                        {row.user?.email}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.event}<br></br>
                                                        {row.league}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.text}<br></br>
                                                        {row.selectedOption}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.odds}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.amount}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status == 'placed' ? <Chip size="small" color="success" label="Placed" /> : ''}
                                                        {row.status == 'succedded' ? <Chip size="small" color="success" label="Succuss" /> : ''}
                                                        {row.status == 'failed' ? <Chip size="small" color="error" label="Lost" /> : ''}
                                                        {row.status == 'in-review' ? <Chip size="small" color="warning" label="In Review" /> : ''}
                                                        {row.status == 'credited' ? <Chip size="small" color="success" label="Credited to wallet" /> : ''}
                                                        {row.status == 'cashout-credited' ? <Chip size="small" color="success" label="Sold" /> : ''}
                                                        {row.status == 'cashout' ? <Chip size="small" color="info" label="Sell Requested" /> : ''}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.createdOn ?
                                                            dayjs(new Date(row.createdOn)).format('YYYY-MM-DD hh:mm:ss A') :
                                                            null

                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status == 'succedded' ? <Button color="success" size="small" variant="outlined" onClick={() => {
                                                            releaseAmountFunc(row._id)
                                                        }}>Release Amount</Button> : row.status == 'cashout' ? <Button color="info" size="small" variant="outlined" onClick={() => {
                                                            releaseAmountFunc(row._id)
                                                        }}>Release Amount</Button>:''}
                                                    </StyledTableCell>

                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={betCounts}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Betlist