import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/horizon-full.svg";
import { useNavigate, useParams, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import { DatePicker, Space } from 'antd';
import { imgaeUpload } from "../../services/api";
import { Tooltip } from 'antd';
import { API_STATUS } from "../../utils/constants";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL, DateFormat } from "../../services/config";
import dayjs from 'dayjs';
import { subAdminField } from "../fields/subadminform";
// import { clearSubAdminAddLoading, subadminSelector, clearErrormsg, AddSubAdminfunc, subAdminData, subAdminById, EditSubAdminfunc } from "../../store/reducer/subadmin";
import { sportsField } from "../fields/sportsform";
import { EditSportsFunc, SportsByIdFunc, sportsSelector, clearErrormsg } from "../../store/reducer/sports";
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';


function EditSports() {
  const params = useParams();
  const [editID, setEditID] = useState(params?.id);
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const MySwal = withReactContent(Swal)
  const simpleValidator = useRef(new SimpleReactValidator())
  // const { bannerDataById, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(bannerSelector);
  // const { subAdminDataById, editSubAdminDatas, subAdminDatas, subAdminCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(subadminSelector);
  const { sportsById, editSportsDatas, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(sportsSelector);

  const resetForm = sportsField;
  const [formFields, setFormFields] = useState(sportsField);
  const [showAdd, setshowAdd] = useState(false);
  const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  const [showvalidator, setShowvalidator] = useState(false);
  const [fileUpload, setfileUpload] = useState([]);
  const [filename, setfilename] = useState('');
  const [fileerror, setfileerror] = useState(false);
  const [filesizeerror, setfilesizeerror] = useState(false);
  const [filePath, setfilePath] = useState('');
  const [updateID, setUpdateID] = useState();
  const fileReader = new FileReader();
  const [files, setFiles] = useState([]);
  const [uploadfiles, setUploadFiles] = useState([]);
  const [editDatas, setEditDatas] = useState([]);
  const [deletedImgs, setdeletedImgs] = useState([]);
  const [fileName, setfileName] = useState("")


  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([])
  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    console.log(file.preview, 'file--preview');
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList)
    setfileName(newFileList && newFileList.length && newFileList[0].name)
    if (newFileList && newFileList.length > 0) {
      let originalUrl = await getBase64(newFileList[0].originFileObj);
      setfileUpload(originalUrl)
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );



  const FieldChange = (e, index) => {
    console.log(e.target.value, formFields[index]);
    formFields[index].value = e.target.value;
    console.log(formFields);
    setFormFields([...formFields]);
  };

  const DropdownChange = (value, index) => {
    console.log(value)
    formFields[index].value = value;
    setFormFields([...formFields]);
  };

  const onChangeTime = (value, time, index, name) => {
    formFields[index].value = value;
    setFormFields([...formFields]);
  };

  const deleteImg = (imgs, index) => {
    let img_index = _.findIndex(formFields, { name: "icon" })
    if (img_index >= 0) {
      let images = formFields[img_index].value;
      console.log(images, "hi")
      _.unset(images, index);
      images = images.filter(function (element) {
        return element !== undefined;
      });
      console.log(images);
      formFields[img_index].value = images;
      console.log(formFields)
      setFormFields([...formFields])
      setdeletedImgs([...deletedImgs, imgs]);
    }

  };

  const submitEditSports = (e) => {
    if (simpleValidator.current.allValid()) {
      //alert('You submitted the form and stuff!');
      let arr = {};
      console.log(fileName, fileUpload, 'file-name-upload');
      console.log(formFields, 'formFieldsSubmit');
      const datas = formFields.map((data) => {
        console.log(data, 'data--data');
        if (data?.name === "status") {
          let val = data.value;
          console.log(val, 'value--status')
          //data.value = val[0].id;
          if (val.length)
            return arr[data?.name] = val[0].id;
        } else {
          return arr[data?.name] = data.value;
        }
      });
      const postDatas = arr;
      console.log(postDatas, "postDatas")
      postDatas.image_name = fileName;
      postDatas.image_binary = fileUpload;
      dispatch(EditSportsFunc({ postDatas, editID }))
    } else {
      if (fileUpload == '' && updateID == "") {
        setfileerror(true);
      }
      setShowvalidator(true);
    }
  };

  useEffect(() => {
    if (editID)
      dispatch(SportsByIdFunc({ editID }));
  }, [])
  // useEffect(() => {
  //     console.log(formFields, "formFields")
  //     console.log(deletedImgs, "deletedImgs")
  // }, [formFields, deletedImgs])

  useEffect(() => {
    if (sportsById) {
      console.log(sportsById, "productDataById")
      setEditDatas(sportsById);
      const row = sportsById;
      setUpdateID(row.id);
      let date_val = [];
      if (row.from_date && row.from_date != '') {
        let from_date = dayjs(row.from_date).format(DateFormat);
        let to_date = dayjs(row.to_date).format(DateFormat);
        date_val = [dayjs(from_date, DateFormat), dayjs(to_date, DateFormat)]
      }

      let chosen_data = []
      console.log(row, formFields, 'row--formFields');
      let data = formFields.map((data, index) => {
        console.log(data, row, 'data--formfield');
        if (data.name === "sports_name") {
          data.value = row.sportName;
        }
        if (data.name === 'sports_id') {
          data.value = row.sportId;
        }
        if (data.name === 'email') {
          data.value = row.email;
        }
        if (data.name === 'address') {
          data.value = row.address
        }
        if (data.name === "icon") {
          data.value = row.icon
          setFileList([{ url: assestURL + `static/${row.icon}` }])
        }
        if (data.name === 'altermobile') {
          data.value = row.altermobile
        }
        if (data.name === 'draw_amount') {
          data.value = row.draw_amount
        }
        if (data.name === "status") {
          let set_status = _.filter(statusDropdown, { id: row.status });
          console.log(set_status[0]);
          data.value = set_status;
        }
        if (data.name === "image") {
          console.log(row.images, "imgs")
          let imgs = row.images.split(",");
          let img_urls = [];
          imgs.forEach((file) => {
            img_urls.push(file);
          });
          data.value = img_urls;
          console.log(imgs, img_urls, "imgs")
          if (imgs && imgs.length > 0)
            setFileList([{ url: assestURL + `static/${imgs[0]}` }])
        }
      });
      console.log(data, 'data__row');
    }
  }, [sportsById])

  useEffect(() => {
    simpleValidator.current.showMessages()
    console.log(loading, "loading")
    if (updateloading === API_STATUS.FULFILLED) {
      setshowAdd(false);
      toast.dismiss();
      toast.success("Updated Successfully!");
      // dispatch(clearBannUpdateLoading())
      navigate('/sportlist');
    }
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage.error);
      // if (errorMessage.message)
      // else if (errorMessage.errors) {
      //     let data = "";
      //     errorMessage?.errors.map((err) => {
      //         data += err.message + " ";
      //     })
      //     toast.error(data);
      // }
      setshowAdd(true);
      dispatch(clearErrormsg())

    }
  }, [updateloading, errorMessage]);


  const imageOnChange = (e) => {
    // e.preventDefault();
    const image = e.target.files[0];
    // if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
    console.log(image, 'Select Image');
    //   toast.error('Select Valid Image - (jpg,jpeg,png)')
    //   return false;
    // } else {
    const reader = new FileReader();
    const file = e.target.files[0];
    setfileName(file.name)

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onloadend = () => {
      const binaryStr = reader.result
      setfileUpload(binaryStr)
    }
    reader.readAsDataURL(file);
  }


  return (
    <div>
      <div className="container-fluid">
        <PageHeader
          HeaderText="Edit Sports"
          Breadcrumb={[{ name: "Edit Sports" }]}
        />
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <div className="row">
                  <div className="col-md-6">
                    <h2>Edit Sports</h2>
                  </div>
                  <div className="col-md-6 row">
                    <div className="col-md-12">
                      <Link to='/sportlist' className="btn btn-danger mr-1  float-right"> Cancel</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"body"}>
                <form className="ng-untouched ng-dirty ng-invalid">
                  <div className="row">
                    {
                      formFields && formFields.map((fields, index) => {
                        console.log(fields, 'img_fields');
                        return (
                          <div className={(fields?.input == "file") ? "col-md-12" : "col-md-6"}>
                            <div className="form-group">
                              <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                              {(fields?.input == "text") ?
                                <input
                                  className={`form-control ` + fields?.class}
                                  value={fields?.value}
                                  name={fields?.name}
                                  required=""
                                  onChange={(e) => { FieldChange(e, index) }}
                                />
                                : (fields?.input == "textarea") ?
                                  <textarea
                                    className="form-control"
                                    value={fields?.value}
                                    cols="30"
                                    name="textarea"
                                    required=""
                                    rows="2"
                                    onChange={(e) => {
                                      FieldChange(e, index)
                                    }}
                                  ></textarea>
                                  : (fields?.input == "dropdown") ?
                                    <Select
                                      className="js-states"
                                      placeholder=""
                                      options={statusDropdown}
                                      values={fields?.value}
                                      disabled={false}
                                      multi={false}
                                      dropdownHandle={true}
                                      searchable={false}
                                      labelField={"name"}
                                      valueField={"id"}
                                      onChange={(value) => { DropdownChange(value, index); }}
                                    />
                                    : (fields?.input == "file") ?
                                      <>&nbsp;
                                        <Upload
                                          // action={""}
                                          // https://www.mocky.io/v2/5cc8019d300000980a055e76
                                          listType="picture-card"
                                          fileList={fileList}
                                          onPreview={handlePreview}
                                          onChange={handleChange}
                                          beforeUpload={() => { return false }}
                                        >
                                          {fileList.length >= 1 ? null : uploadButton}
                                        </Upload>
                                        {/* <Tooltip title={fields?.placeHolder}>
                                          <span className="icon-question"></span>
                                        </Tooltip><br />
                                        <section className="row container">
                                          <div className="col-md-6">
                                            {/* <div
                                              {...getRootProps({
                                                className: 'dropzone'
                                              })}> 
                                            <input
                                              name={fields?.name}
                                              className="form-control"
                                              accept={"image"}
                                              multiple={"multiple"}
                                              type={fields?.type}
                                              // style={{ display: "none" }}
                                              tabIndex={-1}
                                              onChange={(e) => { imageOnChange(e) }}
                                            />
                                            {/* <p>Drag 'n' drop some files here, or click to select files</p> 
                                          </div>
                                          {/* </div> 
                                          <div className="col-md-12">
                                            <div className="thumbcontainer">{(thumbs && thumbs.length > 0 ? thumbs : '')}
                                              {(fields?.value != '') ? <div className="dropzone_thum_img">
                                                <a href="javascript:void(0);" className="close_img" onClick={(e) => {
                                                  // deleteImg(fields?.value, index)
                                                }}>
                                                  {/* <i className="error fa fa-times"></i>
                                                </a>
                                                <div className="dropzone_thumb">
                                                  <img
                                                    src={`${assestURL}static/${fields?.value}`}
                                                    className="dropzone_img"
                                                  />
                                                </div>
                                              </div> : ''}</div>
                                          </div>
                                          <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                        </section> */}
                                      </>
                                      : (fields?.input == "rangepicker") ?
                                        <RangePicker onChange={(time, timeString) => {
                                          console.log(timeString);
                                          onChangeTime(time, timeString, index, fields?.name);
                                        }}
                                          value={fields?.value}
                                          format={DateFormat}
                                          className="form-control antformrange" />
                                        : ''

                              }
                              {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                            </div>
                          </div>
                        );
                      })
                    }

                    <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitEditSports(e); }}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
}

export default EditSports;

