import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { diceGameBetList} from "../../services/api";
import { toast } from "react-toastify";
const namespace = "dicegames";

const initialState = {
    bets:[],
    betsCount:0
};

export const getDiceBets = createAsyncThunk(
    `${namespace}/bets`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await diceGameBetList({ payload });
            return data;
        } catch (error) {
            toast.error('Something went wrong.. Try again..')
            return rejectWithValue(error.response);
        }
    }
);

const diceGamesSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
       
        [getDiceBets.fulfilled](state, { payload }) {
            state.betsCount = payload?.data?.data?.count;
            state.bets = payload?.data?.data?.result;
        },
       
    },
});

export const { clearData} = diceGamesSlice.actions;

export const diceGamesSelector = (state) => state.dice;

export default diceGamesSlice.reducer;
