import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addSubadmin, editSubadmin, subAdminList, subadminbyid } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from '../../utils/alert';
import { toast } from "react-toastify";
const namespace = "subadmin";


const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    subAdminDatas: null,
    subAdminCount: 0,
    subAdminDataById: null,
    editSubAdminDatas: null
};



export const subAdminData = createAsyncThunk(
    `${namespace}/subAdminData`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await subAdminList({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddSubAdminfunc = createAsyncThunk(
    `${namespace}/AddSubAdminfunc`,
    async ({ postDatas }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postDatas, "interdata");
            // let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            // console.log(payload, '--payload');
            const data = await addSubadmin({ payload: postDatas });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const EditSubAdminfunc = createAsyncThunk(
    `${namespace}/EditSubAdminfunc`,
    async ({ postDatas, editID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postDatas, "interdata");
            // let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const data = await editSubadmin({ payload: postDatas }, editID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
)
export const subAdminById = createAsyncThunk(
    `${namespace}/subAdminById`,
    async ({ editID }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await subadminbyid(editID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const subAdminSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearSubAdminLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearSubAdminAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearSubAdminUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearSubAdminDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [subAdminData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [subAdminData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.subAdminCount = payload?.data?.count;
            // state.subAdminDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            // console.log(payloaddatas?.data?.count, "payload")
            let payloaddatas = payload
            console.log(payloaddatas, 'payloaddatas');
            state.subAdminCount = payloaddatas?.data?.data?.count;
            state.subAdminDatas = payloaddatas?.data?.data?.result;
        },
        [subAdminData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddSubAdminfunc.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddSubAdminfunc.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddSubAdminfunc.rejected](state, action) {
            console.log('rejected--admin', state, action);
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
            // alertmessage.sweetalert(action?.payload?.data?.message);
        },
        [EditSubAdminfunc.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [EditSubAdminfunc.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
            let payloaddatas = payload?.data?.data
            console.log(payloaddatas, "subAdminById--payload")
            state.editSubAdminDatas = payloaddatas?.result;
        },
        [EditSubAdminfunc.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        // [DeleteSubAdmin.pending](state) {
        //     state.deleteloading = API_STATUS.PENDING;
        // },
        // [DeleteSubAdmin.fulfilled](state, { payload }) {
        //     state.deleteloading = API_STATUS.FULFILLED;
        // },
        // [DeleteSubAdmin.rejected](state, action) {
        //     state.deleteloading = API_STATUS.REJECTED;
        //     state.errorMessage = action?.payload?.data;
        // },
        [subAdminById.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [subAdminById.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = payload?.data?.data
            console.log(payloaddatas, "subAdminById--payload")
            state.subAdminDataById = payloaddatas?.result;
        },
        [subAdminById.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        }
    },
});

export const { clearData, clearSubAdminLoadingDatas, clearSubAdminAddLoading, clearSubAdminUpdateLoading, clearSubAdminDeleteLoading, clearErrormsg } = subAdminSlice.actions;

export const subadminSelector = (state) => state.subadmin;

export default subAdminSlice.reducer;
