import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageHeader from "../../view/pageheader";
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import _ from 'lodash';
import moment from 'moment'
import { Button, Form, Modal } from "react-bootstrap";
import { fastParityGamesSelector, getFastParityGames, getFastParityGameBets } from "../../store/reducer/fastparity";
import CachedIcon from '@mui/icons-material/Cached';
function FastParityGamesList() {
    const dispatch = useDispatch();
    const {
        bets,
        games,
        betsCount,
        gamesCount
    } = useSelector(fastParityGamesSelector);
    const [openBetsModal, setopenBetsModal] = useState(false)
    let [page, setPage] = useState(0);
    let [betPage, setBetPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [betrowsPerPage, setbetRowsPerPage] = useState(10);
    let [currentBetId, setcurrentBetId] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getFastParityGamesList({
            page: newPage,
            limit: rowsPerPage
        });
    };
    const handleChangeRowsPerPage = (event) => {
        let limit = parseInt(event.target.value, 10)
        setRowsPerPage(limit);
        setPage(0);
        getFastParityGamesList({
            page,
            limit
        });
    };

    const bethandleChangePage = (event, newPage) => {
        setBetPage(newPage);
        getFastParityGameBetsList({
            page: newPage,
            limit: betrowsPerPage,
            _id:currentBetId
        });
    };
    const bethandleChangeRowsPerPage = (event) => {
        let limit = parseInt(event.target.value, 10)
        setbetRowsPerPage(limit);
        setBetPage(0);
        getFastParityGameBetsList({
            page:0,
            limit: limit,
            _id:currentBetId
        });
    };


    const getFastParityGameBetsList = ({
        page,
        limit,
        _id
    }) => {
        setcurrentBetId(_id)
        dispatch(
            getFastParityGameBets({
                payload: {
                    betId: _id,
                    page: page + 1,
                    limit: limit,
                }
            })
        )
    }

    const getFastParityGamesList = ({
        page,
        limit
    }) => {
        dispatch(
            getFastParityGames({
                payload: {
                    page: page + 1,
                    limit,
                }
            })
        )
    }

    useEffect(() => {
        getFastParityGamesList({
            page,
            limit: rowsPerPage
        })
    }, [])

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Fast Parity"
                    Breadcrumb={[{ name: "Fast Parity" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Fast Parity</h2>
                                    </div>
                                    <div className="col-md-6 ">
                                        <h2> <Button className="pull-right" variant="light"
                                            onClick={() => {
                                                setPage(0);
                                                getFastParityGamesList({
                                                    page,
                                                    limit: rowsPerPage
                                                })
                                            }}
                                        >
                                            <CachedIcon></CachedIcon>
                                        </Button></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">#</StyledTableCell>
                                            <StyledTableCell align="">Result</StyledTableCell>
                                            <StyledTableCell align="">Date</StyledTableCell>
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            games && games.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.hash}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">

                                                        <Chip sx={{
                                                            fontSize: 17,
                                                            fontWeight: 'bold',
                                                            backgroundColor: row.color
                                                        }} label={row.color ? row.numberVal : '?'}></Chip>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {moment(row.startedOn).format('DD-MM-YYYY HH:mm:ss a')}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <Button variant="light"
                                                            onClick={() => {
                                                                setopenBetsModal(row._id)
                                                                getFastParityGameBetsList({
                                                                    page:0,
                                                                    limit: betrowsPerPage,
                                                                    _id:row._id
                                                                })
                                                            }}
                                                        >
                                                            {row.betCount} Bets
                                                        </Button>

                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={gamesCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="xl" show={openBetsModal} backdrop="static" scrollable centered >
                <Modal.Header>
                    <Modal.Title>Bets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 1000 }} stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="">#</StyledTableCell>
                                    <StyledTableCell align="">Name</StyledTableCell>
                                    <StyledTableCell align="">Type</StyledTableCell>
                                    <StyledTableCell align="">Value</StyledTableCell>
                                    <StyledTableCell align="">Amount</StyledTableCell>
                                    <StyledTableCell align="">Status</StyledTableCell>
                                    <StyledTableCell align="">Winning Amount</StyledTableCell>
                                    <StyledTableCell align="">Date</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    bets && bets.map((row) => (
                                        <StyledTableRow>
                                            <StyledTableCell align="">
                                                {row.betDetails?.hash}
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                {row.username}
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                {row.type == "color" ? "Color" : "Number"}
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                {row.type == "color" ? <Chip size="small" sx={{
                                                    backgroundColor: row.value
                                                }} label={row.value}></Chip> : row.value}
                                            </StyledTableCell>
                                            
                                            <StyledTableCell align="">
                                                {`₹ ${Number(row.amount).toFixed(2)}`}
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                {row.status == 'placed' ? <Chip size="small" color="success" label="Placed" /> : ''}
                                                {row.status == 'succedded' ? <Chip size="small" color="success" label="Succuss" /> : ''}
                                                {row.status == 'failed' ? <Chip size="small" color="error" label="Lost" /> : ''}
                                                {row.status == 'in-review' ? <Chip size="small" color="warning" label="In Review" /> : ''}
                                                {row.status == 'credited' ? <Chip size="small" color="success" label="Credited to wallet" /> : ''}
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                {`₹ ${Number(row.winningAmount).toFixed(2)}`}
                                            </StyledTableCell>

                                            <StyledTableCell align="">
                                                {moment(row.placedOn).format('DD-MM-YYYY HH:mm:ss a')}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[1,10, 25, 50]}
                                        colSpan={8}
                                        count={betsCount}
                                        rowsPerPage={betrowsPerPage}
                                        page={betPage}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                            native: true,
                                        }}
                                        onPageChange={bethandleChangePage}
                                        onRowsPerPageChange={bethandleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="primary" onClick={() => { setopenBetsModal(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default FastParityGamesList;
