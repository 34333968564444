import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/horizon-full.svg";
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import dayjs from 'dayjs';
import { API_STATUS } from "../../utils/constants";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL, DateFormat } from "../../services/config";
// import { sportsSelector, clearTransactionLoadingDatas, clearSportsLoadingDatas, clearErrormsg, clearSportsUpdateLoading, clearSportsDeleteLoading, SportsDataFunc } from "../../store/reducer/sports";
import { Button, Modal } from "react-bootstrap";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { TransactionSelector, clearTransactionLoadingDatas, clearTransactionUpdateLoading, clearErrormsg, TransactionDataFunc, updateTranscationFunc } from "../../store/reducer/transactions";


function Transactionlist() {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    // const { bannerDatas, bannerCount, loading, addloding, deleteloading, updateloading, errorMessage } = useSelector(bannerSelector);
    // const { sportsDatas, sportsCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(sportsSelector)
    const { loading, updateloading, transactionDatas, transactionCount, errorMessage } = useSelector(TransactionSelector)
    // const resetForm = bannerFields;
    const [formFields, setFormFields] = useState([]);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    let [sortBy, setSortBy] = useState("createdOn");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const [trasncationModal, setTrasncationModal] = useState(false)
    const [transaction_ID, setTransaction_ID] = useState()
    const [transactionAmount, setTransactionAmount] = useState(0)
    const [paymentApprovalStatus, setPaymentApprovalStatus] = useState(0)
    const [transaction_Img, settransaction_Img] = useState("")
    const [paymentScreenshot, setPaymentScreenshot] = useState("")
    //const formDatas = _.map()

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        TransactionListDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        TransactionListDispatch();
    };

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value)
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
            console.log(file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setfileUpload(binaryStr);
                setfilePath(file.path);
                setfileerror(false);
                //console.log(binaryStr)
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const thumbs = files.map(file => (
        <div className="dropzone_thum_img" key={file.name}>
            <div className="dropzone_thumb">
                <img
                    src={file.preview}
                    className="dropzone_img"
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }
    })


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{e.message}</span>
            ))}
        </div>
    ));

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        TransactionListDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        TransactionListDispatch();
    };

    const TransactionListDispatch = () => {
        let payloadOrder = order == 'asc' ? 1 : -1;
        dispatch(
            TransactionDataFunc({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery
                }
            })
        )
    }

    useEffect(() => {
        let payloadOrder = order == 'asc' ? 1 : -1;
        dispatch(
            TransactionDataFunc({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery
                }
            })
        )
    }, [])

    const resetFormFields = () => {
        let data = formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
            return data;
        })
        setFormFields(data)
    };

    useEffect(() => {
        console.log(formFields, "formFields")
    }, [formFields])

    useEffect(() => {
        console.log(loading, "loading")
        if (updateloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearTransactionUpdateLoading())
            resetFormFields();
            TransactionListDispatch()
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            setshowAdd(true);
            dispatch(clearErrormsg())

        }
    }, [updateloading, errorMessage]);


    const paymentAction = (type, id, isModal) => {
        console.log(type, id, 'typeIds');
        let title = ''
        let status = 0;
        if (isModal) {
            setTrasncationModal(false)
        }
        if (type === 'accept') {
            title = 'Are You Sure to Accept Payment?'
            status = 1
        }
        if (type === 'deny') {
            title = 'Are You Sure to Deny Payment?'
            status = 2
        }
        if (type === 'paid') {
            toast.dismiss()
            toast.info("Payment Already Approved!");
            return false
        }
        MySwal.fire({
            title: title,
            // html: 'Users Wallet will be updated shortly',
            icon: 'info',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            console.log(result, 'result--swal');
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                // dispatch(UpdatePayment&Wallet({ id }));
                dispatch(updateTranscationFunc({ payload: { transaction_ids: id, status } }))
                MySwal.fire({
                    title: type === 'accept' ? "Payment Accepted Successfully" : "Payment Denied Successfully",
                    icon: 'success'
                })
            }
            if (result.isDismissed) {
                // dispatch(UpdatePayment&Wallet({ id }));
                MySwal.fire({
                    title: "OOPS! Payment Cancelled",
                    icon: 'error'
                })
            }
        })
    }



    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Transactions"
                    Breadcrumb={[{ name: "Transactions" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Transaction List</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-8 offset-md-4">
                                            {(!showAdd) ?
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder="Search Transaction" />
                                                : ''}
                                        </div>
                                        {/* <div className="col-md-4"> */}
                                        {/* <Link to='/addsports' className="btn btn-primary mr-1  float-right" onClick={resetFormFields}> Add Sports</Link> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "TransactionId" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("TransactionId");
                                                    }}
                                                >
                                                    Transaction Id
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("name");
                                                    }}
                                                >
                                                    Customer Name
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {/* <TableSortLabel
                                                    // active={sortBy == "amount" ? true : false}
                                                    // direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("amount");
                                                    }}
                                                > */}
                                                Amount
                                                {/* </TableSortLabel> */}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {/* <TableSortLabel
                                                    // active={sortBy == "amount" ? true : false}
                                                    // direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("amount");
                                                    }}
                                                > */}
                                                UTR Number
                                                {/* </TableSortLabel> */}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {/* <TableSortLabel
                                                    active={sortBy == "status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("status");
                                                    }}
                                                > */}
                                                Status
                                                {/* </TableSortLabel> */}
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                                  Sorting No.
                                              </StyledTableCell> */}
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            transactionDatas && transactionDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.transactionID}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        ₹ {row.amount.toFixed(2)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {"UTR Number"}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {row.status === 0 ?
                                                            <>
                                                                <button className="btn btn-success mr-1" onClick={(e) => { paymentAction('accept', row._id, false) }}>Accept</button>
                                                                <button className="btn btn-danger mr-1" onClick={(e) => { paymentAction('deny', row._id, false) }}>Deny</button>
                                                            </> :
                                                            row.status === 1 ? <button className="btn btn-primary mr-1" onClick={(e) => { paymentAction('paid', row._id, false) }}>Paid</button> :
                                                                "Denied"
                                                        }
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="center">
  
                                                      </StyledTableCell> */}
                                                    <StyledTableCell align="">
                                                        {/* <Link to={'/edit_sports/' + row._id} title="Edit"> */}
                                                        <i className="icon-eye text-info p-2" onClick={(e) => {
                                                            setTransaction_ID(row._id)
                                                            setPaymentApprovalStatus(row.status)
                                                            setTrasncationModal(true)
                                                            setPaymentScreenshot(row.transaction_image)
                                                        }}></i>
                                                        {/* </Link> */}
                                                        {/* <a href="javascript:void(0);" onClick={(e) => {
                                                              // deleteBanner(row)
                                                          }} title="Delete"><i className="icon-trash text-danger p-2" ></i></a> */}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={transactionCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={trasncationModal} backdrop="static" scrollable centered >
                <Modal.Header>
                    <Modal.Title>Transaction Screenshot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {/* <img className="qr_img_modal" src="https://basecamp.temenos.com/sfc/servlet.shepherd/version/renditionDownload?rendition=THUMB720BY480&versionId=0682K000008n5mL&operationContext=CHATTER&contentId=05T2K00000S1jGr&page=0" alt="payment_screenshot" /> */}
                        <img src={`${assestURL}static/${paymentScreenshot}`} alt={"Payment_Screenshot"} />
                    </>
                </Modal.Body>

                <Modal.Footer>
                    {paymentApprovalStatus == 0 ?
                        <>
                            <Button variant="success" onClick={() => paymentAction('accept', transaction_ID, true)}>
                                Approve
                            </Button>
                            <Button variant="danger" onClick={() => paymentAction('deny', transaction_ID, true)}>
                                Deny
                            </Button>
                        </>
                        : null
                    }
                    {/* <Button variant="success" onClick={() => paymentAction('accept', transaction_ID, true)}>
                        Approve
                    </Button>
                    <Button variant="danger" onClick={() => paymentAction('deny', transaction_ID, true)}>
                        Deny
                    </Button> */}
                    <Button variant="primary" onClick={() => { setTrasncationModal(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Transactionlist;




