import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import { DatePicker, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
// import {
//     bannerSelector,
//     clearBannAddLoading,
//     clearErrormsg
// } from "../../store/reducer/banner";
import { API_STATUS } from "../../utils/constants";
import Select from "react-dropdown-select";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { DateFormat, assestURL } from "../../services/config";
import { subAdminField } from "../fields/subadminform";
import { clearSubAdminAddLoading, subadminSelector, clearErrormsg, AddSubAdminfunc } from "../../store/reducer/subadmin";
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';


function AddSubAdmin() {
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    // const { bannerDatas, bannerCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(bannerSelector);
    const { subAdminDatas, subAdminCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(subadminSelector);
    const resetForm = subAdminField;
    const [formFields, setFormFields] = useState(subAdminField);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]
    const RegionDropDown = [{ name: 'Mumbai', id: 1 }, { name: 'Kolkata', id: 2 }, { name: 'Chennai', id: 3 }, { name: 'Hyderabad', id: 4 }]
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filesizeerror, setfilesizeerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [uploadfiles, setUploadFiles] = useState([]);
    const [fileName, setfileName] = useState("")

    let [thumbsimage, setThumbsImage] = useState([]);
    const maxProductWidth = 1200;
    const maxProductHeight = 600;

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([])
    const handleCancel = () => setPreviewOpen(false);

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        console.log(file.preview, 'file--preview');
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = async ({ fileList: newFileList }) => {
        setFileList(newFileList)
        setfileName(newFileList && newFileList.length && newFileList[0].name)
        console.log(newFileList, 'newFileList');
        if (newFileList && newFileList.length > 0) {
            let originalUrl = await getBase64(newFileList[0].originFileObj);
            setfileUpload(originalUrl)
        }
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value)
        formFields[index].value = value;
        setFormFields([...formFields]);
    };



    const onChangeTime = (value, time, index, name) => {
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    const submitAdmin = (e) => {
        console.log(formFields, 'submmit admin', simpleValidator.current.allValid());
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            // if (fileUpload == '' && updateID == "") {
            //     setfileerror(true);
            //     return false;
            // }
            if (filesizeerror == true) {
                return false
            }

            const datas = formFields.map((data) => {
                if (data?.name === "status") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val[0].id;
                } else {
                    return arr[data?.name] = data.value;
                }
            });
            console.log(arr);
            // if (arr.date != "") {
            //     arr.from_date = dayjs(new Date(arr.date[0])).format('YYYY-MM-DD')
            //     arr.to_date = dayjs(new Date(arr.date[1])).format('YYYY-MM-DD')
            // } else {
            //     arr.from_date = null
            //     arr.to_date = null
            // }
            const postDatas = arr;
            postDatas.region_qr = fileName;
            postDatas.region_qr_path = fileUpload;
            console.log(postDatas, "postDatas")
            dispatch(AddSubAdminfunc({ postDatas }))
        } else {
            // console.log(fileUpload, updateID)
            // if (fileUpload == '' && updateID == "") {
            //     setfileerror(true);
            // }
            setShowvalidator(true);
        }
    };

    const imageOnChange = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
            console.log(image, 'Select Image');
            toast.error('Select Valid Image - (jpg,jpeg,png)')
            return false;
        } else {
            const reader = new FileReader();
            const file = e.target.files[0];
            setfileName(file.name)

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onloadend = () => {
                const binaryStr = reader.result
                setfileUpload(binaryStr)
            }
            reader.readAsDataURL(file);
        }
    }

    useEffect(() => {
    }, [])

    useEffect(() => {
        simpleValidator.current.showMessages()
        if (addloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearSubAdminAddLoading())
            navigate('/adminlist');
        }
        if (errorMessage) {
            toast.dismiss();
            toast.error(errorMessage.error);
            // if (errorMessage.error)
            // else if (errorMessage.errors) {
            //     let data = "";
            //     errorMessage?.errors.map((err) => {
            //         data += err.message + " ";
            //     })
            //     toast.error(data);
            // }
            setshowAdd(true);
            dispatch(clearErrormsg())
        }
    }, [addloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Sub Admin"
                    Breadcrumb={[{ name: "Sub Admin" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Add Sub Admin</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-12">
                                            <Link to='/adminlist' className="btn btn-primary mr-1  float-right"> Cancel</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"body"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <div className={(fields?.input == "file") ? "col-md-12" : "col-md-6"}>
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.name}
                                                                    required=""
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={fields?.name == "status" ? statusDropdown : RegionDropDown}
                                                                            values={fields?.value}
                                                                            disabled={false}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        />
                                                                        :
                                                                        (fields?.input == "file") ?
                                                                            <>&nbsp;

                                                                                <Upload
                                                                                    // action={""}
                                                                                    // https://www.mocky.io/v2/5cc8019d300000980a055e76
                                                                                    listType="picture-card"
                                                                                    fileList={fileList}
                                                                                    onPreview={handlePreview}
                                                                                    onChange={handleChange}
                                                                                    beforeUpload={() => { return false }}
                                                                                >
                                                                                    {fileList.length >= 1 ? null : uploadButton}
                                                                                </Upload>
                                                                                {/* <Tooltip title={fields?.placeHolder}>
                                                                                    <span className="icon-question"></span>
                                                                                </Tooltip><br />
                                                                                <section className="row container">
                                                                                    <div className="col-md-6">
                                                                                        {/* <div
                                                                                {...getRootProps({
                                                                                  className: 'dropzone'
                                                                                })}> 
                                                                                        <input
                                                                                            name={fields?.name}
                                                                                            className="form-control"
                                                                                            accept={"image"}
                                                                                            multiple={"multiple"}
                                                                                            type={fields?.type}
                                                                                            // style={{ display: "none" }}
                                                                                            tabIndex={-1}
                                                                                            onChange={(e) => { imageOnChange(e) }}
                                                                                        />
                                                                                        {/* <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                    </div>
                                                                                    {/* </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className="thumbcontainer">
                                                                                            {/* {(thumbs && thumbs.length > 0 ? thumbs : '')}

                                                                                            {(fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                                <a href="javascript:void(0);" className="close_img" onClick={(e) => {
                                                                                                    // deleteImg(fields?.value, index)
                                                                                                }}>
                                                                                                    {/* <i className="error fa fa-times"></i> 
                                                                                                </a>
                                                                                                <div className="dropzone_thumb">
                                                                                                    <img
                                                                                                        src={`${assestURL}static/${fields?.value}`}
                                                                                                        className="dropzone_img"
                                                                                                    />
                                                                                                </div>
                                                                                            </div> : ''}</div>
                                                                                    </div>
                                                                                    {/* {fileRejectionItems}
                                                                                    <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                                </section> */}
                                                                            </>
                                                                            : (fields?.input == "rangepicker") ?
                                                                                <RangePicker onChange={(time, timeString) => {
                                                                                    console.log(timeString);
                                                                                    onChangeTime(time, timeString, index, fields?.name);
                                                                                }}
                                                                                    format={DateFormat}
                                                                                    className="form-control antformrange" />
                                                                                : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitAdmin(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>
    );
}

export default AddSubAdmin;
