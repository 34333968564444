import React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { stopGame} from "../../store/reducer/burstgames";
import { useDispatch } from 'react-redux';

const Timer = () => {
    const dispatch= useDispatch()
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const now = Date.now()

    const getTime = () => {
        const time = Date.now() - now;
        let seconds = Math.floor((time / 1000) % 60)
        if (seconds <= 30) {
            setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
            setMinutes(Math.floor((time / 1000 / 60) % 60));
            setSeconds(Math.floor((time / 1000) % 60));
        }else dispatch(stopGame())
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="timer" id="timer" role="timer">
            <div className="col-4">
                <div className="box">
                    <p id="hour">{hours < 10 ? "0" + hours : hours}</p>
                    <span className="text">Hours</span>
                </div>
            </div>
            <div className="col-4">
                <div className="box">
                    <p id="minute">{minutes < 10 ? "0" + minutes : minutes}</p>
                    <span className="text">Minutes</span>
                </div>
            </div>
            <div className="col-4">
                <div className="box">
                    <p id="second">{seconds < 10 ? "0" + seconds : seconds}</p>
                    <span className="text">Seconds</span>
                </div>
            </div>
        </div>
    );
};

export default Timer