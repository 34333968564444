import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
    categoryData,
    categorySelector,
} from "../store/reducer/category";
import {
    subcategoryFilterData,
    subCategorySelector,
    clearSubCatgDatas,
    subcategoryData
} from "../store/reducer/subcategory";
import {
    kitchensectionData,
    kitchensectionSelector
} from "../store/reducer/kitchensection";
import {
    kitchencodeData,
    AddKitchencode,
    UpdateKitchencode,
    DeleteKitchencode,
    kitchencodeSelector,
    clearKCLoadingDatas,
    clearKCAddLoading,
    clearKCUpdateLoading,
    clearKCDeleteLoading,
    clearData,
    clearErrormsg
} from "../store/reducer/kitchencode";
import {
    custommenuitemData,
    custommenuitemSelector
} from "../store/reducer/custommenuitem";
import { API_STATUS } from "../utils/constants";
import {
    kitchencodeFields
} from "./fields/kitchencodeform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL } from "../services/config";

function Kitchencode() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { kitchencodeDatas, kitchencodeCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(kitchencodeSelector);
    const { custommenuitemDatas, custommenuitemCount } = useSelector(custommenuitemSelector);
    const { kitchensectionDatas, kitchensectionCount } = useSelector(kitchensectionSelector);
    const { categoryDatas, categoryCount } = useSelector(categorySelector);
    const { subcategoryDatas, allsubcategoryDatas, subcategoryCount } = useSelector(subCategorySelector);
    const resetForm = kitchencodeFields;
    const [formFields, setFormFields] = useState(kitchencodeFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const [editData, setEditData] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [custommenuitemOptions, setCustommenuitemOptions] = useState([]);
    let [sortBy, setSortBy] = useState("updated_at");
    let [activesortBy, setActiveSortBy] = useState("updated_at");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [allsubcategory, setallsubcategory] = useState([]);
    const [kitchenOptions, setKitchenOptions] = useState([]);
    let [chosenSection, setChosenSection] = useState([]);
    let codefrmt = [{ name: "Default", id: "." }, { name: "( UPPER CASE )", id: "(A)" }, { name: "( lower case )", id: "(a)" }, { name: "{ UPPER CASE }", id: "{A}" }];
    //const formDatas = _.map()

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        kitchencodeDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        kitchencodeDispatch();
    };

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index, type) => {
        console.log(value, "value", type)
        if (value == "")
            value = [];

        if (type === "category") {
            setSubcategoryOptions([]);
            let from_index = _.findIndex(formFields, { name: "subcategory_id" })
            formFields[from_index].value = [];
            if (value.length > 0) {
                let postData = { category_id: value[0].id };
                console.log(postData, postData)
                dispatch(subcategoryFilterData({ postData }))
            }
        }
        if (type === "kitchensection") {
            let data = chosenSection;
            let new_id = "";
            let split_code = [];
            let split_kitchen = [];
            if (editData) {
                split_code = _.split(editData.codeformat, ",", editData.codeformat.length);
                split_kitchen = _.split(editData.kitchensection_id, ",", editData.kitchensection_id.length);
            }
            console.log(split_kitchen, split_code)
            let codeval = [{ name: "Default", id: "." }]
            value.map((items, index) => {
                let index_chosen = _.findIndex(chosenSection, { name: items.name });
                if (index_chosen < 0) {
                    codeval = [{ name: "Default", id: "." }]
                    if (split_kitchen.length > 0) {
                        let findkitchen = _.indexOf(split_kitchen, items.id.toString());
                        if (findkitchen >= 0) {
                            codeval = _.filter(codefrmt, { id: split_code[findkitchen] })
                        }
                    }
                    console.log(codeval)
                    data.push({ name: items.name, kitchensection_id: items.id, codeformat: codefrmt, codefrmtvalue: codeval, codefrmtid: codeval[0].id });
                    new_id = items.id;
                }
                let diff = _.differenceBy(data, value, 'name');
                console.log(diff, 'diff');
                let delete_index = -1;
                if (diff.length > 0)
                    delete_index = _.findIndex(data, { name: diff[0]?.name });
                if (delete_index >= 0) {
                    let default_index = _.findIndex(formFields, { name: "kitchensection_id" });
                    if (default_index >= 0) {
                        let dval = formFields[default_index].value;
                        if (dval.length > 0 && diff[0]?.name == dval[0]?.name) {
                            formFields[default_index].value = [];
                        }
                    }
                    _.unset(data, delete_index)
                }
                data = data.filter(function (element) {
                    return element !== undefined;
                });
                setChosenSection([...data]);
            })
            if (value.length == 0) {
                setChosenSection([]);
            }
        }
        if (type == "codeformat") {
            chosenSection[index].codefrmtvalue = value;
            chosenSection[index].codefrmtid = value[0].id;
            setChosenSection(chosenSection);
        } else {
            formFields[index].value = value;
            setFormFields([...formFields]);
        }
    };

    const getCategoryName = (row, type) => {
        if (type == 1) {
            let filterdata = _.filter(categoryDatas, { id: row.category_id })
            if (filterdata.length > 0) {
                return filterdata[0].name;
            }
        }
        if (type == 2) {
            let splitids = row.subcategory_id.split(",");
            console.log(splitids, "filterdata");
            let data = "";
            for (let i = 0; i < splitids.length; i++) {
                let filterdata = _.filter(allsubcategory, { id: parseInt(splitids[i]) })
                console.log(filterdata, "filterdata");
                if (filterdata.length > 0) {
                    if (i != splitids.length - 1)
                        data += filterdata[0].name + ", ";
                    else
                        data += filterdata[0].name;
                }
            }
            return data;
        }
        if (type == 3) {
            let splitids = row.kitchensection_id.split(",");
            console.log(splitids, "filterdata");
            let data = "";
            for (let i = 0; i < splitids.length; i++) {
                let filterdata = _.filter(kitchensectionDatas, { id: parseInt(splitids[i]) })
                console.log(filterdata, "filterdata");
                if (filterdata.length > 0) {
                    if (i != splitids.length - 1)
                        data += filterdata[0].name + ", ";
                    else
                        data += filterdata[0].name;
                }
            }
            return data;
        }
        if (type == 4) {
            let splitids = row.codeformat.split(",");
            console.log(splitids, "filterdata");
            let data = "";
            for (let i = 0; i < splitids.length; i++) {
                let filterdata = _.filter(codefrmt, { id: splitids[i] })
                console.log(filterdata, "filterdata");
                if (filterdata.length > 0) {
                    if (i != splitids.length - 1)
                        data += filterdata[0].name + ", ";
                    else
                        data += filterdata[0].name;
                }
            }
            return data;
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
            console.log(file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setfileUpload(binaryStr);
                setfilePath(file.path);
                setfileerror(false);
                //console.log(binaryStr)
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const thumbs = files.map(file => (
        <div className="dropzone_thum_img" key={file.name}>
            <div className="dropzone_thumb">
                <img
                    src={file.preview}
                    className="dropzone_img"
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: false
    })


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{e.message}</span>
            ))}
        </div>
    ));
    const submitKitchencode = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            //const postData = new FormData();
            const datas = formFields.map((data) => {
                return arr[data?.name] = data.value;
            });
            arr.subcategory_id = _.map(arr.subcategory_id, 'id');
            arr.category_id = _.map(arr.category_id, 'id')[0];
            arr.kitchensection_id = _.map(arr.kitchensection_id, 'id');
            arr.codeformat = _.map(chosenSection, 'codefrmtid');
            const postData = arr;
            console.log(postData, "postData");
            //return false;
            //const postData = arr;
            if (updateID == "")
                dispatch(AddKitchencode({ postData }));
            else {
                postData.id = updateID;
                dispatch(UpdateKitchencode({ postData, updateID }));
            }
            setshowAdd(false);
        } else {
            setShowvalidator(true);
            console.log("test");
        }
    };

    const editKitchencode = (row) => {
        resetFormFields()
        window.scrollTo(0, 0)
        setshowAdd(true);
        setUpdateID(row.id);
        setEditData(row);
        let data = formFields.map((data, index) => {
            //console.log(_.filter(statusDropdown, { id: row.status }));
            if (data.name === "category_id") {
                //setSubcategoryOptions([])
                let postData = { category_id: row.category_id };
                //dispatch(subcategoryFilterData({ postData }))
                let set_status = _.filter(categoryOptions, { id: row.category_id });
                data.value = set_status;
            }
            if (data.name === "description")
                data.value = row.description;
            if (data.name === "subcategory_id") {
                let s_ids = _.split(row.subcategory_id, ',', row.subcategory_id.length);
                let chosen = [];
                s_ids.map((item) => {
                    let set_status = _.filter(subcategoryOptions, { id: parseInt(item) });
                    //data.value = row.code;
                    console.log(set_status, "chosen")
                    if (set_status.length > 0) {
                        chosen.push(set_status[0]);
                    }
                })
                console.log(chosen, "chosen")
                data.value = chosen;
                console.log(data)
            }
            if (data.name === "kitchensection_id") {
                let s_ids = _.split(row.kitchensection_id, ',', row.kitchensection_id.length);
                let chosen = [];
                let split_code = _.split(row.codeformat, ",", row.codeformat.length);
                let split_kitchen = _.split(row.kitchensection_id, ",", row.kitchensection_id.length);
                let codeval = [{ name: "Default", id: "." }]
                let datacode =[]
                let chosenSection = [];
                s_ids.map((item) => {
                    let set_status = _.filter(kitchenOptions, { id: parseInt(item) });
                    //data.value = row.code;
                    console.log(chosenSection, "chosenSection")
                    if (set_status.length > 0) {
                        chosen.push(set_status[0]);
                        let index_chosen = _.findIndex(chosenSection, { name: set_status[0].name });
                        if (index_chosen < 0) {
                            codeval = [{ name: "Default", id: "." }]
                            if (split_kitchen.length > 0) {
                                let findkitchen = _.indexOf(split_kitchen, item.toString());
                                if (findkitchen >= 0) {
                                    codeval = _.filter(codefrmt, { id: split_code[findkitchen] })
                                }
                            }
                            console.log(codeval)
                            datacode.push({ name: set_status[0].name, kitchensection_id: set_status[0].id, codeformat: codefrmt, codefrmtvalue: codeval, codefrmtid: codeval[0].id });
                        }
                    }
                })
                console.log(datacode,"datacode")
                setChosenSection(datacode);
                console.log(chosen, "chosen")
                data.value = chosen;
                console.log(data)

            }
            // console.log(data)
            return data;
        });
        console.log(data, "formfields data");
        setFormFields(data)
        setfileerror(false);
    };


    const resetFormFields = () => {
        //dispatch(clearSubCatgDatas());
        setSubcategoryOptions([])
        setChosenSection([]);
        console.log("sub resetFormFields")
        let datas = formFields.map((data, index) => {
            console.log(data, "data");
            if (data.name === "category_id")
                data.value = [];
            else if (data.name === "subcategory_id")
                data.value = [];
            else if (data.name === "kitchensection_id")
                data.value = [];
            else
                data.value = "";
            return data
            //return datas.push(data);
        })
        //console.log(datas, "datas")
        setFormFields(datas)
    };


    const deleteKitchencode = (row) => {
        setshowAdd(false);
        //setUpdateID(row.id);
        const deleteID = row.id;
        MySwal.fire({
            title: 'Are You Sure to Delete Kitchencode?',
            html: 'Deleting Kitchencode will delete its code',
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            cancelButtonText:
                'Cancel',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(DeleteKitchencode({ deleteID }));
            }
        })

    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        kitchencodeDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        kitchencodeDispatch();
    };

    const kitchencodeDispatch = () => {
        dispatch(
            kitchencodeData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };

    useEffect(() => {
        dispatch(clearData())
        //dispatch(custommenuitemData({}))
        dispatch(categoryData({}));
        dispatch(subcategoryData({}));
        dispatch(kitchensectionData({}));
        kitchencodeDispatch()
        simpleValidator.current.showMessages()
    }, [])


    useEffect(() => {
        if (categoryOptions && categoryOptions.length == 0) {
            if (categoryDatas && categoryDatas.length > 0) {
                let catdata = [];
                categoryDatas.map((data) => {
                    return catdata.push({ id: data.id, name: data.name })
                });
                setCategoryOptions(catdata)
            }
        }
        if (kitchenOptions && kitchenOptions.length == 0) {
            if (kitchensectionDatas && kitchensectionDatas.length > 0) {
                let catdata = [];
                kitchensectionDatas.map((data) => {
                    return catdata.push({ id: data.id, name: data.name })
                });
                setKitchenOptions(catdata)
            }
        }
        console.log(allsubcategoryDatas, "allsubcategoryDatas")
        if (allsubcategoryDatas) {
            setallsubcategory(allsubcategoryDatas);
        }
        if (subcategoryOptions && subcategoryOptions.length == 0) {
            if (subcategoryDatas && subcategoryDatas.length > 0) {
                let subcategoryOptions = [];
                subcategoryDatas.map((data) => {
                    return subcategoryOptions.push({ id: data.id, name: data.name })
                });
                setSubcategoryOptions(subcategoryOptions)
                if (editData && typeof (editData.subcategory_id) !== "undefined") {
                    let from_index = _.findIndex(formFields, { name: "subcategory_id" });
                    let s_ids = _.split(editData.subcategory_id, ',', editData.subcategory_id.length);
                    let chosen = [];
                    s_ids.map((item) => {
                        let set_status = _.filter(subcategoryOptions, { id: parseInt(item) });
                        if (set_status.length > 0) {
                            chosen.push(set_status[0]);
                        }
                    })
                    formFields[from_index].value = chosen;
                }
            }
        }
    }, [categoryDatas, subcategoryDatas, kitchensectionDatas, allsubcategoryDatas])

    useEffect(() => {
        console.log(subcategoryOptions, "subcategoryOptions")
        console.log(categoryOptions, "categoryOptions")
        console.log(kitchenOptions, "kitchenOptions")
        console.log(chosenSection, "chosenSection")
    }, [subcategoryOptions, formFields, chosenSection])

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearKCDeleteLoading())
            kitchencodeDispatch()
        }
        if (addloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearKCAddLoading())
            kitchencodeDispatch()
        }
        if (updateloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearKCUpdateLoading())
            resetFormFields();
            kitchencodeDispatch()
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            dispatch(clearErrormsg())

        }
    }, [deleteloading, addloading, updateloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Kitchen Code Format"
                    Breadcrumb={[{ name: "Kitchen Code Format" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Kitchen Code Format</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-6">
                                            {(!showAdd) ?
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder="Search by Category" />
                                                : ''}
                                        </div>
                                        <div className="col-md-6"><button type="button" className="btn btn-primary mr-1  float-right" onClick={(e) => {
                                            setshowAdd(!showAdd);
                                            console.log(resetForm, 'kitchencodeFields')
                                            resetFormFields()
                                        }}>
                                            {(showAdd) ? "Cancel" : "Add Kitchen Code Format"}
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={(showAdd) ? "body" : "body hide"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                                {(fields?.input == "text") ?
                                                                    <input
                                                                        className={`form-control ` + fields?.class}
                                                                        value={fields?.value}
                                                                        name={fields?.type}
                                                                        required=""
                                                                        onChange={(e) => { FieldChange(e, index) }}
                                                                    />
                                                                    : (fields?.input == "textarea") ?
                                                                        <textarea
                                                                            className="form-control"
                                                                            value={fields?.value}
                                                                            cols="30"
                                                                            name="textarea"
                                                                            required=""
                                                                            rows="2"
                                                                            onChange={(e) => {
                                                                                FieldChange(e, index)
                                                                            }}
                                                                        ></textarea>
                                                                        : (fields?.input == "dropdown") ?
                                                                            <Select
                                                                                className="js-states"
                                                                                placeholder=""
                                                                                options={(fields?.options == "subcategory" && subcategoryOptions.length > 0) ? subcategoryOptions : (fields?.options == "category" && categoryOptions.length > 0) ? categoryOptions : (fields?.options == "kitchensection" && kitchenOptions.length > 0) ? kitchenOptions : []}
                                                                                values={fields.value}
                                                                                disabled={false}
                                                                                multi={fields?.multiple}
                                                                                dropdownHandle={true}
                                                                                searchable={false}
                                                                                labelField={"name"}
                                                                                valueField={"id"}
                                                                                onChange={(value) => { DropdownChange(value, index, fields?.options); }}
                                                                            />
                                                                            : (fields?.input == "file") ?
                                                                                <section className="row container">
                                                                                    <div className="col-md-8">
                                                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                                                            <input {...getInputProps()}
                                                                                                name={fields?.name} />
                                                                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                        </div></div>
                                                                                    <div className="col-md-4">


                                                                                        <div className="thumbcontainer">{(thumbs && thumbs.length > 0 ? thumbs : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                            <div className="dropzone_thumb">
                                                                                                <img
                                                                                                    src={fields?.value}
                                                                                                    className="dropzone_img"
                                                                                                />
                                                                                            </div>
                                                                                        </div> : '')}</div>
                                                                                    </div>
                                                                                    {fileRejectionItems}
                                                                                    <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                                </section>
                                                                                : ''

                                                                }
                                                                {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                        }
                                        <div className="col-md-12">
                                            <TableContainer sx={{ minHeight: 250 }} component={Paper}>
                                                <Table sx={{ minWidth: 400 }} stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell className="table-header-column-name">
                                                                Kitchen Code
                                                            </StyledTableCell>
                                                            <StyledTableCell className={"table-header-column-name"}>
                                                                Code Format
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {chosenSection && chosenSection.map((items, index) => {
                                                            return (items && items.name) ?
                                                                <StyledTableRow>
                                                                    <StyledTableCell align="center">
                                                                        {items?.name}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="center">
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={items.codeformat}
                                                                            values={items.codefrmtvalue}
                                                                            disabled={false}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index, 'codeformat'); }}
                                                                        />

                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                                : ''
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>

                                        <button id="submit_btn" class="offset-md-5 mt-2 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitKitchencode(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("category_id");
                                                    }}
                                                >
                                                    Category
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("subcategory_id");
                                                    }}
                                                >
                                                    Sub Category
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("subcategory_id");
                                                    }}
                                                >
                                                    Kitchen Section
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("subcategory_id");
                                                    }}
                                                >
                                                    Code Format
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                                Sorting No.
                                            </StyledTableCell> */}
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            kitchencodeDatas && kitchencodeDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {getCategoryName(row, 1)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {getCategoryName(row, 2)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {getCategoryName(row, 3)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {getCategoryName(row, 4)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <a href="javascript:void(0);" onClick={(e) => { editKitchencode(row) }} title="Edit"><i className="icon-pencil text-info p-2"></i></a>
                                                        <a href="javascript:void(0);" onClick={(e) => { deleteKitchencode(row) }} title="Delete"><i className="icon-trash text-danger p-2" ></i></a>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={kitchencodeCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kitchencode;
