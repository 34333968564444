import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fastParityGamesList, fastParitybetList} from "../../services/api";
import { toast } from "react-toastify";
const namespace = "fastparitygames";

const initialState = {
    bets:[],
    games:[],
    betsCount:0,
    gamesCount:0
};

export const getFastParityGames = createAsyncThunk(
    `${namespace}/list`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await fastParityGamesList({ payload });
            return data;
        } catch (error) {
            toast.error('Something went wrong.. Try again..')
            return rejectWithValue(error.response);
        }
    }
);

export const getFastParityGameBets = createAsyncThunk(
    `${namespace}/bets`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await fastParitybetList({ payload });
            return data;
        } catch (error) {
            toast.error('Something went wrong.. Try again..')
            return rejectWithValue(error.response);
        }
    }
);

const fastParityGamesSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
       
        [getFastParityGameBets.fulfilled](state, { payload }) {
            state.betsCount = payload?.data?.data?.count;
            state.bets = payload?.data?.data?.result;
        },
        [getFastParityGames.fulfilled](state, { payload }) {
            state.gamesCount = payload?.data?.data?.count;
            state.games = payload?.data?.data?.result;
        },
       
    },
});

export const { clearData} = fastParityGamesSlice.actions;

export const fastParityGamesSelector = (state) => state.fastparity;

export default fastParityGamesSlice.reducer;
