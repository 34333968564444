import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import dayjs from 'dayjs';
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { EventList, BetSelector } from "../../store/reducer/bet";
import { Chip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";



function Events() {
    const dispatch = useDispatch();
    const { events, eventCount } = useSelector(BetSelector);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [sortBy, setSortBy] = useState("createdOn");
    let [order, setOrder] = useState("desc");
    let [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate()
    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        EventsListDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        EventsListDispatch();
    };



    const EventsListDispatch = () => {
        let payloadOrder = order == 'asc' ? 1 : -1;

        dispatch(
            EventList({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery
                }
            })
        )
    }

    useEffect(() => {
        let payloadOrder = order == 'asc' ? 1 : -1;
        dispatch(
            EventList({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    // filter: { 'userType': 0 },
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery,
                }
            })
        )
    }, [])

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Events"
                    Breadcrumb={[{ name: "Events" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Events</h2>
                                    </div>
                                    <div className="col-md-6">
                                        <Button className="btn btn-primary mr-1  float-right" onClick={()=>{
navigate('/betlist')
                                        }}>Bets</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                Date
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Event Id
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Event
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                League
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Active
                                            </StyledTableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            events && events.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.createdOn ?
                                                            dayjs(new Date(row.createdOn)).format('YYYY-MM-DD') :
                                                            null

                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <Link to={`/event_odds/${row.eventId}`}>{row?.eventId}</Link>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.event}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.league}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.active ? <Chip size="small" color="success" label="Yes" /> : <Chip size="small" color="default" label="No" />}
                                                    </StyledTableCell>


                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={eventCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Events