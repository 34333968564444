import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import PageHeader from "../view/pageheader";
import TableCell from '@mui/material/TableCell';
import {
    getDisplayData,
    kitchenDisplaySelector,
    getDisplayReport
} from "../store/reducer/kitchendisplay";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import dayjs from 'dayjs';
import { DateFormat } from "../services/config";
import { Divider, Space, Tag } from 'antd';
import {
    generalData,
    generalSelector,
} from "../store/reducer/general";
// import moment from 'moment'

function KitchenDisplay() {

    const dispatch = useDispatch();
    const [currentTime, setCurrentTime] = useState('')
    const [driveDatas, setdriveDatas] = useState([])
    const [scheduleDatas, setscheduleDatas] = useState([])
    const [displayReports, setdisplayReports] = useState('')
    const { orderDisplayDatas, driveThruList, pickupList, scheduleList, orderDisplayReport, loading, errorMessage } = useSelector(kitchenDisplaySelector);
    const { generalDatas } = useSelector(generalSelector);

    console.log(displayReports, 'orderDisplayReport');
    useEffect(() => {
        dispatch(generalData({}))
    }, [])

    useEffect(() => {
        if (driveThruList && driveThruList.length) {

            let vechicleDetails = driveThruList && driveThruList.map((item) => {
                let driveDetails = JSON.parse(item.orderMode_details)
                let vechicleData = `${driveDetails.vehicleModel}, ${driveDetails.vehicleColor}`
                return Object.assign({}, item, { orderMode_details: vechicleData });
            })
            setdriveDatas([...vechicleDetails])
        }

    }, [driveThruList])

    useEffect(() => {
        if (scheduleList && scheduleList.length) {
            let vechicleData;
            let scheduleDetails = scheduleList && scheduleList.map((item) => {
                if (item.order_mode == 1) {
                    let driveDetails = JSON.parse(item.orderMode_details)
                    vechicleData = `${driveDetails.vehicleModel}, ${driveDetails.vehicleColor}`

                } else {
                    vechicleData = item.orderMode_details
                }
                return Object.assign({}, item, { orderMode_details: vechicleData });
            })
            setscheduleDatas([...scheduleDetails])
        }

    }, [scheduleList])


    useEffect(() => {
        if (orderDisplayReport) {
            setdisplayReports(orderDisplayReport)
        }
    }, [orderDisplayReport])

    // useEffect(() => {
    //     if (generalDatas) {

    //         console.log(generalDatas, 'currentCSTtime--generalDatas');
    //         const myInterval = setInterval(() => {
    //             // var dateTime = dayjs(new Date()).format('YYYY-MM-DD h:mm:ss A');

    //             let currentCSTtime = moment().tz(generalDatas[0].timezone).format('LT')
    //             console.log('currentCSTtime--');
    //             console.log(currentCSTtime, 'currentCSTtime');
    //             let dateTime = moment(currentCSTtime, 'YYYY-MM-DD h:mm:ss A')

    //             console.log(dateTime, 'dateTime');
    //             // let dateTime = dayjs
    //             setCurrentTime(dateTime)
    //         }, 1000)
    //         return () => clearInterval(myInterval);
    //     }
    // }, [generalDatas])

    // useEffect(() => {
    //     const myInterval = setInterval(() => {
    //         var dateTime = dayjs(new Date()).format('YYYY-MM-DD h:mm:ss A');
    //         setCurrentTime(dateTime)
    //     }, 1000)
    //     return () => clearInterval(myInterval);
    // }, [])


    // useEffect(() => {
    //     dispatch(
    //         generalData({})
    //     );
    // }, [])

    useEffect(() => {

        // create a interval and get the data
        const myInterval = setInterval(() => {
            dispatch(getDisplayData({}))
            dispatch(getDisplayReport({}))
        }, 5000);
        // clear out the interval when unmounting the component
        return () => clearInterval(myInterval);

    }, []);


    useEffect(() => {
        if (generalDatas) {
            let options = {
                timeZone: generalDatas[0].timezone,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }

            // console.log(dayjs(new Date().toLocaleString("en-US")).format("MM/DD/YYYY hh:mm A"),'timenow');
            //  console.log(options,new Date().toLocaleString([], options),'timenow')
            //  console.log(dayjs(new Date().toLocaleString([])).tz(generalDatas[0].timezone),'timenow')
            setCurrentTime(dayjs(new Date().toLocaleString([], options)).format("MM-DD-YYYY h:mm:ss A"))

            const interval = setInterval(() => {

                let options = {
                    timeZone: generalDatas[0].timezone,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                }
                setCurrentTime(dayjs(new Date().toLocaleString([], options)).format("MM-DD-YYYY h:mm:ss A"))
            }, 1000);
            return () => clearInterval(interval);
            //const timezone = 
        }
    }, [generalDatas])

    return (
        <div>
            <div className="container-fluid">
                <div className="row mt-3">
                    <h5>Kitchen Display</h5>
                </div>
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="row" style={{ marginBottom: '35px', marginTop: '5px' }}>
                            <div className="col-md-6">
                                <button type="button" class="btn btn-danger " style={{ textAlign: 'left', float: 'left' }}><b>TOTAL ORDERS TODAY&nbsp;:&nbsp;{displayReports && displayReports.dailyReport[0].count ? displayReports.dailyReport[0].count : 0}</b></button>
                            </div>
                            <div className="col-md-6">
                                <button type="button" class="btn btn-light btn-lg" style={{ textAlign: 'right', float: 'right', fontSize: '16px' }}><b>{currentTime}</b></button>
                            </div>
                        </div>
                        {/* <div style={{ marginBottom: '30px', color: 'black' }}> <h6 style={{ textAlign: 'center', float: 'left' }}><b>TOTAL ORDERS</b>&nbsp;:&nbsp;{displayReports && displayReports.dailyReport[0].count}</h6> <h6 style={{ textAlign: 'right' }}>{currentTime}</h6></div> */}
                        <div className="row">
                            <div className="col-md-5">
                                <div className="card p-2">
                                    <div className="col-md-8">
                                        <h6>ORDERS - <button type="button" class="btn btn-success">Total Active Orders : <b>{orderDisplayDatas && orderDisplayDatas.length ? orderDisplayDatas.length : 0}</b></button></h6>
                                    </div>
                                    <TableContainer
                                        sx={{
                                            height: 960
                                        }}
                                    >
                                        <Table
                                            // sx={{
                                            //     height: "max-content"
                                            // }}
                                            stickyHeader
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order ID
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Customer Name
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order Status
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order Total ($)
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    orderDisplayDatas && orderDisplayDatas.map((row) => (
                                                        <StyledTableRow>
                                                            <StyledTableCell align="">
                                                                <b>{row.id}</b>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row.order_status == 0 ? "Pending" : "Confirmed"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row.order_total}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="row mb-3">
                                    <div className="card p-2">
                                        <div className="col-md-8">
                                            <h6>Drive-Thru Orders - <button type="button" class="btn btn-success">Active Orders : <b>{driveDatas && driveDatas.length ? driveDatas.length : 0}</b></button></h6>
                                        </div>
                                        <div className="col-md-12">
                                            <TableContainer
                                                sx={{
                                                    height: 250
                                                }}
                                            >
                                                <Table
                                                    sx={{
                                                        height: "max-content"
                                                    }}
                                                    stickyHeader
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order ID
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Customer Name
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Status
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Vehicle Details
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Total ($)
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            driveDatas && driveDatas.map((row) => (
                                                                < StyledTableRow >
                                                                    <StyledTableCell align="">
                                                                        <b>{row.id}</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_status == 0 ? "Pending" : "Confirmed"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.orderMode_details}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_total}
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            )
                                                            )
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="card p-2">
                                        <div className="col-md-8">
                                            <h6>Pickup Orders - <button type="button" class="btn btn-success">Active Orders : <b>{pickupList && pickupList.length ? pickupList.length : 0}</b></button></h6>
                                        </div>
                                        <div className="col-md-12">
                                            <TableContainer
                                                sx={{
                                                    height: 250
                                                }}
                                            >
                                                <Table
                                                    sx={{
                                                        height: "max-content"
                                                    }}
                                                    stickyHeader
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order ID
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Customer Name
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Status
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Total ($)
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            pickupList && pickupList.map((row) => (
                                                                <StyledTableRow>
                                                                    <StyledTableCell align="">
                                                                        <b>{row.id}</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_status == 0 ? "Pending" : "Confirmed"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_total}
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="card p-2">
                                        <div className="col-md-12">
                                            <h6>Scheduled Pickup Orders - <button type="button" class="btn btn-success">Active Orders : <b>{scheduleList && scheduleList.length ? scheduleList.length : 0}</b></button></h6>
                                            <TableContainer
                                                sx={{
                                                    height: 250
                                                }}
                                            >
                                                <Table
                                                    sx={{
                                                        height: "max-content"
                                                    }}
                                                    stickyHeader
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order ID
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Customer Name
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Status
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Schedule Time
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Mode
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Total ($)
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            scheduleDatas && scheduleDatas.map((row) => (
                                                                <StyledTableRow>
                                                                    <StyledTableCell align="">
                                                                        <b>{row.id}</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_status == 0 ? "Pending" : "Confirmed"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {(row.schedule_pickup == '1') ? <p><i class="fa fa-clock-o"></i>&nbsp;<b>{row.schedulepickup_time}</b></p> : '-'}                                                                </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_mode == 2 ? 'Pickup Order' : row.order_mode == 1 ? <>Drive-Thru<br />({row.orderMode_details})</> : ''}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_total}
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                                <div className="col-md-12">
                                    <h6>TOTAL</h6>
                                    <table>
                                        <tr>
                                            <td><b>Daily -</b> {displayReports && displayReports.dailyReport[0].count}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Weekly -</b>{displayReports && displayReports.weeklyReport[0].count}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Monthly -</b>{displayReports && displayReports.monthlyReport[0].count}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Quaterly -</b>{displayReports && displayReports.quaterlyReport[0].count}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Yearly -</b>{displayReports && displayReports.yearlyReport[0].count}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default KitchenDisplay