import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/horizon-full.svg";
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import dayjs from 'dayjs';
import { API_STATUS } from "../../utils/constants";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL, DateFormat } from "../../services/config";
import { clearSubAdminAddLoading, subadminSelector, clearErrormsg, AddSubAdminfunc, subAdminData, clearSubAdminUpdateLoading, clearSubAdminDeleteLoading } from "../../store/reducer/subadmin";
import { subAdminField } from "../fields/subadminform";
import { DeleteBanner, bannerData, bannerSelector } from "../../store/reducer/banner";



function Subbannerlist() {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [formFields, setFormFields] = useState(subAdminField);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]
    const { loading, addloading, updateloading, deleteloading, errorMessage, bannerDatas, bannerCount, bannerDataById } = useSelector(bannerSelector);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [sortBy, setSortBy] = useState("createdOn");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const [showAdd, setshowAdd] = useState(false);
    let [files, setFiles] = useState([]);

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        BannerListDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        BannerListDispatch();
    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        BannerListDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        BannerListDispatch();
    };

    const resetFormFields = () => {
        let data = formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
            return data;
        })
        setFormFields(data)
    };

    const BannerListDispatch = () => {
        let payloadOrder = order == 'asc' ? 1 : -1;

        dispatch(
            bannerData({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery,
                    additionalPayload: {
                        isSub: true
                    }
                }
            })
        )
    }

    useEffect(() => {
        let payloadOrder = order == 'asc' ? 1 : -1;
        dispatch(
            bannerData({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    // filter: { 'userType': 0 },
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery,
                    additionalPayload: {
                        isSub: true
                    }
                }
            })
        )
    }, [])

    useEffect(() => {
        console.log(formFields, "formFields")
    }, [formFields])

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearSubAdminDeleteLoading())
            BannerListDispatch()
        }
        if (addloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearSubAdminAddLoading())
            BannerListDispatch()
        }
        if (updateloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearSubAdminUpdateLoading())
            resetFormFields();
            BannerListDispatch()
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            setshowAdd(true);
            dispatch(clearErrormsg())

        }
    }, [addloading, updateloading, errorMessage]);

    const deleteBanner = (id) => {
        MySwal.fire({
            title: "Are You Sure to Delte This Banner?",
            icon: 'info',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            console.log(result, 'result--swal');
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                // dispatch(UpdatePayment&Wallet({ id }));
                dispatch(DeleteBanner(id))
                MySwal.fire({
                    title: "Banner Deleted Successfully",
                    icon: 'success'
                })
                BannerListDispatch();
            }
        })
    }


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Sub Banner"
                    Breadcrumb={[{ name: "Sub Banner" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Sub Banner</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-8">
                                            {(!showAdd) ?
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder="Search Banner" />
                                                : ''}
                                        </div>
                                        <div className="col-md-4">
                                            <Link to='/addsubbanner' className="btn btn-primary mr-1  float-right" onClick={resetFormFields}> Add Sub Banner</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("name");
                                                    }}
                                                >
                                                    Banner Name
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "priority" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("priority");
                                                    }}
                                                >
                                                    Priority
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("status");
                                                    }}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            bannerDatas && bannerDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.promoName}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.priority}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status === 1
                                                            ? "Active"
                                                            : "In-active"}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <Link to={'/editsubbanner/' + row._id} title="Edit"> <i className="icon-pencil text-info p-2"></i></Link>
                                                        <a href="javascript:void(0);" onClick={(e) => {
                                                            deleteBanner(row._id)
                                                        }} title="Delete"><i className="icon-trash text-danger p-2" ></i></a>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={bannerCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subbannerlist