import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TransactionList, updateTranscation, withDrawList, withDrawUpdate } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import alertmessage from '../../utils/alert';
import { toast } from "react-toastify";
const namespace = "transaction";

const initialState = {
    loading: "initial",
    updateloading: "initial",
    errorMessage: null,
    transactionDatas: null,
    transactionCount: 0,
    withdrawDatas: null,
    withdrawCount: 0
};

export const TransactionDataFunc = createAsyncThunk(
    `${namespace}/TransactionDataFunc`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await TransactionList({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const updateTranscationFunc = createAsyncThunk(
    `${namespace}/updateTranscationFunc`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            console.log(payload, "interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await updateTranscation({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const WithdrawDataFunc = createAsyncThunk(
    `${namespace}/WithdrawDataFunc`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await withDrawList({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const updateWithdrawFunc = createAsyncThunk(
    `${namespace}/updateWithdrawFunc`,
    async ({ payload, id }, { rejectWithValue, dispatch }) => {
        try {
            console.log(payload,id, "interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await withDrawUpdate({ payload }, id);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const TransactionSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearTransactionLoadingDatas: (state) => {
            state.loading = "initial";
            state.updateloading = "initial";
        },
        clearTransactionUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [TransactionDataFunc.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [TransactionDataFunc.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = payload
            console.log(payloaddatas.data.data, 'payloaddatas--load');
            state.transactionCount = payloaddatas?.data?.data?.count;
            state.transactionDatas = payloaddatas?.data?.data?.result;
        },
        [TransactionDataFunc.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [updateTranscationFunc.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [updateTranscationFunc.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
            let payloaddatas = payload
            console.log(payloaddatas.data.data, 'payloaddatas--load');
            state.transactionCount = payloaddatas?.data?.data?.count;
            state.transactionDatas = payloaddatas?.data?.data?.result;
        },
        [updateTranscationFunc.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [WithdrawDataFunc.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [WithdrawDataFunc.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = payload
            console.log(payloaddatas.data.data, 'payloaddatas--load');
            state.withdrawCount = payloaddatas?.data?.data?.count;
            state.withdrawDatas = payloaddatas?.data?.data?.result;
        },
        [WithdrawDataFunc.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [updateWithdrawFunc.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [updateWithdrawFunc.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
            let payloaddatas = payload
            console.log(payloaddatas.data.data, 'payloaddatas--load');
            state.withdrawCount = payloaddatas?.data?.data?.count;
            state.withdrawDatas = payloaddatas?.data?.data?.result;
        },
        [updateWithdrawFunc.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
    }
});

export const { clearData, clearTransactionLoadingDatas, clearTransactionUpdateLoading, clearErrormsg } = TransactionSlice.actions;

export const TransactionSelector = (state) => state.transactions;

export default TransactionSlice.reducer;