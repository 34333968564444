import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { API_STATUS } from "../../utils/constants";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import _ from 'lodash';
import moment from 'moment'
import { useDropzone } from 'react-dropzone';
import { clearAddLoading, burstgamesSelector, clearMessages, toggleGameLoading, getBurstGames, getSettings, updateSettings, resetGameState, startGame, endGame, clearUpdateLoading, clearDeleteLoading } from "../../store/reducer/burstgames";
import { Modal, Button, Form, Card, Spinner } from "react-bootstrap";
import Timer from "./Timer";
let interval = null
function BurstGamesList() {
    const dispatch = useDispatch();
    const {
        // loading,
        addloading,
        updateloading,
        deleteloading,
        errorMessage,
        burstGamesData,
        burstGamesCount,
        successMessage,
        burstGameDetails,
        gameLoading,
        gameStatus,
        settings
    } = useSelector(burstgamesSelector);
    const [startGameModalOpen, setStartGameModalOpen] = useState(false)
    const [openSettigsModal, setOpenSettigsModal] = useState(false)
    const [showOnlyLiveGames, setShowOnlyLiveGames] = useState(false)
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [sortBy, setSortBy] = useState("startedOn");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("desc");
    const availableTypes = [
        'ball',
        'flight'
    ]
    let [gameStartPayload, setGameStartPayload] = useState({
        type: 'ball'
    });
    let [gameSettings, setGameSettings] = useState({
        isAutomatic: true,
        maxOdds: [],
        _id: false
    });
    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        getBurstGamesList();
    };
    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getBurstGamesList();
    };
    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        getBurstGamesList();
    };

    const getBurstGamesList = () => {
        let payloadOrder = order == 'asc' ? 1 : -1;
        dispatch(toggleGameLoading())
        dispatch(
            getBurstGames({
                payload: {
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder }
                }
            })
        )
    }

    const triggerStartGameFunc = () => {
        if (availableTypes.indexOf(gameStartPayload.type) > -1) {
            dispatch(toggleGameLoading())
            dispatch(
                startGame({
                    payload: gameStartPayload
                })
            )
        } else {
            toast.error("Please select the type of burst game..");
        }
    }

    const triggerEndGameFunc = (id) => {
        dispatch(toggleGameLoading())
        dispatch(
            endGame({
                id
            })
        )
    }

    const saveSettings = () => {
        dispatch(toggleGameLoading())
        dispatch(
            updateSettings({
                payload: gameSettings
            })
        )
        setOpenSettigsModal(false)
    }




    useEffect(() => {
        let payloadOrder = order == 'asc' ? 1 : -1;
        dispatch(
            getBurstGames({
                payload: {
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder },
                }
            })
        )
        dispatch(getSettings())
    }, [])


    useEffect(() => {
        if (errorMessage) {
            toast.dismiss();
            toast.error(errorMessage);
        }
        if (successMessage) {
            toast.dismiss();
            toast.success(successMessage);
        }

        if (gameStatus == 'end') {
            toast.success('Game stopped successfully..');
            dispatch(resetGameState())
            if (!interval)
                getBurstGamesList()
            setStartGameModalOpen(false)
        }
        dispatch(clearMessages())
    }, [successMessage, errorMessage, gameStatus]);

    useEffect(() => {
        if (settings && settings._id) {
            setGameSettings({
                ...gameSettings,
                ...settings
            })
        }
    }, [settings])

    useEffect(() => {
        if (showOnlyLiveGames) {

            interval = setInterval(() => {
                dispatch(
                    getBurstGames({
                        payload: {
                            page: page + 1,
                            limit: rowsPerPage,
                            additionalPayload: {
                                live: true
                            }
                        }
                    })
                )
            }, 750)

        } else if (interval) {
            let payloadOrder = order == 'asc' ? 1 : -1;
            interval = clearInterval(interval)
            dispatch(
                getBurstGames({
                    payload: {
                        page: page + 1,
                        limit: rowsPerPage,
                        sort: { [sortBy]: payloadOrder },
                    }
                })
            )
        }
    }, [showOnlyLiveGames])

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Burst Games"
                    Breadcrumb={[{ name: "Burst Games" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Burst Games</h2>

                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-12">
                                            <Form.Check
                                                className="mr-1 mt-2 ml-2 float-right"
                                                type="switch"
                                                value={showOnlyLiveGames}
                                                id="custom-switch"
                                                onChange={(e) => {
                                                    setShowOnlyLiveGames(e.target.checked)
                                                }}
                                                label="Go Live"
                                            />
                                            {settings && settings.isAutomatic === false ? <button type="button" className="btn btn-primary mr-1  float-right" onClick={() => {
                                                setStartGameModalOpen(true)
                                            }}>Start Game</button> : <></>}

                                            <button type="button" className="btn btn-primary mr-1  float-right" onClick={() => {
                                                setOpenSettigsModal(true)
                                            }}><i className="fa fa-gear"></i></button>
                                            {/* <button type="button" className="btn btn-primary mr-1  float-right" onClick={() => {
                                                getBurstGamesList()
                                            }}><i className="fa fa-refresh"></i></button> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            {settings && settings.isAutomatic ? <Chip label="Mode : Auto" color="success" /> : <Chip label="Mode : Manual" color="primary" />}
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "hash" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("hash");
                                                    }}
                                                >
                                                    #
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">

                                                <TableSortLabel
                                                    active={sortBy == "type" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("type");
                                                    }}
                                                >
                                                    Type
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "startedOn" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("startedOn");
                                                    }}
                                                >
                                                    Started On
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "endedOn" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("endedOn");
                                                    }}
                                                >
                                                    Ended On
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "odds" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("odds");
                                                    }}
                                                >
                                                    Odds
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                                Sorting No.
                                            </StyledTableCell> */}
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            burstGamesData && burstGamesData.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row.hash}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.type}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {moment(row.startedOn).format('DD-MM-YYYY HH:mm:ss a')}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.endedOn ? moment(row.endedOn).format('DD-MM-YYYY HH:mm:ss a') : '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.odds}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {!row.endedOn ? <Button variant="danger" onClick={() => { triggerEndGameFunc(row._id) }}>
                                                            Stop Game
                                                        </Button> : ''}

                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={burstGamesCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={openSettigsModal} backdrop="static" scrollable centered >
                <Modal.Header>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {gameLoading ? <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box> : <></>}
                        <Form.Label>Is Automatic?</Form.Label>
                        <Form.Select size="lg" value={gameSettings.isAutomatic ? 'yes' : 'no'} onChange={(e) => {
                            setGameSettings({
                                ...gameSettings,
                                isAutomatic: e.target.value == 'yes'
                            })
                        }}>
                            <option value={'yes'}>{'Yes'}</option>
                            <option value={'no'}>{'No'}</option>
                        </Form.Select>
                        <br></br>
                        <h6>Max Odds : {gameSettings.maxOdds.join(' ,')}</h6>
                    </>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={() => { saveSettings() }}>
                        Update
                    </Button>
                    <Button variant="primary" onClick={() => { setOpenSettigsModal(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={startGameModalOpen} scrollable centered >
                <Modal.Header>
                    <Modal.Title>Start Game</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {gameLoading ? <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box> : <></>}
                        {burstGameDetails && burstGameDetails._id && gameStatus == 'start' ? <>
                            <Card className="text-center">
                                <Card.Header>Game Started</Card.Header>
                                <Card.Body>
                                    <Card.Title><Timer /></Card.Title>
                                    <Button variant="danger" onClick={() => { triggerEndGameFunc(burstGameDetails._id); }}>
                                        Stop
                                    </Button>
                                    <Button variant="primary" onClick={() => { setStartGameModalOpen(false); getBurstGamesList(); }}>
                                        Close
                                    </Button>
                                </Card.Body>
                            </Card>
                        </> :
                            // <Form.Select size="lg" value={gameStartPayload.type} onChange={(e) => {
                            //     setGameStartPayload({
                            //         ...gameStartPayload,
                            //         type: e.target.value
                            //     })
                            // }}>
                            //     <option>Choose</option>
                            //     {availableTypes && availableTypes.map((item) => {
                            //         return <option value={item} style={{ textTransform: 'capitalize' }}>{item}</option>
                            //     })}
                            // </Form.Select>
                            <></>
                        }
                        <br></br>
                        <p>Note: Game will stop automatically after 23 seconds..</p>
                        <p>User can only place their bet within first 8 seconds..</p>
                        <p>Once 8 seconds completed, odds will be start from 0.1..</p>

                        {burstGameDetails && burstGameDetails._id ? <></> : <   center>
                            <Button variant="success" className="mr-2" onClick={() => { triggerStartGameFunc() }}>
                                Start
                            </Button>
                            <Button variant="primary" onClick={() => { setStartGameModalOpen(false) }}>
                                Cancel
                            </Button>
                        </center>
                        }
                    </>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default BurstGamesList;
