import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bannerList, bannerAdd, bannerUpdate, bannerDelete, bannerListById } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "banner";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    bannerDatas: null,
    bannerCount: 0,
    bannerDataById: null
};

export const bannerData = createAsyncThunk(
    `${namespace}/bannerData`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            console.log(payload, "postDatas--interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await bannerList({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddBanner = createAsyncThunk(
    `${namespace}/AddBanner`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            console.log(payload, "interdata");
            // let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            // // const postData = new FormData();
            // uploadfiles.forEach((file) => {
            //     console.log(file);
            //     postData.append("images", file);
            // })
            // postData.append("payload", payload);
            const data = await bannerAdd({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateBanner = createAsyncThunk(
    `${namespace}/UpdateBanner`,
    async ({ payload, id }, { rejectWithValue, dispatch }) => {
        try {
            console.log(id, "updateID__interdata");
            console.log(payload, "interdata");
            // let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            // const postData = new FormData();
            // if (uploadfiles) {
            //     uploadfiles.forEach((file) => {
            //         console.log(file);
            //         postData.append("images", file);
            //     })
            // } else {
            //     postData.append("images", []);
            // }
            // postData.append("payload", payload);
            const data = await bannerUpdate(payload, id);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteBanner = createAsyncThunk(
    `${namespace}/DeleteBanner`,
    async (id, { rejectWithValue, dispatch }) => {
        try {
            console.log(id, "interdata");
            const data = await bannerDelete(id);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const bannerById = createAsyncThunk(
    `${namespace}/bannerById`,
    async ({ editID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(editID, "interdata__bannerbyid");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await bannerListById(editID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const bannerSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearBannLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearBannAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearBannUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearBannDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [bannerData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [bannerData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.bannerCount = payload?.data?.count;
            // state.bannerDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payload?.data?.data, "payload_result")
            state.bannerCount = payload?.data?.data.count;
            state.bannerDatas = payload?.data?.data.result;
        },
        [bannerData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddBanner.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddBanner.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddBanner.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateBanner.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateBanner.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateBanner.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteBanner.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteBanner.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteBanner.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [bannerById.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [bannerById.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payload, "payload__bannerid")
            state.bannerDataById = payload?.data?.data.result;
        },
        [bannerById.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        }
    },
});

export const { clearData, clearLoadingDatas, clearBannAddLoading, clearBannUpdateLoading, clearBannDeleteLoading, clearErrormsg } = bannerSlice.actions;

export const bannerSelector = (state) => state.banner;

export default bannerSlice.reducer;
