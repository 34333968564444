import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addSports, editSports, SportsList, SportsById } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import alertmessage from '../../utils/alert';
import { toast } from "react-toastify";
const namespace = "sports";



const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    sportsDatas: null,
    sportsCount: 0,
    sportsById: null,
    editSportsDatas: null
};

export const SportsDataFunc = createAsyncThunk(
    `${namespace}/SportsDataFunc`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await SportsList({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddSportsfunc = createAsyncThunk(
    `${namespace}/AddSportsfunc`,
    async ({ postDatas }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postDatas, "interdata");
            // let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            // console.log(payload, '--payload');
            const data = await addSports({ payload: postDatas });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const EditSportsFunc = createAsyncThunk(
    `${namespace}/EditSportsFunc`,
    async ({ postDatas, editID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postDatas, "interdata");
            // let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const data = await editSports({ payload: postDatas }, editID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
)
export const SportsByIdFunc = createAsyncThunk(
    `${namespace}/SportsByIdFunc`,
    async ({ editID }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await SportsById(editID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const SportsSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearSportsLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearSportsAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearSportsUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearSportsDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [SportsDataFunc.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [SportsDataFunc.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.subAdminCount = payload?.data?.count;
            // state.subAdminDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            // console.log(payloaddatas?.data?.count, "payload")
            let payloaddatas = payload
            console.log(payloaddatas.data.data, 'payloaddatas--load');
            state.sportsCount = payloaddatas?.data?.data?.count;
            state.sportsDatas = payloaddatas?.data?.data?.result;
        },
        [SportsDataFunc.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddSportsfunc.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddSportsfunc.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddSportsfunc.rejected](state, action) {
            console.log('rejected--admin', state, action);
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
            // alertmessage.sweetalert(action?.payload?.data?.message);
        },
        [EditSportsFunc.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [EditSportsFunc.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
            let payloaddatas = payload?.data?.data
            console.log(payloaddatas, "subAdminById--payload")
            state.editSubAdminDatas = payloaddatas?.result;
        },
        [EditSportsFunc.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        // [DeleteSubAdmin.pending](state) {
        //     state.deleteloading = API_STATUS.PENDING;
        // },
        // [DeleteSubAdmin.fulfilled](state, { payload }) {
        //     state.deleteloading = API_STATUS.FULFILLED;
        // },
        // [DeleteSubAdmin.rejected](state, action) {
        //     state.deleteloading = API_STATUS.REJECTED;
        //     state.errorMessage = action?.payload?.data;
        // },
        [SportsByIdFunc.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [SportsByIdFunc.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = payload?.data?.data
            console.log(payloaddatas, "subAdminById--payload")
            state.sportsById = payloaddatas?.result;
        },
        [SportsByIdFunc.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        }
    },
});

export const { clearData, clearSportsLoadingDatas, clearSportsAddLoading, clearSportsUpdateLoading, clearSportsDeleteLoading, clearErrormsg } = SportsSlice.actions;

export const sportsSelector = (state) => state.sports;

export default SportsSlice.reducer;