import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { customersList, customerUpdate, customerDelete } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "customers";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    customerDatas: null,
    customerDataById: null,
    customerCount: 0,
};

export const customerData = createAsyncThunk(
    `${namespace}/customerData`,
    async ({query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata123");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await customersList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateCustomer = createAsyncThunk(
    `${namespace}/UpdateCustomer`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await customerUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteCustomer = createAsyncThunk(
    `${namespace}/DeleteCustomer`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await customerDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const customersSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearCustLoadingDatas: (state) => {
            state.loading = "initial";
            state.updateloading ="initial";
            state.deleteloading ="initial";
        },
        clearCustUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearCustDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [customerData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [customerData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count,"payload")
            state.customerCount = payloaddatas?.count;
            state.customerDatas = payloaddatas?.data;
        },
        [customerData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [UpdateCustomer.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateCustomer.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateCustomer.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteCustomer.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteCustomer.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteCustomer.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearCustUpdateLoading, clearCustLoadingDatas, clearCustDeleteLoading, clearErrormsg } = customersSlice.actions;

export const customersSelector = (state) => state.customers;

export default customersSlice.reducer;
