import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import dayjs from 'dayjs';
import { API_STATUS } from "../../utils/constants";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { TransactionSelector, clearTransactionUpdateLoading, clearErrormsg, updateWithdrawFunc, WithdrawDataFunc } from "../../store/reducer/transactions";
import { Button, Modal } from "react-bootstrap";


function WithdrawList() {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal)
    const { loading, updateloading, withdrawDatas, withdrawCount, errorMessage } = useSelector(TransactionSelector)
    const [formFields, setFormFields] = useState([]);
    const [showAdd, setshowAdd] = useState(false);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [sortBy, setSortBy] = useState("createdOn");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("desc");
    let [searchQuery, setSearchQuery] = useState("");
    const [editId, setEditId] = useState("")
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [denialForm, setDenialForm] = useState({
        comments: "",
        amount: 0
    })
    const [viewComments, setViewComments] = useState("")
    // const [trasncationModal, setTrasncationModal] = useState(false)
    // const [transaction_ID, setTransaction_ID] = useState()
    // const [paymentApprovalStatus, setPaymentApprovalStatus] = useState(0)
    // const [paymentScreenshot, setPaymentScreenshot] = useState("")
    //const formDatas = _.map()

    console.log(withdrawDatas, 'withdrawDatas');

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        WithDrawListDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        WithDrawListDispatch();
    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        WithDrawListDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy === key) {
            if (order === "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        WithDrawListDispatch();
    };

    const WithDrawListDispatch = () => {
        let payloadOrder = order === 'asc' ? 1 : -1;
        dispatch(
            WithdrawDataFunc({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery
                }
            })
        )
    }

    useEffect(() => {
        let payloadOrder = order === 'asc' ? 1 : -1;
        dispatch(
            WithdrawDataFunc({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery
                }
            })
        )
    }, [])

    const resetFormFields = () => {
        let data = formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
            return data;
        })
        setFormFields(data)
    };

    useEffect(() => {
        console.log(formFields, "formFields")
    }, [formFields])

    useEffect(() => {
        console.log(loading, "loading")
        if (updateloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearTransactionUpdateLoading())
            resetFormFields();
            WithDrawListDispatch()
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            setshowAdd(true);
            dispatch(clearErrormsg())

        }
    }, [updateloading, errorMessage]);


    const paymentAction = (status, id, amount) => {
        setEditId(id)
        if (status === 1) {
            MySwal.fire({
                title: "Are You Sure to Accept Withdraw Request?",
                icon: 'info',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    dispatch(updateWithdrawFunc({
                        payload: {
                            status: status,
                            amount: amount,
                        },
                        id: id
                    }))
                    MySwal.fire({
                        title: "Withdraw Request Accepted Successfully",
                        icon: 'success'
                    })
                }
                if (result.isDismissed) {
                    MySwal.fire({
                        title: "OOPS! Withdraw Request Cancelled",
                        icon: 'error'
                    })
                }
            })
        }
        if (status === 2) {
            console.log(status, amount, 'status_modal');
            setIsModalVisible(true)
            setDenialForm({
                ...denialForm,
                amount: amount
            })
        }
        if (status === 0) {
            toast.dismiss()
            toast.info("Already Approved")
        }
    }

    const denialOnChange = (e) => {
        e.preventDefault()
        console.log(e.target.value, 'comments');
        setDenialForm({
            ...denialForm,
            comments: e.target.value
        })
    }

    const paymentDenyFunc = (e) => {
        e.preventDefault()
        setIsModalVisible(false)
        dispatch(updateWithdrawFunc({
            payload: {
                status: 2,
                amount: denialForm.amount,
                comments: denialForm.comments
            },
            id: editId
        }))

    }

    const denyCommentsFunc = (comments) => {
        console.log(comments, 'comments_comments');
        setIsModalVisible(true);
        setViewComments(comments)
    }


    return (
        <Fragment>
            <div>
                <div className="container-fluid">
                    <PageHeader
                        HeaderText="Withdraw List"
                        Breadcrumb={[{ name: "Withdraw List" }]}
                    />
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2>Withdraw List</h2>
                                        </div>
                                        <div className="col-md-6 row">
                                            <div className="col-md-8 offset-md-4">
                                                {(!showAdd) ?
                                                    <input type="text" name="search" className="form-control" value={searchQuery}
                                                        onChange={(e) => { searchData(e) }} placeholder="Search Request" />
                                                    : ''}
                                            </div>
                                            {/* <div className="col-md-4"> */}
                                            {/* <Link to='/addsports' className="btn btn-primary mr-1  float-right" onClick={resetFormFields}> Add Sports</Link> */}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-2">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy === "name" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("name");
                                                        }}
                                                    >
                                                        Customer Name
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy === "amount" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("amount");
                                                        }}
                                                    >
                                                        Amount
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    Account
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <TableSortLabel
                                                        active={sortBy === "status" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("status");
                                                        }}
                                                    >
                                                        Action
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <TableSortLabel
                                                        active={sortBy === "createdOn" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("createdOn");
                                                        }}
                                                    >
                                                        createdOn
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                withdrawDatas && withdrawDatas.map((row) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="">
                                                            {row.name}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            ₹ {row.amount.toFixed(2)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {"User's Account Numberr"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row.status === 0 ?
                                                                <>
                                                                    <button className="btn btn-success mr-1" onClick={(e) => { paymentAction(1, row._id, row.amount) }}>Accept</button>
                                                                    <button className="btn btn-danger mr-1" onClick={(e) => { paymentAction(2, row._id, row.amount) }}>Deny</button>
                                                                </> :
                                                                row.status === 1 ? <button className="btn btn-primary mr-1" onClick={(e) => { paymentAction(0, row._id, row.amount) }}>Paid</button> :
                                                                    <>Denied<i className="icon-note text-info p-2" onClick={() => denyCommentsFunc(row.comments)}></i></>
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row.createdOn ?
                                                                dayjs(new Date(row.createdOn)).format('YYYY-MM-DD hh:mm:ss A') :
                                                                null}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={8}
                                                    count={withdrawCount}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={isModalVisible} backdrop="static" scrollable centered >
                <Modal.Header>
                    <Modal.Title>Denial Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {viewComments ?
                            <textarea name="denyComments" value={viewComments} readOnly className="form-control" id="" cols="15" rows="3"></textarea>
                            :
                            <textarea name="denyComments" onChange={denialOnChange} className="form-control" id="" cols="15" rows="3"></textarea>
                        }
                    </>
                </Modal.Body>
                <Modal.Footer>
                    {
                        viewComments ? null :
                            <Button variant="danger" onClick={paymentDenyFunc}>
                                Deny Request
                            </Button>
                    }
                    < Button variant="primary" onClick={() => { setIsModalVisible(false); setViewComments("") }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment >
    );
}

export default WithdrawList;




