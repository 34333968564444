import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import React, { Fragment, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Login from "./components/login";
import PrivateRoute from './PrivateRoute';
import Full from './view/full'
import RoutesLink from './components/routes';
import 'react-toastify/dist/ReactToastify.css';

import Customers from "./components/customers/customers";
import Dashboard from "./components/dashboard";
import AdminList from "./components/UserManagement/adminlist";
import AddSubAdmin from "./components/UserManagement/add_subadmin";
import EditSubAdmin from "./components/UserManagement/edit_subadmin";
import ListSports from "./components/Sports/listSports";
import AddSports from "./components/Sports/addSports";
import EditSports from "./components/Sports/editSports";
import Transactionlist from "./components/Transactions/transactionlist";
import UserProfile from "./components/Profile";
import Mainbannerlist from "./components/Promotions/mainbannerlist";
import Addmainbanner from "./components/Promotions/addmainbanner";
import Editmainbanner from "./components/Promotions/editmainbanner";
import Subbannerlist from "./components/Promotions/subbannerlist";
import AddSubbanner from "./components/Promotions/addsubbanner";
import EditSubbanner from "./components/Promotions/editsubbanner";
import Betlist from "./components/Livesports/betlist";
import EventOdds from "./components/Livesports/eventOdds";
import Events from "./components/Livesports/events";
import WithdrawList from "./components/Transactions/withdrawlist";


import Category from "./components/category";
import SubCategory from "./components/subcategory";
import ItemSize from "./components/itemsize";
import Products from "./components/products/products";
import AddProducts from "./components/products/addproducts";
import EditProducts from "./components/products/editproducts";
import Sorting from "./components/sorting/sorting";
import ForgotPassword from "./components/forgotpassword";
import ResetPassword from "./components/resetpassword";
import Custommenu from "./components/custommenu";
import CustomMenuItem from "./components/custommenuitem";
import POS from "./components/settings/pos";
import Banner from "./components/settings/banner";
// import AddBanners from "./components/settings/addbanner";
// import EditBanners from "./components/settings/editbanner";
import Notification from "./components/settings/notification";
import Aboutus from "./components/settings/aboutus";
import AboutusAdd from "./components/settings/addaboutus";
import AboutusEdit from "./components/settings/editaboutus";
import Contactus from "./components/settings/contactus";
import ContactusEdit from "./components/settings/editcontactus";
import GiftCard from "./components/giftcard";
import Orders from "./components/order";
import OrderView from "./components/orderview";
import Kitchensection from "./components/kitchensection";
import Kitchencode from "./components/kitchencode";
import General from "./components/settings/general";
import Reports from "./components/Reports";
import KitchenDisplay from "./components/kitchenDisplay";
import Colorpallete from "./components/settings/colorpallete";
import Kitchendispatch from "./components/kitchendispatch";
import { useDispatch } from "react-redux";
import { Session } from "./store/reducer/login";


import BurstGamesList from "./components/burstgames/list";
import FastParityGamesList from "./components/fastparity/list";
import GamesList from "./components/Games/list";
import DiceGamesList from "./components/dice/list";
import RouletteGamesList from "./components/roulette/list";


function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    console.log("session");
    let isAuth = localStorage.getItem("isAuthenticated")
    if (isAuth) {
      dispatch(Session({}))
    }
  }, [])

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/resetpassword/:token" element={<ResetPassword />} />
          <Route path="/" element={<PrivateRoute />} >
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/customers" element={<Customers />} />
            <Route exact path="/adminlist" element={<AdminList />} />
            <Route exact path='/addsubadmin' element={<AddSubAdmin />} />
            <Route exact path='/editsubadmin/:id' element={<EditSubAdmin />} />
            <Route exact path="/sportlist" element={<ListSports />} />
            <Route exact path="/addsports" element={<AddSports />} />
            < Route exact path="/edit_sports/:id" element={<EditSports />} />
            < Route exact path="/transactions" element={< Transactionlist />} />
            < Route exact path="/profile" element={< UserProfile />} />
            <Route exact path="/bannerlist" element={< Mainbannerlist />} />
            <Route exact path="/addbanner" element={<Addmainbanner />} />
            <Route exact path="/editbanner/:id" element={<Editmainbanner />} />
            <Route exact path="/subbannerlist" element={<Subbannerlist />} />
            <Route exact path="/addsubbanner" element={<AddSubbanner />} />
            <Route exact path="/editsubbanner/:id" element={<EditSubbanner />} />
            <Route exact path="/betlist" element={<Betlist />} />
            <Route exact path="/event_odds/:eventId" element={<EventOdds />} />
            <Route exact path="/events" element={<Events />} />
            <Route exact path="/withdrawal" element={<WithdrawList />} />


            <Route exact path="/category" element={<Category />} />
            <Route exact path="/subcategory" element={<SubCategory />} />
            <Route exact path="/itemsize" element={<ItemSize />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/addproducts" element={<AddProducts />} />
            <Route exact path="/editproducts/:id" element={<EditProducts />} />
            <Route exact path="/custommenu" element={<Custommenu />} />
            <Route exact path="/sorting" element={<Sorting />} />
            <Route exact path="/custommenuitem" element={<CustomMenuItem />} />
            <Route exact path="/pos" element={<POS />} />
            <Route exact path="/banner" element={<Banner />} />
            {/* <Route exact path="/addbanner" element={<AddBanners />} /> */}
            {/* <Route exact path="/editbanner/:id" element={<EditBanners />} /> */}
            <Route exact path="/notification" element={<Notification />} />
            <Route exact path="/aboutus" element={<Aboutus />} />
            <Route exact path="/addaboutus" element={<AboutusAdd />} />
            <Route exact path="/editaboutus/:id" element={<AboutusEdit />} />
            <Route exact path="/contactus" element={<Contactus />} />
            <Route exact path="/editcontactus/:id" element={<ContactusEdit />} />
            <Route exact path="/giftcard" element={<GiftCard />} />
            <Route exact path="/orders" element={<Orders />} />
            <Route exact path="/orderdetails/:id" element={<OrderView />} />
            <Route exact path="/kitchensection" element={<Kitchensection />} />
            <Route exact path="/kitchencode" element={<Kitchencode />} />
            <Route exact path="/general" element={<General />} />
            <Route exact path="/reports" element={<Reports />} />
            <Route exact path="/kitchendisplay" element={<KitchenDisplay />} />
            <Route exact path="/kitchendispatch" element={<Kitchendispatch />} />
            <Route exact path="/colorpicker" element={<Colorpallete />} />
            <Route exact path="/addcolor" element={<Colorpallete />} />
            <Route exact path="/burstgames" element={<BurstGamesList />} />
            <Route exact path="/fastparity" element={<FastParityGamesList />} />
            <Route exact path="/games" element={<GamesList />} />
            <Route exact path="/dice" element={<DiceGamesList />} />
            <Route exact path="/roulette" element={<RouletteGamesList />} />
          </Route>
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;
