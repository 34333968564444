import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Dropdown, Modal, Nav, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/GamingLogo.png";
import LogoWhite from "../assets/images/GamingLogo.png";
import Avatar12 from "../assets/images/xs/avatar12.jpg";
// import { Leftmenu } from "../services/leftmenu";
import { logOut, loginSelector } from "../store/reducer/login"
import _ from 'lodash';
import Leftmenu from "../services/leftmenu";
import { getServerSettingsApi, updateServerSettingsApi } from "../services/api";
import { toast } from "react-toastify";


function NavBar() {
    const dispatch = useDispatch();
    const { sessionData } = useSelector(loginSelector)
    const [openSettigsModal, setOpenSettigsModal] = useState(false)
    const [serverSettings, setServerSettings] = useState({
        result: []
    })
    const roleID = localStorage.getItem('role_id')
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? res : "/";


    const [menuDetails, setMenuDetails] = useState(Leftmenu);
    const [toggleMenu, setToggleMenu] = useState(true);
    let [activeKey, setactiveKey] = useState(res);
    const activeMenutabContainer = (id) => {
        var parents = document.getElementById("main-menu");
        var activeMenu = document.getElementById(id);
        let check = 0;
        for (let index = 0; index < parents.children.length; index++) {
            if (parents.children[index].id !== id) {
                parents.children[index]?.classList.remove("active");
                parents.children[index].children[1]?.classList.remove("in");
            } else {
                check = 1;
                parents.children[index]?.classList.add("active");
            }
        }
        console.log(check, "check");
        setTimeout(() => {
            console.log("in");
            if (check == 1) {
                activeMenu?.classList.add("active");
                activeMenu.children[1]?.classList.add("in");
            }
            else {
                activeMenu?.classList.toggle("active");
                activeMenu.children[1]?.classList.toggle("in");
            }
        }, 100);

    };
    const user_name = localStorage.getItem("username")

    const onToggleMenu = () => {
        setToggleMenu(!toggleMenu);
        if (!toggleMenu) {
            document.body?.classList.remove("layout-fullwidth");
        } else {
            document.body?.classList.add("layout-fullwidth");
        }
    };

    const MarkActiveSub = (active, sub) => {
        activeKey = sub;
        setactiveKey(sub);
    }

    const logout = () => {
        dispatch(logOut());
    }

    useEffect(() => {
        if (activeKey) {
            let getcontainer = menuDetails.filter((menu) => {
                if (_.includes(menu.url, activeKey)) {
                    return menu.container;
                }
            })
            //let get_c = _.some(menuDetails, (el) => _.includes(el.url, activeKey))

            //console.log(menuDetails, getcontainer[0].container, "menuDetails")
            if (getcontainer.length > 0)
                activeMenutabContainer(getcontainer[0].container)

        }
    }, [activeKey])

    useEffect(() => {
        let userType = sessionData?.userType
        if (userType) {
            if (menuDetails && menuDetails.length) {
                let index = menuDetails.findIndex(item => !item.usertType.includes(userType))
                let result = menuDetails.filter(item => item.usertType.includes(userType))
                setMenuDetails(result)
            }

        }
    }, [sessionData])

    const openSettingsModalFunc = async () => {
        setOpenSettigsModal(true)
        const settingsResult = await getServerSettingsApi()
        setServerSettings({
            ...serverSettings,
            result: settingsResult.data?.data?.result || []
        })
    }

    const updateSettingsFunc = async () => {
        await updateServerSettingsApi({
            payload: serverSettings.result
        })
        toast.success("Server Settings updated successfully");
        setOpenSettigsModal(false)
    }

    const changeSettings = (key, value) => {
        let tempArray = serverSettings.result
        let findKey = _.find(serverSettings.result, { key });
        if (findKey) {
            for (let i in tempArray) {
                if (tempArray[i].key == key) {
                    tempArray[i].value = value && String(value).trim()
                }
            }
        } else {
            tempArray.push({
                key,
                value
            })
        }
        setServerSettings({
            ...serverSettings,
            result: tempArray
        })
    }




    return (

        <div>
            <nav className="navbar navbar-fixed-top">
                <div className="container-fluid">
                    <div className="navbar-btn">
                        <button
                            className="btn-toggle-offcanvas"
                            onClick={() => {
                                onToggleMenu()
                            }}
                        >
                            <i className="lnr lnr-menu fa fa-bars"></i>
                        </button>
                    </div>

                    <div className="navbar-brand">
                        <a href={(roleID == "4" || roleID == "5") ? "" : "/adminlist"}>
                            <img
                                src={
                                    document.body?.classList.contains("full-dark")
                                        ? LogoWhite
                                        : Logo
                                }
                                alt="Horizon Logo"
                                className="img-responsive logo"
                            />
                        </a>
                    </div>

                    <div className="navbar-right">
                        <div id="navbar-menu">
                            <ul className="nav navbar-nav">
                                <li>
                                    <a href="javascript:void(0)" onClick={logout} className="icon-menu" title="Logout">
                                        <i className="icon-login"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            {(roleID == "4" || roleID == "5") ? "" :
                <div id="left-sidebar" className="sidebar" style={{ zIndex: 9 }}>
                    <div className="sidebar-scroll">
                        <div className="user-account">
                            <img
                                src={Avatar12}
                                className="rounded-circle user-photo"
                                alt="User Profile Picture"
                            />
                            <Dropdown>
                                <span>Welcome,</span>
                                <Dropdown.Toggle
                                    variant="none"
                                    as="a"
                                    id="dropdown-basic"
                                    className="user-name"
                                >
                                    <strong>{user_name}</strong>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-right account profile-dropdown" >
                                    <Dropdown.Item href="/profile" onClick={() => { }}>
                                        {" "}
                                        <i className="icon-user"></i>Profile
                                    </Dropdown.Item>
                                    {localStorage.getItem("userType") == 0 ? <Dropdown.Item onClick={() => { openSettingsModalFunc() }}>
                                        {" "}
                                        <i className="icon-settings"></i>Server Settings
                                    </Dropdown.Item> : ''}

                                    <Dropdown.Item href="javascript:void(0)" onClick={logout}>
                                        {" "}
                                        <i className="icon-power"></i>Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <hr />
                        </div>
                        <div className="tab-content p-l-0 p-r-0">
                            <div className={"tab-pane active show"} id="menu">
                                <Nav id="left-sidebar-nav" className="sidebar-nav">
                                    <ul id="main-menu" className="metismenu">
                                        {menuDetails != null && menuDetails.map((menu) => {
                                            return (menu?.ischild) ? (
                                                <li className="" id={menu?.container} >
                                                    <a href="#!" className={(menu?.ischild) ? "has-arrow" : ""} onClick={(e) => { e.preventDefault(); activeMenutabContainer(menu?.container); }}>
                                                        <i className={menu?.icon}></i> <span>{menu?.menu}</span>
                                                    </a>
                                                    {(menu?.ischild) ? (
                                                        <ul className="collapse">
                                                            {menu?.child.map((submenu) => {
                                                                return (<li
                                                                    className={(activeKey === submenu.url) ? "active" : ""} onClick={(e) => { e.preventDefault(); MarkActiveSub(activeKey, submenu.url); }}
                                                                >
                                                                    <Link to={submenu?.url}>{submenu?.menu}</Link>
                                                                </li>)
                                                            })}
                                                        </ul>
                                                    ) : ''}
                                                </li>
                                            ) : (
                                                <li className="" id={menu?.container} onClick={(e) => { e.preventDefault(); activeMenutabContainer(menu?.container); MarkActiveSub(activeKey, menu.url); }}>
                                                    <Link to={menu?.url}><i className={menu?.icon}></i> <span>{menu?.menu}</span></Link>
                                                </li>
                                            )
                                        })
                                        }

                                    </ul>
                                </Nav>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal size="lg" show={openSettigsModal} backdrop="static" scrollable centered >
                <Modal.Header>
                    <Modal.Title>Server Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {serverSettings.result && serverSettings.result.map((item, index) => {
                        return <Row key={index}>
                            <Col>
                                <Form.Label>{item.label}</Form.Label>
                                <Form.Control size="sm" value={item.value} onChange={(e) => {
                                    changeSettings(item.key, e.target.value)
                                }} type="text" placeholder="Value" />
                            </Col>
                        </Row>
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => {
                        updateSettingsFunc()
                    }}>
                        Update
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setOpenSettigsModal(false)
                    }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )


}
export default NavBar;