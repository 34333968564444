

import React from "react";
import { Toast } from "react-bootstrap";


const errorToaster = message => {
    console.log(message,"mess")
    return (
        <Toast
        id="toast-container"
        className={`toast-container toast-bottom-right`}
        autohide
        delay={3000}
      >
        <Toast.Body className={`toast-danger mb-0 ml-0`}>
          <strong className="mr-auto"> {message} </strong>
          <button className="toast-close-button" >
            x
          </button>
        </Toast.Body>
      </Toast>
    )
}
const successToaster = message => {
    return (
        <Toast
        id="toast-container"
        className={`toast-container toast-bottom-right`}
        autohide
        delay={3000}
      >
        <Toast.Body className={`toast-success mb-0 ml-0`}>
          <strong className="mr-auto"> {message} </strong>
          <button className="toast-close-button" >
            x
          </button>
        </Toast.Body>
      </Toast>
    )
}
const warningToaster = message => {
    return (
        <Toast
        id="toast-container"
        className={`toast-container toast-bottom-right`}
        autohide
        delay={3000}
      >
        <Toast.Body className={`toast-warning mb-0 ml-0`}>
          <strong className="mr-auto"> {message} </strong>
          <button className="toast-close-button" >
            x
          </button>
        </Toast.Body>
      </Toast>
    )
}
const infoToaster = message => {
    return (
        <Toast
        id="toast-container"
        className={`toast-container toast-bottom-right`}
        autohide
        delay={3000}
      >
        <Toast.Body className={`toast-info mb-0 ml-0`}>
          <strong className="mr-auto"> {message} </strong>
          <button className="toast-close-button" >
            x
          </button>
        </Toast.Body>
      </Toast>
    )
}
 const Toastr = {
    error: errorToaster,
    warning: warningToaster,
    info: infoToaster,
    success: successToaster,
}
export default Toastr;