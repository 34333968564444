import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import dayjs from 'dayjs';
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { EventOdds, BetSelector } from "../../store/reducer/bet";
import { Button, Chip, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { EventOddsUpdateApi } from "../../services/api";
import { Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";


function EventOddsComp() {
    const dispatch = useDispatch();
    const { eventId } = useParams()
    const { eventOdds, eventOddsCount, eventDetails } = useSelector(BetSelector);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [sortBy, setSortBy] = useState("createdOn");
    let [order, setOrder] = useState("desc");
    let [searchQuery, setSearchQuery] = useState("");
    const [openModal, setOpenModal] = useState(false)
    const [oddsUpdateFields, setOddsUpdateFields] = useState({
        id: null,
        name: null,
        eventId: null,
        reference: null,
        options: [],
        oddsDetails: {
            name: null
        }
    })
    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        EventOddsDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        EventOddsDispatch();
    };



    const EventOddsDispatch = () => {
        let payloadOrder = order == 'asc' ? 1 : -1;

        dispatch(
            EventOdds({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery
                },
                eventId
            },)
        )
    }

    const updateBtnClick = (item) => {
        setOddsUpdateFields({
            ...oddsUpdateFields,
            id: null,
            name: null,
            eventId: item.eventId,
            reference: item.reference,
            options: item.options,
            oddsDetails: {
                name: item.name
            }
        })
        setOpenModal(true)
    }
    const proceedToUpdate = async () => {
        try {
            if (oddsUpdateFields.id && oddsUpdateFields.name && oddsUpdateFields.eventId && oddsUpdateFields.reference) {
                await EventOddsUpdateApi({
                    payload: {
                        id: oddsUpdateFields.id,
                        name: oddsUpdateFields.name,
                        eventId: oddsUpdateFields.eventId,
                        reference: oddsUpdateFields.reference
                    }
                })
                EventOddsDispatch()
                setOpenModal(false)
            } else {
                toast.error('Missing required fields..');
            }
        } catch (error) {
            EventOddsDispatch()
            setOpenModal(false)
        }
    }

    useEffect(() => {
        let payloadOrder = order == 'asc' ? 1 : -1;
        dispatch(
            EventOdds({
                payload: {
                    query: searchQuery,
                    page: page + 1,
                    limit: rowsPerPage,
                    // filter: { 'userType': 0 },
                    sort: { [sortBy]: payloadOrder },
                    searchKey: searchQuery,
                },
                eventId
            })
        )
    }, [])

    return (
        <>
            <div>
                <div className="container-fluid">
                    <PageHeader
                        HeaderText="Event Odds List"
                        Breadcrumb={[{ name: "Event List", navigate: '/events' }, { name: "Event Odds List" }]}
                    />
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2>Event Odds List</h2>
                                        </div>
                                        <div className="col-md-12 row">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {eventDetails ? <div className="card">
                                <div className="header">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2>{`${eventDetails?.data?.home?.name} vs ${eventDetails?.data?.away?.name}`}</h2>
                                            <h7>{`${eventDetails?.data?.league?.name}`}</h7>
                                        </div>
                                        <div className="col-md-12 row">

                                        </div>
                                    </div>
                                </div>
                            </div> : ''}

                            <div className="card p-2">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="">
                                                    Odds Name
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    Options
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    Success Value
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    Active
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    createdOn
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    Active
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                eventOdds && eventOdds.map((row) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="">
                                                            {row?.name}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.options && row.options.map(item => <Chip size="small" color="default" label={item.name} />)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.successValue?.name ? <Chip size="small" color="success" label={row?.successValue?.name} />: ''}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.active ? <Chip size="small" color="success" label="Yes" /> : <Chip size="small" color="default" label="No" />}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.createdOn ?
                                                                dayjs(new Date(row.createdOn)).format('YYYY-MM-DD hh:mm:ss A') :
                                                                null

                                                            }
                                                        </StyledTableCell>

                                                        <StyledTableCell align="">
                                                            {row?.successValue?.id ? '' :
                                                                <Button color="info" size="small" variant="outlined" onClick={() => {
                                                                    updateBtnClick(row)
                                                                }}>Update Odds</Button>
                                                            }
                                                        </StyledTableCell>

                                                    </StyledTableRow>
                                                ))
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={8}
                                                    count={eventOddsCount}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={openModal} backdrop="static" scrollable centered >
                <Modal.Header>
                    <Modal.Title>Update Event Odd</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>{`${eventDetails?.data?.home?.name} vs ${eventDetails?.data?.away?.name}`}</h2>
                                        <h7>{`${eventDetails?.data?.league?.name}`}</h7>
                                    </div>
                                    <div className="col-md-6">
                                        <h2>{`${oddsUpdateFields.oddsDetails.name}`}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Form.Label>Select Success Value</Form.Label>
                        <Form.Select size="lg" value={oddsUpdateFields.id} onChange={(e) => {
                            if (e.target.value) {
                                let optionName = oddsUpdateFields.options.find(item => item.id == e.target.value)
                                setOddsUpdateFields({
                                    ...oddsUpdateFields,
                                    id: e.target.value,
                                    name: optionName.name
                                })
                            } else {
                                setOddsUpdateFields({
                                    ...oddsUpdateFields,
                                    id: null,
                                    name: null
                                })
                            }
                        }}>
                            <option value={""}>Select Option</option>
                            {oddsUpdateFields.options.map((item) => {
                                return <option value={item.id}>{item.name}</option>
                            })}
                        </Form.Select>
                    </>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="contained" color="success" onClick={() => { proceedToUpdate() }}>
                        Update
                    </Button>
                    <Button variant="contained" color="inherit" onClick={() => { setOpenModal(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EventOddsComp