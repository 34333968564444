const Leftmenu = () => {
    const menus = [
        { menu: "Dashboard", url: 'dashboard', usertType: [0], container: "Dashboard Container", ischild: false, icon: "icon-home", child: [{ menu: "", url: '' }] },
        { menu: "User Management", usertType: [0], url: 'user adminlist', container: "Users Container", ischild: true, icon: "icon-users", child: [{ menu: "Admin", url: 'adminlist', container: "Admin Container" }] },
        { menu: "Sport Management", usertType: [0], url: 'sports sportlist', container: "Sports Container", ischild: true, icon: "icon-badge", child: [{ menu: "Sports", url: 'sportlist', container: "Sports Container" }] },
        { menu: "Promotions", usertType: [0], url: 'banner bannerlist', container: "Promotions Container", ischild: true, icon: "icon-flag", child: [{ menu: "Main Banner", url: 'bannerlist', container: "Banner Container" }, { menu: "Sub Banner", url: 'subbannerlist', container: "Sub Banner Container" }] },
        { menu: "Transactions", usertType: [0, 2], url: 'transactions', container: "Transaction Container", ischild: false, icon: "icon-wallet", child: [{ menu: "", url: '' }] },
        // { menu: "Burst Games", usertType: [0, 2], url: 'burstgames', container: "BurstGames Container", ischild: false, icon: "icon-badge", child: [{ menu: "", url: '' }] },
        { menu: "Games", usertType: [0, 2], url: 'games', container: "Games Container", ischild: false, icon: "icon-badge", child: [{ menu: "", url: '' }] },

        {
            menu: "Payments", usertType: [0, 2], url: 'payment transactions', container: "Payment Container", ischild: true, icon: "icon-wallet", child: [
                { menu: "Transactions", url: 'transactions', container: "Transactions Container" }, 
                { menu: "Withdrawal", url: 'withdrawal', container: "Withdrawal Container" }
            ]
        },
        // {
        //     menu: "Live Sports", usertType: [0, 2], url: 'live sports', container: "Live Sports Container", ischild: true, icon: "icon-flag", child: [
        //         { menu: "Events", url: 'events', container: "Transactions Container" },
        //         { menu: "Bet List", url: 'betlist', container: "Bet List Container" },
        //     ]
        // },

    ]
    return menus;
}

export default Leftmenu;
