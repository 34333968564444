import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addSubadmin, burstGamesList, editSubadmin, subAdminList, subadminbyid, startBurstGame, endBurstGame, getSettingsApi, updateSettingsApi } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from '../../utils/alert';
import { toast } from "react-toastify";
const namespace = "burstgames";


const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    gameLoading: false,
    gameStatus: 'initial',
    successMessage: null,
    errorMessage: null,
    burstGamesData: null,
    burstGameDetails: null,
    burstGamesCount: 0,
    settings: null
};



export const getBurstGames = createAsyncThunk(
    `${namespace}/list`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await burstGamesList({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const startGame = createAsyncThunk(
    `${namespace}/start`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const data = await startBurstGame({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const endGame = createAsyncThunk(
    `${namespace}/end`,
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            const data = await endBurstGame({ id });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getSettings = createAsyncThunk(
    `${namespace}/setting-get`,
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const data = await getSettingsApi();
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const updateSettings = createAsyncThunk(
    `${namespace}/setting-update`,
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const data = await updateSettingsApi(payload);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

const burstGamesSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearMessages: (state) => {
            state.errorMessage = null;
            state.successMessage = null;
        },
        toggleGameLoading: (state) => {
            state.gameLoading = !state.gameLoading
        },
        resetGameState: (state) => {
            state.gameLoading = false
            state.gameStatus = 'initial'
            state.burstGameDetails = null
        },
        stopGame: (state) => {
            state.gameStatus = 'end'
        },
    },
    extraReducers: {
        [getBurstGames.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [getBurstGames.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            state.gameLoading = false;
            let payloaddatas = payload
            state.burstGamesCount = payloaddatas?.data?.data?.count;
            state.burstGamesData = payloaddatas?.data?.data?.result;
        },
        [getBurstGames.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
            state.gameLoading = false;

        },
        [startGame.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.gameLoading = false;
            state.errorMessage = action?.payload?.data?.message;
        },
        [startGame.fulfilled](state, action) {
            state.loading = API_STATUS.FULFILLED;
            state.successMessage = action?.payload?.data?.message;
            state.gameLoading = false;
            state.burstGameDetails = action?.payload?.data?.data?.details;
            state.gameStatus = 'start'
        },
        [endGame.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.gameLoading = false;
            state.errorMessage = action?.payload?.data?.message;
        },
        [endGame.fulfilled](state, action) {
            state.loading = API_STATUS.FULFILLED;
            state.gameLoading = false;
            state.successMessage = action?.payload?.data?.message;
            state.gameStatus = 'end'
        },
        [getSettings.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            if (!payload?.data?.data?.result) {
                toast.error("Burst game settings required to start game..",'Important..!')
            }
            state.settings = payload?.data?.data?.result;
        },
        [updateSettings.fulfilled](state, { payload }) {
            toast.success("Settings updated successfully..")
            state.gameLoading = false;
            state.loading = API_STATUS.FULFILLED;
            state.settings = payload?.data?.data?.result;
        },
    },
});

export const { clearData, clearLoadingDatas, clearAddLoading, clearUpdateLoading, stopGame, clearDeleteLoading, resetGameState, clearMessages, toggleGameLoading } = burstGamesSlice.actions;

export const burstgamesSelector = (state) => state.burstgames;

export default burstGamesSlice.reducer;
