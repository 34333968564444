import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/horizon-full.svg";
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import dayjs from 'dayjs';
import { API_STATUS } from "../../utils/constants";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL, DateFormat } from "../../services/config";
import { sportsSelector, clearSportsAddLoading, clearSportsLoadingDatas, clearErrormsg, clearSportsUpdateLoading, clearSportsDeleteLoading, SportsDataFunc } from "../../store/reducer/sports";


function ListSports() {
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal)
  const simpleValidator = useRef(new SimpleReactValidator())
  // const { bannerDatas, bannerCount, loading, addloding, deleteloading, updateloading, errorMessage } = useSelector(bannerSelector);
  const { sportsDatas, sportsCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(sportsSelector)
  // const resetForm = bannerFields;
  const [formFields, setFormFields] = useState([]);
  const [showAdd, setshowAdd] = useState(false);
  const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  const [showvalidator, setShowvalidator] = useState(false);
  const [fileUpload, setfileUpload] = useState('');
  const [filename, setfilename] = useState('');
  const [fileerror, setfileerror] = useState(false);
  const [filePath, setfilePath] = useState('');
  const [updateID, setUpdateID] = useState('');
  const fileReader = new FileReader();
  const [files, setFiles] = useState([]);
  let [sortBy, setSortBy] = useState("createdOn");
  let [activesortBy, setActiveSortBy] = useState("name");
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  //const formDatas = _.map()

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
    SportsListDispatch();
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    SportsListDispatch();
  };

  const FieldChange = (e, index) => {
    console.log(e.target.value, formFields[index]);
    formFields[index].value = e.target.value;
    console.log(formFields);
    setFormFields([...formFields]);
  };

  const DropdownChange = (value, index) => {
    console.log(value)
    formFields[index].value = value;
    setFormFields([...formFields]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    acceptedFiles.forEach((file) => {
      console.log(file);
      setfilename(file.name)
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        setfileUpload(binaryStr);
        setfilePath(file.path);
        setfileerror(false);
        //console.log(binaryStr)
      }
      reader.readAsDataURL(file)
    })

  }, [])

  const thumbs = files.map(file => (
    <div className="dropzone_thum_img" key={file.name}>
      <div className="dropzone_thumb">
        <img
          src={file.preview}
          className="dropzone_img"
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop, accept: {
      'image/*': []
    }
  })


  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div class="invalid-feedback show">
      {errors.map(e => (
        <span>{e.message}</span>
      ))}
    </div>
  ));
  // const submitBanner = (e) => {
  //     if (simpleValidator.current.allValid()) {
  //         //alert('You submitted the form and stuff!');
  //         let arr = {};
  //         if (fileUpload == '' && updateID == "") {
  //             setfileerror(true);
  //             return false;
  //         }
  //         //const postData = new FormData();
  //         const datas = formFields.map((data) => {
  //             if (data?.name === "status") {
  //                 let val = data.value;
  //                 console.log(val)
  //                 //data.value = val[0].id;
  //                 return arr[data?.name] = val[0].id;
  //             } else {
  //                 return arr[data?.name] = data.value;
  //             }
  //         });
  //         arr.image = fileUpload;
  //         arr.imgpath = filePath;

  //         const postData = arr;
  //         console.log(postData, "postData");
  //         //return false;
  //         //const postData = arr;
  //         if (updateID == "")
  //             dispatch(AddBanner({ postData }));
  //         else {
  //             postData.id = updateID;
  //             dispatch(UpdateBanner({ postData, updateID }));
  //         }
  //         //setshowAdd(false);
  //     } else {
  //         if (fileUpload == '' && updateID == "") {
  //             setfileerror(true);
  //         }
  //         setShowvalidator(true);
  //         console.log("test");
  //     }
  // };

  // const editBanner = (row) => {
  //     window.scrollTo(0, 0)
  //     setshowAdd(true);
  //     setUpdateID(row.id);
  //     console.log(row.name)
  //     let data = formFields.map((data, index) => {
  //         //console.log(_.filter(statusDropdown, { id: row.status }));
  //         if (data.name === "name")
  //             data.value = row.name;
  //         if (data.name === "description")
  //             data.value = row.description;
  //         if (data.name === "status") {
  //             let set_status = _.filter(statusDropdown, { id: row.status });
  //             console.log(set_status[0]);
  //             data.value = set_status;
  //         }
  //         if (data.name === "image") {
  //             data.value = assestURL + row.image;
  //         }

  //         return data;
  //     });
  //     console.log(data, "formfields");
  //     setFormFields(data)
  //     setfileerror(false);
  // };



  // const deleteBanner = (row) => {
  //     //setshowAdd(true);
  //     //setUpdateID(row.id);
  //     const deleteID = row.id;
  //     MySwal.fire({
  //         title: 'Are You Sure to Delete Admin?',
  //         html: 'Deleting Admin will delete its Sub Admins',
  //         icon: 'warning',
  //         showCancelButton: true,
  //         focusConfirm: false,
  //         confirmButtonText: 'Ok',
  //         cancelButtonText:
  //             'Cancel',
  //     }).then((result) => {
  //         /* Read more about isConfirmed, isDenied below */
  //         if (result.isConfirmed) {
  //             dispatch(DeleteBanner({ deleteID }));
  //         }
  //     })

  // };


  const searchData = (e) => {
    searchQuery = e.target.value;
    setSearchQuery(e.target.value)
    setPage(0);
    SportsListDispatch()
  }

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
    SportsListDispatch();
  };

  const SportsListDispatch = () => {
    let payloadOrder = order == 'asc' ? 1 : -1;
    dispatch(
      SportsDataFunc({
        payload: {
          query: searchQuery,
          page: page + 1,
          limit: rowsPerPage,
          sort: { [sortBy]: payloadOrder },
          searchKey: searchQuery
        }
      })
    )
  }

  useEffect(() => {
    let payloadOrder = order == 'asc' ? 1 : -1;
    dispatch(
      SportsDataFunc({
        payload: {
          query: searchQuery,
          page: page + 1,
          limit: rowsPerPage,
          sort: { [sortBy]: payloadOrder },
          searchKey: searchQuery
        }
      })
    )
  }, [])


  // const bannerDispatch = () => {
  //     dispatch(
  //         bannerData({
  //             query: searchQuery,
  //             page: page + 1,
  //             limit: rowsPerPage,
  //             sortby: sortBy,
  //             order: order,
  //         })
  //     );
  // };

  const resetFormFields = () => {
    let data = formFields.map((data, index) => {
      if (data.name === "status")
        data.value = [{ id: "1", name: "Active" }];
      else
        data.value = "";
      return data;
    })
    setFormFields(data)
  };

  // useEffect(() => {
  //     dispatch(clearData())
  //     SportsListDispatch()
  //     simpleValidator.current.showMessages()
  // }, [])

  useEffect(() => {
    console.log(formFields, "formFields")
  }, [formFields])

  useEffect(() => {
    console.log(loading, "loading")
    if (deleteloading === API_STATUS.FULFILLED) {
      toast.dismiss();
      toast.success("Deleted Successfully!");
      dispatch(clearSportsDeleteLoading())
      SportsListDispatch()
    }
    if (addloading === API_STATUS.FULFILLED) {
      setshowAdd(false);
      toast.dismiss();
      toast.success("Added Successfully!");
      dispatch(clearSportsAddLoading())
      SportsListDispatch()
    }
    if (updateloading === API_STATUS.FULFILLED) {
      setshowAdd(false);
      toast.dismiss();
      toast.success("Updated Successfully!");
      dispatch(clearSportsUpdateLoading())
      resetFormFields();
      SportsListDispatch()
    }
    if (errorMessage) {
      toast.dismiss();
      if (errorMessage.message)
        toast.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        })
        toast.error(data);
      }
      setshowAdd(true);
      dispatch(clearErrormsg())

    }
  }, [deleteloading, addloading, updateloading, errorMessage]);


  return (
    <div>
      <div className="container-fluid">
        <PageHeader
          HeaderText="Sports"
          Breadcrumb={[{ name: "Sports" }]}
        />
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <div className="row">
                  <div className="col-md-6">
                    <h2>Sports List</h2>
                  </div>
                  <div className="col-md-6 row">
                    <div className="col-md-8">
                      {(!showAdd) ?
                        <input type="text" name="search" className="form-control" value={searchQuery}
                          onChange={(e) => { searchData(e) }} placeholder="Search Sports" />
                        : ''}
                    </div>
                    <div className="col-md-4">
                      <Link to='/addsports' className="btn btn-primary mr-1  float-right" onClick={resetFormFields}> Add Sports</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-2">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="">
                        <TableSortLabel
                          active={sortBy == "sportName" ? true : false}
                          direction={order}
                          className="table-header-column-name"
                          onClick={(e) => {
                            sortByKey("sportName");
                          }}
                        >
                          Sports Name
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="">
                        Icon
                      </StyledTableCell>
                      <StyledTableCell align="">
                        <TableSortLabel
                          active={sortBy == "status" ? true : false}
                          direction={order}
                          className="table-header-column-name"
                          onClick={(e) => {
                            sortByKey("status");
                          }}
                        >
                          Status
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      sportsDatas && sportsDatas.map((row) => (
                        <StyledTableRow>
                          <StyledTableCell align="">
                            {row.sportName}
                          </StyledTableCell>
                          <StyledTableCell align="">
                            {/* {row.icon} */}
                            <img
                              src={`${assestURL}static/${row.icon}`} alt="sports_icon" width={"50"} height={"50"} />
                          </StyledTableCell>
                          <StyledTableCell align="">
                            {row.status === 1
                              ? "Active"
                              : "In-active"}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">

                                                    </StyledTableCell> */}
                          <StyledTableCell align="">
                            <Link to={'/edit_sports/' + row._id} title="Edit"> <i className="icon-pencil text-info p-2"></i></Link>
                            {/* <a href="javascript:void(0);" onClick={(e) => {
                                                            // deleteBanner(row)
                                                        }} title="Delete"><i className="icon-trash text-danger p-2" ></i></a> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        colSpan={8}
                        count={sportsCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListSports;
