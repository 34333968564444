import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { tryLogin, forgotPassword, resetPassword, session, updateprofile } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import { Navigate } from "react-router-dom";
const namespace = "login";

const initialState = {
    loading: "initial",
    sessionloading: "initial",
    updateloading: "initial",
    forgotloading: "initial",
    resetloading: "initial",
    errorMessage: null,
    loginData: null,
    sessionData: null,
    updateProfileData: null
};

export const doLogin = createAsyncThunk(
    `${namespace}/doLogin`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            postData.role = "admin";
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await tryLogin(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const forgotPwd = createAsyncThunk(
    `${namespace}/forgotPwd`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await forgotPassword(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const resetPwd = createAsyncThunk(
    `${namespace}/resetPwd`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await resetPassword(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

// session
export const Session = createAsyncThunk(
    `${namespace}/Session`,
    async ({ rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await session();
            console.log("getsessionData--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const updateProfile = createAsyncThunk(
    `${namespace}/updateProfile`,
    async ({ postDatas, editID }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await updateprofile({ postDatas, editID });
            console.log("getsessionData--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const loginSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearLoading: (state) => {
            state.updateloading = "initial"
        },
        logOut: () => {
            localStorage.clear();
            window.location.reload(true);
        },
    },
    extraReducers: {
        [doLogin.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [doLogin.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log({ payload }, 'loginpayload');
            state.loginData = payload?.data?.data;
            localStorage.setItem('authToken', payload?.data?.data?.token)
            localStorage.setItem('isAuthenticated', true)
            localStorage.setItem('username', payload?.data?.data?.username)
            // localStorage.setItem('role_id', payload?.data?.roleId)
        },
        [doLogin.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [Session.pending](state) {
            state.sessionloading = API_STATUS.PENDING;
        },
        [Session.fulfilled](state, { payload }) {
            state.sessionloading = API_STATUS.FULFILLED;
            console.log({ payload }, 'sessionpayload');
            state.sessionData = payload?.data?.data?.user;
            localStorage.setItem('userType', payload?.data?.data?.user?.userType)
        },
        [Session.rejected](state, action) {
            state.sessionloading = API_STATUS.REJECTED;
            let error = action?.error
            state.errorMessage = error;
            console.log(error, 'session--error');
            if (error) {
                localStorage.clear();
                <Navigate to="/login" />
            }
        },
        [updateProfile.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [updateProfile.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
            console.log({ payload }, 'sessionpayload');
            state.sessionData = payload?.data?.data?.user;
            // localStorage.setItem('authToken', payload?.data?.data?.token)
            // localStorage.setItem('isAuthenticated', true)
            // localStorage.setItem('username', payload?.data?.data?.username)
            // localStorage.setItem('role_id', payload?.data?.roleId)
        },
        [updateProfile.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },

        [forgotPwd.pending](state) {
            state.forgotloading = API_STATUS.PENDING;
        },
        [forgotPwd.fulfilled](state, { payload }) {
            state.forgotloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [forgotPwd.rejected](state, action) {
            state.forgotloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [resetPwd.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [resetPwd.fulfilled](state, { payload }) {
            state.resetloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [resetPwd.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoading, logOut } = loginSlice.actions;

export const loginSelector = (state) => state.login;

export default loginSlice.reducer;
